import * as React from 'react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import moment from 'moment';
import ConnectionInfo from './ConnectionInfo';
import { ITask, TaskStatus } from "@testout/testout-commerce/models/commerce/Task";
import { Api } from './Api';

import './Tasks.scss';
import ico_Home from './images/ico_home.png';
import ico_Retry from './images/ico_retry.png';
import ico_Trash from './images/ico_trash.png';
import ico_Task from './images/ico_task.png';
import ico_LeftArrowLine from './images/ico_leftarrowline.png';
import ico_LeftArrow from './images/ico_leftarrow.png';
import ico_RightArrow from './images/ico_rightarrow.png';
import ico_Refresh from './images/ico_refresh.png';
import ico_Magnifier from './images/ico_magnifier.png';
import ico_Close from './images/ico_close.png';
import ico_Task16 from './images/ico_task16.png';
import ico_Sort from './images/ico_sort.png';
import ico_SortUp from './images/ico_sortup.png';
import ico_SortDown from './images/ico_sortdown.png';

interface ITasksProps extends RouteComponentProps {

}

interface ITasksState {
    fetchingList: boolean;
    fetchingTask: boolean;
    fetchingCommand: boolean;
    toggles: { [key: string]: boolean };
    tasks: ITask[];
    pageIndex: number;
    selectedIndex?: number;
    taskId?: string;
    taskData?: string;
    taskResult?: string;
    search?: string;
    sortColumn: string;
    sortDirection: string;
}

class Tasks extends React.PureComponent<ITasksProps, ITasksState> {
    constructor(props: ITasksProps) {
        super(props);
        let defaultToggles = { "new": true, "waiting": true, "running": true, "retry": true, "failed": true };
        this.state = { fetchingList: false, fetchingTask: false, fetchingCommand: false, toggles: defaultToggles, pageIndex: 0, tasks: [], sortColumn:"CreatedOn", sortDirection: "Down" };
    }

    getToggleButtonClassName(toggleKey: string) {
        return this.state.toggles[toggleKey] ? "ToggleButton Toggled" : "ToggleButton";
    }

    toggle(toggleKey: string) {
        let toggles = Object.assign({}, this.state.toggles);
        toggles[toggleKey] = !(toggles[toggleKey] === true);
        this.setState({ toggles: toggles, fetchingList: false, pageIndex: 0 }, () => { this.doFetchList() });
    }

    componentDidMount() {
        this.doFetchList();
    }

    doFetchList() {
        if (!this.state.fetchingList) {
            this.setState({ fetchingList: true, selectedIndex: undefined, taskId: undefined, taskData: undefined, taskResult: undefined }, async () => {
                let taskStatus = Object.keys(this.state.toggles).filter(key => this.state.toggles[key]).join();
                let pageIndex = this.state.pageIndex;
                let tasks = [] as ITask[];
                if (taskStatus)
                    tasks = await Api.getTasks(taskStatus, this.state.search, pageIndex);
                if (this.state.pageIndex === pageIndex)
                    this.setState({ fetchingList: false, tasks: tasks });
                this.doSort();
            });
        }
    }

    clearSearch() {
        this.setState({ search: undefined }, () => {
            this.doFetchList();
        });
    }

    selectItem(index: number) {
        if (!this.state.fetchingList) {
            this.setState({ selectedIndex: index, taskId: undefined, taskData: undefined, taskResult: undefined }, () => {
                if (index != null) {
                    this.setState({ fetchingTask: true }, async () => {
                        let task = await Api.getTask(this.state.tasks[index].taskId);
                        if (this.state.selectedIndex === index && task != null) {
                            let prettyData = task?.parameter;
                            try {
                                prettyData = JSON.stringify(JSON.parse(prettyData), null, 6);
                            }
                            catch { }
                            this.setState({ taskId: task.taskId, taskData: prettyData, taskResult: task.error ?? task.result });
                        }
                        this.setState({ fetchingTask: false });
                    });
                }
            });
        }
    }

    setPageIndex(pageIndex: number) {
        if (pageIndex < 0)
            pageIndex = 0;
        this.setState({ pageIndex: pageIndex, selectedIndex: undefined }, () => {
            this.doFetchList();
        });
    }

    async doRetry() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                if (this.state.selectedIndex != null && this.state.taskId)
                    await Api.retryTask(this.state.taskId, this.state.taskData ?? "");
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchList();
                });
            });
        }
    }

    sleep(ms?: number) {
        return new Promise(resolve => setTimeout(resolve, ms || 1000))
    }

    async doRetryAll() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true, selectedIndex: undefined, taskId: undefined, taskData: undefined, taskResult: undefined }, async () => {
                for (let i = this.state.tasks.length - 1; i >= 0;  i--) {
                    if (this.state.tasks[i].status === TaskStatus.Failed) {
                        this.setState({ selectedIndex: i  });
                        let task = await Api.getTask(this.state.tasks[i].taskId);
                        if (task) {
                            await Api.retryTask(task.taskId, task.parameter ?? "");
                            await this.sleep(1000);
                            task = await Api.getTask(task.taskId); // refresh
                            this.setState({ taskResult: task?.error ?? task?.result ?? "" });
                        }
                    }
                }
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchList();
                });
            });
        }
    }

    async doDelete() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                 if (this.state.selectedIndex != null && this.state.taskId)
                    await Api.deleteTask(this.state.taskId);
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchList();
                });
            });
        }
    }

    async doAuditCarts() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                await Api.auditCarts();
                this.setState({ fetchingCommand: false });
            });
        }
    }

    async doAuditOrders() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                await Api.auditOrders();
                this.setState({ fetchingCommand: false });
            });
        }
    }

    async doProcessDeferredFulfillments() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                await Api.processDeferredFulfillments();
                this.setState({ fetchingCommand: false });
            });
        }
    }

    doSort() {
        let arr = [...this.state.tasks];
        arr.sort((a, b) => {
            let result = 0;
            switch (this.state.sortColumn) {
                case "Task": result = a.methodName.localeCompare(b.methodName); break;
                case "Status": result = a.status - b.status; break;
                case "StatusDate": result = moment(a.statusDate).valueOf() - moment(b.statusDate).valueOf(); break;
                case "CreatedOn": result = moment(a.createdOn).valueOf() - moment(b.createdOn).valueOf(); break;
            }
            if (this.state.sortDirection === "Down")
                result = result * -1;
            return result;
        });
        this.setState({ tasks: arr, selectedIndex: undefined });
    }

    toggleSort(column: string) {
        if (this.state.sortColumn === column) {
            this.setState({ sortDirection: this.state.sortDirection === "Up" ? "Down" : "Up" }, () => this.doSort());
        }
        else this.setState({ sortColumn: column, sortDirection: "Down" }, () => this.doSort());
    }

    getSortIcon(column: string) {
        if (this.state.sortColumn === column) {
            switch (this.state.sortDirection) {
                case "Up": return ico_SortUp;
                case "Down": return ico_SortDown;
            }
        }
        return ico_Sort;
    }

    render() {
        return <div className="Tasks">
            <header>
                <div>
                    <div>
                        <button className="RibbonButton" onClick={() => { this.props.history.push("/") }}><img src={ico_Home} alt="Home" />HOME</button>
                        <button className="RibbonButton" onClick={() => this.doRetry()} disabled={this.state.fetchingTask || this.state.selectedIndex == null || this.state.tasks[this.state.selectedIndex].status !== TaskStatus.Failed}><img src={ico_Retry} alt="Retry Task" />RETRY TASK</button>
                        <button className="RibbonButton" onClick={() => this.doRetryAll()} disabled={this.state.fetchingTask || !this.state.tasks.find((task) => task.status === TaskStatus.Failed)}><img src={ico_Retry} alt="Retry All Tasks" />RETRY ALL TASKS</button>
                        <button className="RibbonButton" onClick={() => this.doDelete()} disabled={this.state.selectedIndex == null}><img src={ico_Trash} alt="Delete Task" />DELETE TASK</button>
                    </div>
                    <div>
                        <span>Filter:&nbsp;</span>
                        <button className={this.getToggleButtonClassName("new")} onClick={() => this.toggle("new")}>NEW</button>
                        <button className={this.getToggleButtonClassName("waiting")} onClick={() => this.toggle("waiting")}>WAITING</button>
                        <button className={this.getToggleButtonClassName("running")} onClick={() => this.toggle("running")}>RUNNING</button>
                        <button className={this.getToggleButtonClassName("completed")} onClick={() => this.toggle("completed")}>COMPLETED</button>
                        <button className={this.getToggleButtonClassName("retry")} onClick={() => this.toggle("retry")}>RETRY</button>
                        <button className={this.getToggleButtonClassName("failed")} onClick={() => this.toggle("failed")}>FAILED</button>
                    </div>
                    <div>
                        <button className="RibbonButton" onClick={() => this.doAuditCarts()}><img src={ico_Task16} alt="Audit Carts" />AUDIT CARTS</button>
                        <button className="RibbonButton" onClick={() => this.doAuditOrders()}><img src={ico_Task16} alt="Audit Orders" />AUDIT ORDERS</button>
                        <button className="RibbonButton" onClick={() => this.doProcessDeferredFulfillments()}><img src={ico_Task16} alt="Process Deferred Fulfillments" />PROCESS DEFERRED FULFILLMENTS</button>
                    </div>
                </div>
                <div>
                    <ConnectionInfo />
                </div>
            </header>
            <div className={this.state.fetchingList || this.state.fetchingTask || this.state.fetchingCommand ? "Spinner" : ""}></div>
            <div>
                <div className="PageTitle">
                    <img src={ico_Task} alt="Commerce Tasks" />
                    <span>Commerce Tasks</span>
                </div>
                <div className="Search">
                    <input placeholder="Search for records" value={this.state.search ?? ""} onChange={(e) => this.setState({ search: e.target.value })} onBlur={() => this.doFetchList} onKeyPress={(e) => { if (e.key === "Enter") this.doFetchList() }} />
                    {!this.state.search && <img src={ico_Magnifier} onMouseDown={() => this.doFetchList()} alt="Search" />}
                    {this.state.search && <img src={ico_Close} onMouseDown={() => this.clearSearch()} alt="Clear" />}
                </div>
            </div>
            <div className="Columns">
                <div className="TaskList">
                    <div className="TaskHeader TaskItem">
                        <span>Task<img src={this.getSortIcon("Task")} onClick={() => this.toggleSort("Task")} alt="" /></span>
                        <span>Status<img src={this.getSortIcon("Status")} onClick={() => this.toggleSort("Status")} alt="" /></span>
                        <span>Status Date<img src={this.getSortIcon("StatusDate")} onClick={() => this.toggleSort("StatusDate")} alt="" /></span>
                        <span>Created On<img src={this.getSortIcon("CreatedOn")} onClick={() => this.toggleSort("CreatedOn")} alt="" /></span>
                        <span>Instance<img src={this.getSortIcon("Instance")} onClick={() => this.toggleSort("Instance")} alt="" /></span>
                        <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchList()} alt="Refresh" /></button></div>
                    </div>
                    <div className="TaskScroller">
                        {this.state.tasks.map((task, index) => {
                            return <div className={`TaskItem ${this.state.selectedIndex === index ? "Selected" : ""}`} key={index} onClick={() => this.selectItem(index)}>
                                <span>{task.methodName}</span>
                                <span>{
                                    task.status === TaskStatus.FailedRetry
                                        ? TaskStatus[TaskStatus.Failed] : TaskStatus[task.status]}{
                                    task.retryCount > 0
                                        ? ` #${task.retryCount + 1}` : ""}{
                                    (task.status === TaskStatus.Completed || task.status === TaskStatus.Failed) && task.runMilliseconds
                                        ? ` (${(task.runMilliseconds / 1000).toFixed(3)}s)` : ""}{
                                    task.status === TaskStatus.FailedRetry && task.retryTime
                                        ? ` (retry in ${moment().diff(task.retryTime, "seconds")}s)` : ""}</span>
                                <span>{moment(task.statusDate).format("M/D/YYYY h:mm:ss A")}</span>
                                <span>{moment(task.createdOn).format("M/D/YYYY h:mm:ss A")}</span>
                                <span>{task.instanceId}</span>
                            </div>
                        })}
                    </div>
                    <div className="TaskFooter">
                        <span>{`${this.state.tasks.length}${this.state.tasks.length === 100 ? "+" : ""} record${this.state.tasks.length === 1 ? "" : "s"}`}</span>
                        <div>
                            <button disabled={this.state.pageIndex <= 1} onClick={() => this.setPageIndex(0)}><img src={ico_LeftArrowLine} alt="First" /></button>
                            <button disabled={this.state.pageIndex === 0} onClick={() => this.setPageIndex(this.state.pageIndex - 1)}><img src={ico_LeftArrow} alt="Prior"  /></button>
                            <span>{`Page ${this.state.pageIndex + 1}`}</span>
                            <button disabled={this.state.tasks.length < 100} onClick={() => this.setPageIndex(this.state.pageIndex + 1)}><img src={ico_RightArrow} alt="Next" /></button>
                        </div>
                    </div>
                </div>
                <div className="TaskDetail">
                    <h1>TASK DATA</h1>
                    <textarea spellCheck={false} value={this.state.taskData ?? ""} onChange={(e) => { this.setState({ taskData: e.target.value }) }} />
                    <span>{`${(this.state.taskData ?? "").length.toLocaleString("en-US")} byte${(this.state.taskData ?? "").length === 1 ? "" : "s"}`}</span>
                    <h1>TASK RESULT</h1>
                    <textarea spellCheck={false} readOnly={true} value={this.state.taskResult ?? ""} />
                    <span>{`${(this.state.taskResult ?? "").length.toLocaleString("en-US")} byte${(this.state.taskResult ?? "").length === 1 ? "" : "s"}`}</span>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(Tasks);