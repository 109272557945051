import * as React from 'react';
import { getUserDisplayName } from './ADAuth';
import { Api } from './Api';

import './ConnectionInfo.scss';

interface IConnectionInfoProps {

}

interface IConnectionInfoState {
    version: string;
}


export default class ConnectionInfo extends React.PureComponent<IConnectionInfoProps, IConnectionInfoState> {
    static _lastVersion = "";
    constructor(props: IConnectionInfoProps) {
        super(props);
        this.state = { version: ConnectionInfo._lastVersion };
    }

    async componentDidMount() {
        ConnectionInfo._lastVersion = await Api.getVersionAsync();
        this.setState({ version: ConnectionInfo._lastVersion });
    }

    render() {
        return <div className="ConnectionInfo">
            <span>{this.state.version}</span>
            <span>{getUserDisplayName() ?? "Unauthenticated"}</span>
        </div>;
    }
}