import moment from 'moment';
import { makeFetch } from './ADAuth';
import { ITask } from "@testout/testout-commerce/models/commerce/Task";
import { ICart, Cart } from "@testout/testout-commerce/models/commerce/Cart";
import { PriceGroupCode, IPriceGroupCode } from '@testout/testout-commerce/models/commerce/PriceGroupCode';
import { PriceGroup, IPriceGroup } from '@testout/testout-commerce/models/commerce/PriceGroup';
import { PriceList, IPriceList } from '@testout/testout-commerce/models/commerce/PriceList';
import { IPriceDiscount, PriceDiscount } from '@testout/testout-commerce/models/commerce/PriceDiscount';
import { IProduct, Product } from '@testout/testout-commerce/models/commerce/Product';
import { ILicenseBill, LicenseBill } from '@testout/testout-commerce/models/commerce/LicenseBill';
import { ILicenseActivationBill, LicenseActivationBill } from '@testout/testout-commerce/models/commerce/LicenseActivationBill';
import { ILicenseActivation, LicenseActivation } from '@testout/testout-commerce/models/commerce/LicenseActivation';
import { IProductUnitType, ProductUnitType } from '@testout/testout-commerce/models/commerce/ProductUnitType';
import { IProductFamily, ProductFamily } from '@testout/testout-commerce/models/commerce/ProductFamily';
import { IProductUnit } from '@testout/testout-commerce/models/commerce/ProductUnit';
import { StringDictionary } from '@testout/testout-commerce/core/StringDictionary';

interface ICartMeta {
    cart: ICart;
    totalAmount: number;
    priceGroupCode: IPriceGroupCode;
}

export interface ICCMeta {
    cardBrand: string;
    cardSuffix: string;
    expMonth: string;
    expYear: string;
    transCode: string;
}

interface IPriceGroupCodeAndPriceList {
    priceGroupCode: IPriceGroupCode;
    priceList: IPriceList;
}

export interface IThreadProgress {
    id: string;
    progress: number;
    status: string;
    resultData: string;
}

interface IStringResponse {
    text: string;
}

export interface IDocument {
    base64Content: string;
    contentType: string;
    createdOn: Date;
    name: string;
}

export class Api {
    static async getVersionAsync() {
        const response = await makeFetch("/api/admin/version");
        return await response.text();
    }

    static async getTasks(taskStatus: string, searchText: string = "", pageNumber: number = 0, pageSize: number = 100): Promise<ITask[]> {
        let url = `/api/admin/tasks?taskStatus=${encodeURIComponent(taskStatus)}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
        if (searchText)
            url += `&searchText=${encodeURIComponent(searchText)}`;
        const response = await makeFetch(url);
        if (response.ok)
            return await response.json() as ITask[];
        return [];
    }

    static async getTask(taskId: string): Promise<ITask | undefined> {
        let url = `/api/admin/task/${encodeURIComponent(taskId)}`;
        const response = await makeFetch(url);
        if (response.ok)
            return await response.json() as ITask;
    }

    static async retryTask(taskId: string, taskData: string) {
        let url = `/api/admin/task/${encodeURIComponent(taskId)}/retry`;
        await makeFetch(url, "PUT", JSON.stringify(taskData));
    }

    static async deleteTask(taskId: string) {
        let url = `/api/admin/task/${encodeURIComponent(taskId)}`;
        await makeFetch(url, "DELETE");
    }

    static async auditCarts() {
        let url = `/api/admin/audit/carts`;
        await makeFetch(url, "POST");
    }

    static async auditOrders() {
        let url = `/api/admin/audit/orders`;
        await makeFetch(url, "POST");
    }

    static async processDeferredFulfillments() {
        let url = `/api/admin/fulfillments/deferred`;
        await makeFetch(url, "POST");
    }

    static async getCarts(searchText: string = "", pageNumber: number = 0, pageSize: number = 100): Promise<Cart[]> {
        let url = `/api/admin/carts?pageNumber=${pageNumber}&pageSize=${pageSize}`;
        if (searchText)
            url += `&searchText=${encodeURIComponent(searchText)}`;
        const response = await makeFetch(url);
        if (response.ok) {
            let icm = await response.json() as ICartMeta[];
            return icm.map((ct) => new Cart(ct.cart, ct.totalAmount));
        }
        return [];
    }

    static async getCart(orderNumber: string): Promise<Cart | undefined> {
        let url = `/api/admin/cart/${encodeURIComponent(orderNumber)}`;
        const response = await makeFetch(url);
        if (response.ok) {
            let icm = await response.json() as ICartMeta;
            let pgc = new PriceGroupCode(icm.priceGroupCode);
            let c = new Cart(icm.cart);
            c.pricing = pgc;
            return c;
        }
        return undefined;
    }

    static async submitCartAsOrder(cartId: string, ccMeta: ICCMeta | undefined = undefined): Promise<Cart | undefined> {
        let url = `/api/admin/cart/${encodeURIComponent(cartId)}/submitasorder`;
        const response = await makeFetch(url, "POST", ccMeta != null ? JSON.stringify(ccMeta) : "{}");
        if (response.ok) {
            let icm = await response.json() as ICartMeta;
            let pgc = new PriceGroupCode(icm.priceGroupCode);
            let c = new Cart(icm.cart);
            c.pricing = pgc;
            return c;
        }
        return undefined;
    }

    static async getPriceGroups(searchText: string = ""): Promise<PriceGroup[]> {
        let url = `/api/admin/pricegroups`;
        if (searchText)
            url += `?searchText=${encodeURIComponent(searchText)}`;
        const response = await makeFetch(url);
        if (response.ok) {
            let ipgs = await response.json() as IPriceGroup[];
            return ipgs.map((ipg) => new PriceGroup(ipg));
        }
        return [];
    }

    static async getPriceGroupCode(priceGroupCode: string): Promise<PriceGroupCode | undefined> {
        let url = `/api/admin/pricegroupcode/${priceGroupCode}`;
        const response = await makeFetch(url);
        if (response.ok) {
            let obj = await response.json() as IPriceGroupCodeAndPriceList;
            let pgc = new PriceGroupCode(obj.priceGroupCode);
            pgc.priceList = new PriceList(obj.priceList);
            return pgc;
        }
        return undefined;
    }

    private static deserializePriceGroupCodeAndPriceList(obj: IPriceGroupCodeAndPriceList) {
        let pgc = new PriceGroupCode(obj.priceGroupCode);
        pgc.priceList = new PriceList(obj.priceList);
        return pgc;
    }

    static async moveProductInPriceList(priceGroupCode: string, productNumber: string, unitCode: string, delta: number): Promise<PriceGroupCode | undefined> {
        let url = `/api/admin/pricegroupcode/${encodeURIComponent(priceGroupCode)}/${encodeURIComponent(productNumber)}/${encodeURIComponent(unitCode)}/move/${encodeURIComponent(delta.toString())}`;
        const response = await makeFetch(url, "PUT");
        if (response.ok)
            return Api.deserializePriceGroupCodeAndPriceList(await response.json() as IPriceGroupCodeAndPriceList);
        return undefined;
    }

    static async toggleOptionVisibilty(priceGroupCode: string, productNumber: string, unitCode: string): Promise<PriceGroupCode | undefined> {
        let url = `/api/admin/pricegroupcode/${encodeURIComponent(priceGroupCode)}/${encodeURIComponent(productNumber)}/${encodeURIComponent(unitCode)}/togglevisibility`;
        const response = await makeFetch(url, "PUT");
        if (response.ok)
            return Api.deserializePriceGroupCodeAndPriceList(await response.json() as IPriceGroupCodeAndPriceList);
        return undefined;
    }

    static async toggleOptionsVisibility(priceGroupCode: string, visible: boolean): Promise<PriceGroupCode | undefined> {
        let url = `/api/admin/pricegroupcode/${encodeURIComponent(priceGroupCode)}/optionvisibility/${visible}`;
        const response = await makeFetch(url, "PUT");
        if (response.ok)
            return Api.deserializePriceGroupCodeAndPriceList(await response.json() as IPriceGroupCodeAndPriceList);
        return undefined;
    }

    static async togglePriceCodeEnabled(priceGroupId: number, priceCode: string, enabled: boolean): Promise<PriceGroup | undefined> {
        let url = `/api/admin/pricegroup/${priceGroupId}/${encodeURIComponent(priceCode)}/enabled/${enabled}`;
        const response = await makeFetch(url, "PUT");
        if (response.ok)
            return new PriceGroup(await response.json() as IPriceGroup);
        return undefined;
    }

    static async deletePriceCode(priceGroupId: number, priceCode: string): Promise<PriceGroup | undefined> {
        let url = `/api/admin/pricegroup/${priceGroupId}/${encodeURIComponent(priceCode)}`;
        const response = await makeFetch(url, "DELETE");
        if (response.ok)
            return new PriceGroup(await response.json() as IPriceGroup);
        return undefined;
    }

    static async updatePriceCodeVersion(priceGroupId: number, priceCode: string): Promise<PriceGroup | undefined> {
        let url = `/api/admin/pricegroup/${priceGroupId}/${priceCode}/updateversion`;
        const response = await makeFetch(url, "PUT");
        if (response.ok)
            return new PriceGroup(await response.json() as IPriceGroup);
        return undefined;
    }

    static async deletePriceGroup(priceGroupId: number) {
        let url = `/api/admin/pricegroup/${priceGroupId}`;
        await makeFetch(url, "DELETE");
    }

    static async syncPriceGroups() {
        let url = `/api/admin/pricegroups/sync`;
        await makeFetch(url, "PUT");
    }

    static async updatePriceGroup(priceGroup: IPriceGroup, newPriceCode?: number | undefined) {
        let url = `/api/admin/pricegroup/${priceGroup.priceGroupId}`;
        if (newPriceCode != null)
            url += `?newPriceCode=${newPriceCode}`;
        const response = await makeFetch(url, "PUT", JSON.stringify(priceGroup));
        if (response.ok)
            return new PriceGroup(await response.json() as IPriceGroup);
        return undefined;
    }

    static async getNewPriceGroupId() {
        let url = `/api/admin/pricegroup/newid`;
        const response = await makeFetch(url);
        if (response.ok)
            return await response.json() as number;
        return undefined;
    }

    static async getPriceLists(searchText: string = ""): Promise<PriceList[]> {
        let url = `/api/admin/pricelists`;
        if (searchText)
            url += `?searchText=${encodeURIComponent(searchText)}`;
        const response = await makeFetch(url);
        if (response.ok) {
            let ipls = await response.json() as IPriceList[];
            return ipls.map((ipl) => new PriceList(ipl));
        }
        return [];
    }

    static async deletePriceList(priceId: number) {
        let url = `/api/admin/pricelist/${encodeURIComponent(priceId)}`;
        await makeFetch(url, "DELETE");
    }

    static async getDiscounts(searchText: string = ""): Promise<PriceDiscount[]> {
        let url = `/api/admin/discounts`;
        if (searchText)
            url += `?searchText=${encodeURIComponent(searchText)}`;
        const response = await makeFetch(url);
        if (response.ok) {
            let ipds = await response.json() as IPriceDiscount[];
            return ipds.map((ipd) => new PriceDiscount(ipd));
        }
        return [];
    }

    static async getDiscount(discountCode: string): Promise<PriceDiscount | undefined> {
        let url = `/api/admin/discount/${encodeURIComponent(discountCode)}`;
        const response = await makeFetch(url);
        if (response.ok)
            return new PriceDiscount(await response.json() as IPriceDiscount);
        return undefined;
    }

    static async updateDiscount(d: IPriceDiscount) {
        let url = `/api/admin/discount/${d.discountCode}`;
        const response = await makeFetch(url, "PUT", JSON.stringify(d));
        if (response.ok)
            return new PriceDiscount(await response.json() as IPriceDiscount);
        return undefined;
    }

    static async deleteDiscount(discountCode: string) {
        let url = `/api/admin/discount/${encodeURIComponent(discountCode)}`;
        await makeFetch(url, "DELETE");
    }

    static async syncPriceLists() {
        let url = `/api/admin/pricelists/sync`;
        await makeFetch(url, "PUT");
    }

    static async updatePriceList(priceList: IPriceList) {
        let url = `/api/admin/pricelist/${priceList.priceId}`;
        const response = await makeFetch(url, "PUT", JSON.stringify(priceList));
        if (response.ok)
            return new PriceList(await response.json() as IPriceList);
        return undefined;
    }

    static async getPriceList(priceId: string) {
        let url = `/api/admin/pricelist/${priceId}`;
        const response = await makeFetch(url);
        if (response.ok)
            return new PriceList(await response.json() as IPriceList);
        return undefined;
    }

    static async getProducts(searchText: string = "", includeRetired: boolean = false): Promise<Product[]> {
        let url = `/api/admin/products`;
        if (searchText)
            url += `?searchText=${encodeURIComponent(searchText)}`;
        if (includeRetired)
            url += (url.indexOf('?') > -1 ? "&" : "?") + `&includeRetired=${encodeURIComponent(includeRetired)}`;
        const response = await makeFetch(url);
        if (response.ok) {
            let ips = await response.json() as IProduct[];
            return ips.map((ipd) => new Product(ipd));
        }
        return [];
    }

    static async getProduct(productNumber: string): Promise<Product | undefined> {
        let url = `/api/admin/product/${productNumber}`;
        const response = await makeFetch(url);
        if (response.ok) {
            return new Product(await response.json() as IProduct);
        }
        return undefined;
    }

    static async getNewPriceListId() {
        let url = `/api/admin/pricelist/newid`;
        const response = await makeFetch(url);
        if (response.ok)
            return await response.json() as number;
        return undefined;
    }

    static async getLicenseBills(searchText: string = "", searchBeforeDate: Date): Promise<LicenseBill[]> {
        let url = `/api/admin/licensebills?searchBeforeDate=${encodeURIComponent(moment(searchBeforeDate).format("MM/DD/YYYY"))}`;
        if (searchText)
            url += `&searchText=${encodeURIComponent(searchText)}`;
        const response = await makeFetch(url);
        if (response.ok)
            return (await response.json() as ILicenseBill[]).map(ilb => new LicenseBill(ilb));
        return [];
    }

    static async doTransferActivationCodes(billingCustomer: string, shippingCustomer: string | undefined, priceCode: string|undefined, searchText: string, searchBeforeDate: Date, summaryEmail: string): Promise<string | undefined> {
        let req = {
            billingCustomer: billingCustomer,
            shippingCustomer: shippingCustomer,
            priceCode: priceCode,
            searchText: searchText,
            searchBeforeDate: searchBeforeDate,
            summaryEmail: summaryEmail
        }

        let url = `/api/admin/licensebills/transferactivationcodes`;
        let response = await makeFetch(url, "PUT", JSON.stringify(req));
        if (response.ok)
            return await response.json();
        return undefined;
    }

    static async getThreadProgress(threadId: string): Promise<IThreadProgress | undefined> {
        let url = `/api/admin/threadprogress/${encodeURIComponent(threadId)}`;
        let response = await makeFetch(url);
        if (response.ok)
            return await response.json() as IThreadProgress;
        return undefined;
    }

    static async submitCustomerCSV(csvData: string): Promise<string | undefined> {
        let url = `/api/admin/customercsv`;
        let response = await makeFetch(url, "PUT", JSON.stringify(csvData));
        if (response.ok)
            return (await response.json() as IStringResponse).text;
        return undefined;
    }

    static async getLicenseActivationBills(searchBeforeDate: Date): Promise<LicenseActivationBill[]> {
        let url = `/api/admin/licenseactivationbills?searchBeforeDate=${encodeURIComponent(moment(searchBeforeDate).format("MM/DD/YYYY"))}`;
        const response = await makeFetch(url);
        if (response.ok)
            return (await response.json() as ILicenseActivationBill[]).map(ilab => new LicenseActivationBill(ilab));
        return [];
    }

    static async getLicenseBillDownload(customerNumber: string, searchBeforeDate: Date): Promise<IDocument | undefined> {
        let url = `/api/admin/licenseactivationbill/${customerNumber}?searchBeforeDate=${encodeURIComponent(moment(searchBeforeDate).format("MM/DD/YYYY"))}`;
        const response = await makeFetch(url);
        if (response.ok)
            return await response.json() as IDocument;
        return undefined;
    }

    static async submitLicenseActivation(customerNumber: string, searchBeforeDate: Date): Promise<string | undefined> {
        let req = {
            endDate: new Date(moment(searchBeforeDate).format("MM/DD/YYYY")),
            customerNumber: customerNumber,
        }

        let url = `/api/admin/licenseactivationbill/submitsalesorder`;
        let response = await makeFetch(url, "PUT", JSON.stringify(req));
        if (response.ok)
            return await response.json();
        return undefined;
    }

    static async getActivationsFromBill(customerNumber: string, searchBeforeDate: Date): Promise<LicenseActivation[]> {
        let url = `/api/admin/activations/${encodeURIComponent(customerNumber)}?searchBeforeDate=${encodeURIComponent(moment(searchBeforeDate).format("MM/DD/YYYY"))}`;
        const response = await makeFetch(url);
        if (response.ok)
            return (await response.json() as ILicenseActivation[]).map(ila => new LicenseActivation(ila));
        return [];
    }

    static async doDeactivations(activationKeys: LicenseActivation[]): Promise<string | undefined> {
        let url = `/api/admin/activations/deactivate`;
        let response = await makeFetch(url, "PUT", JSON.stringify(activationKeys));
        if (response.ok)
            return await response.json();
        return undefined;
    }

    static async getUnitTypes(): Promise<ProductUnitType[]> {
        let url = `/api/admin/productunittypes`;
        const response = await makeFetch(url);
        if (response.ok)
            return (await response.json() as IProductUnitType[]).map(iut => new ProductUnitType(iut));
        return [];
    }

    static async getUnitType(unitType: string): Promise<ProductUnitType | undefined> {
        let url = `/api/admin/productunittype/${encodeURIComponent(unitType)}`;
        const response = await makeFetch(url);
        if (response.ok)
            return new ProductUnitType(await response.json() as IProductUnitType);
        return undefined;
    }

    static async getProductFamilies(): Promise<ProductFamily[]> {
        let url = `/api/admin/productfamilies`;
        const response = await makeFetch(url);
        if (response.ok)
            return (await response.json() as IProductFamily[]).map(ipf => new ProductFamily(ipf));
        return [];
    }

    static async updateUnitType(ut: IProductUnitType) {
        let url = `/api/admin/productunittype/${ut.unitType}`;
        const response = await makeFetch(url, "PUT", JSON.stringify(ut));
        if (response.ok)
            return new ProductUnitType(await response.json() as IProductUnitType);
        return undefined;
    }

    static async deleteUnitType(unitType: string) {
        let url = `/api/admin/productunittype/${encodeURIComponent(unitType)}`;
        await makeFetch(url, "DELETE");
    }

    static async updateProductFamily(pf: IProductFamily) {
        let url = `/api/admin/productfamily/${pf.familyCode}`;
        const response = await makeFetch(url, "PUT", JSON.stringify(pf));
        if (response.ok)
            return new ProductFamily(await response.json() as IProductFamily);
        return undefined;
    }

    static async deleteProductFamily(familyCode: string) {
        let url = `/api/admin/productfamily/${encodeURIComponent(familyCode)}`;
        await makeFetch(url, "DELETE");
    }

    static async syncProducts() {
        let url = `/api/admin/products/sync`;
        await makeFetch(url, "PUT");
    }

    static async retireProduct(productNumber: string) {
        let url = `/api/admin/product/${productNumber}/retire`;
        await makeFetch(url, "PUT");
    }

    static async reactivateProduct(productNumber: string) {
        let url = `/api/admin/product/${productNumber}/reactivate`;
        await makeFetch(url, "PUT");
    }

    static async deleteProduct(productNumber: string) {
        let url = `/api/admin/product/${productNumber}`;
        await makeFetch(url, "DELETE");
    }

    static async getCoursesDictionary(): Promise<StringDictionary | undefined> {
        let url = `/api/admin/dictionary/courses`;
        const response = await makeFetch(url);
        if (response.ok)
            return StringDictionary.fromSimpleObject(await response.json());
        return undefined;
    }

    static async getMeritItemsDictionary(): Promise<StringDictionary | undefined> {
        let url = `/api/admin/dictionary/merititems`;
        const response = await makeFetch(url);
        if (response.ok)
            return StringDictionary.fromSimpleObject(await response.json());
        return undefined;
    }

    static async updateProduct(product: IProduct) {
        let url = `/api/admin/product/${product.productNumber}`;
        const response = await makeFetch(url, "PUT", JSON.stringify(product));
        if (response.ok)
            return new Product(await response.json() as IProduct);
        return undefined;
    }
}