import * as React from 'react';

import './Dialog.scss';

interface IDialogProps {

}

interface IDialogState {

}

export default class Dialog extends React.PureComponent <IDialogProps, IDialogState> {
    constructor(props: IDialogProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className="DialogOuter">
            <div className="DialogChrome">
                {this.props.children}
            </div>
        </div>;
    }
}