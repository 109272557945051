import * as React from 'react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import moment from 'moment';
import ConnectionInfo from './ConnectionInfo';
import { PriceList } from "@testout/testout-commerce/models/commerce/PriceList";
import { Api, ICCMeta } from './Api';
import { Price } from '@testout/testout-commerce/models/commerce/Price';

import './PriceLists.scss';
import ico_Home from './images/ico_home.png';
import ico_Refresh from './images/ico_refresh.png';
import ico_Magnifier from './images/ico_magnifier.png';
import ico_Close from './images/ico_close.png';
import ico_Sort from './images/ico_sort.png';
import ico_SortUp from './images/ico_sortup.png';
import ico_SortDown from './images/ico_sortdown.png';
import ico_Trash from './images/ico_trash.png';
import ico_Calculator from './images/ico_calculator.png';
import ico_Plus from './images/ico_plus.png';
import ico_Save from './images/ico_save.png';
import ico_Copy from './images/ico_copy.png';
import { DiscountType, IPriceDiscount, PriceDiscount } from '@testout/testout-commerce/models/commerce/PriceDiscount';
import { IPriceDiscountLevel, PriceDiscountLevel } from '@testout/testout-commerce/models/commerce/PriceDiscountLevel';
import EditPriceListDlg from './EditPriceListDlg';

interface IPriceListsProps extends RouteComponentProps {

}

interface IPriceListsState {
    fetchingPriceLists: boolean;
    fetchingDiscounts: boolean;
    fetchingCommand: boolean;

    priceLists: PriceList[];
    discounts: PriceDiscount[];

    selectedPLIndex?: number;
    selectedDiscountIndex?: number;
    selectedLevelIndex?: number;


    search?: string;
    sortColumn: string;
    sortDirection: string;
    dSortColumn: string;
    dSortDirection: string;

    editDiscountMode: boolean;
    discountEditError?: string;
    editDCode?: string;
    editDName?: string;
    editDType?: DiscountType;

    editLevelMode: boolean;
    levelEditError?: string;
    editLevelMin?: string;
    editLevelMax?: string;
    editLevelAmount?: string;
    editLevelCompound?: boolean;

    editPriceList?: PriceList;
    priceListIsNew?: boolean;
}

class PriceLists extends React.PureComponent<IPriceListsProps, IPriceListsState> {
    constructor(props: IPriceListsProps) {
        super(props);
        this.state = { fetchingPriceLists: false, fetchingDiscounts: false, fetchingCommand: false, editDiscountMode: false, editLevelMode: false, priceLists: [], discounts: [], sortColumn: "PriceList", sortDirection: "Up", dSortColumn: "CodeName", dSortDirection: "Up" };
    }

    componentDidMount() {
        this.doFetchPriceLists();
        this.doFetchDiscounts();
    }

    _lastSearch: string | undefined = undefined;
    doFetchPriceLists(cb?: () => void) {
        if (!this.state.fetchingPriceLists) {
            this.setState({ fetchingPriceLists: true, selectedPLIndex: undefined }, async () => {
                this._lastSearch = this.state.search;
                let priceLists = await Api.getPriceLists(this.state.search);
                this.setState({ fetchingPriceLists: false, priceLists: priceLists });
                this.doSort();
                if (cb != null)
                    cb();
            });
        }
    }

    doFetchDiscounts() {
        if (!this.state.fetchingDiscounts) {
            this.setState({ fetchingDiscounts: true, selectedDiscountIndex: undefined, editDiscountMode: false, discountEditError: undefined, selectedLevelIndex: undefined }, async () => {
                let discounts = await Api.getDiscounts();
                this.setState({ fetchingDiscounts: false, discounts: discounts });
                this.doSort();
            });
        }
    }

    clearSearch() {
        this.setState({ search: undefined }, () => {
            this.doFetchPriceLists();
        });
    }

    selectPriceList(index: number, doEdit: boolean | undefined = undefined) {
        if (!this.state.fetchingPriceLists) {
            if (doEdit)
                setTimeout(() => {
                    this.setState({ selectedPLIndex: index, editPriceList: this.state.priceLists[index], priceListIsNew: false });
                }, 1);
            else this.setState({ selectedPLIndex: index });
        }
    }

    selectDiscount(index: number, doEdit: boolean | undefined = undefined) {
        if (!this.state.fetchingDiscounts) {
            if (doEdit != null && doEdit != this.state.editLevelMode)
                setTimeout(() => {
                    let d = this.state.discounts[index];
                    this.setState({ selectedDiscountIndex: index, editDiscountMode: doEdit, discountEditError: undefined, editDCode: d.discountCode, editDName: d.name, editDType: d.discountType, selectedLevelIndex: undefined })
                }, 1);
            else this.setState({ selectedDiscountIndex: index, editDiscountMode: this.state.editDiscountMode && this.state.selectedDiscountIndex === index, selectedLevelIndex: undefined });
        }
    }

    selectLevel(index: number, doEdit: boolean | undefined = undefined) {
        if (!this.state.fetchingDiscounts) {
            if (doEdit != null && doEdit != this.state.editDiscountMode)
                setTimeout(() => {
                    if (this.selectedDiscount) {
                        let l = index < this.selectedDiscount.levels.length - 1 ? this.selectedDiscount.levels[index] : undefined;
                        this.setState({
                            selectedLevelIndex: index,
                            editLevelMode: doEdit,
                            levelEditError: undefined,
                            editLevelMin: l?.minQuantity.toString() ?? "",
                            editLevelMax: l?.maxQuantity?.toString() ?? "",
                            editLevelAmount: l?.amountOrPercent.toFixed(2) ?? "",
                            editLevelCompound: l?.compound ?? false
                        });
                    }
                    else this.setState({ selectedLevelIndex: undefined, editLevelMode: false });
                }, 1);
            else this.setState({ selectedLevelIndex: index, editLevelMode: this.state.editLevelMode && this.state.selectedLevelIndex === index });
        }
    }

    doSort(cb?: () => void) {
        let arr = [...this.state.priceLists];
        arr.sort((a, b) => {
            let result = 0;
            switch (this.state.sortColumn) {
                case "PriceList": result = a.priceId - b.priceId; break;
                case "Name": result = (a.name ?? "").localeCompare(b.name ?? ""); break;
                case "Currency": result = (a.currencyCode ?? "").localeCompare(b.currencyCode ?? ""); break;
                case "Effective": result = moment(a.effectiveDate).valueOf() - moment(b.effectiveDate).valueOf(); break;
                case "Expiration": result = moment(a.expirationDate).valueOf() - moment(b.expirationDate).valueOf(); break;
            }
            if (this.state.sortDirection === "Down")
                result = result * -1;
            return result;
        });
        this.setState({ priceLists: arr, selectedPLIndex: undefined }, () => {
            if (cb)
                cb();
        });
    }

    toggleSort(column: string) {
        if (this.state.sortColumn === column) {
            this.setState({ sortDirection: this.state.sortDirection === "Up" ? "Down" : "Up" }, () => this.doSort());
        }
        else this.setState({ sortColumn: column, sortDirection: "Down" }, () => this.doSort());
    }

    getSortIcon(column: string) {
        if (this.state.sortColumn === column) {
            switch (this.state.sortDirection) {
                case "Up": return ico_SortUp;
                case "Down": return ico_SortDown;
            }
        }
        return ico_Sort;
    }

    doDiscountSort(cb?: () => void) {
        let arr = [...this.state.discounts];
        arr.sort((a, b) => {
            let result = 0;
            switch (this.state.dSortColumn) {
                case "CodeName": result = (a.discountCode ?? "").localeCompare(b.discountCode ?? ""); break;
                case "DiscountType": result = a.discountType - b.discountType; break;
            }
            if (this.state.dSortDirection === "Down")
                result = result * -1;
            return result;
        });
        this.setState({ discounts: arr, selectedDiscountIndex: undefined }, () => {
            if (cb)
                cb();
        });
    }

    toggleDiscountSort(column: string) {
        if (this.state.dSortColumn === column) {
            this.setState({ dSortDirection: this.state.dSortDirection === "Up" ? "Down" : "Up" }, () => this.doDiscountSort());
        }
        else this.setState({ dSortColumn: column, dSortDirection: "Down" }, () => this.doDiscountSort());
    }

    getDiscountSortIcon(column: string) {
        if (this.state.dSortColumn === column) {
            switch (this.state.dSortDirection) {
                case "Up": return ico_SortUp;
                case "Down": return ico_SortDown;
            }
        }
        return ico_Sort;
    }

    get selectedPriceList(): PriceList | undefined {
        if (this.state.priceLists != null && this.state.selectedPLIndex != null)
            return this.state.priceLists[this.state.selectedPLIndex];
        return undefined;
    }

    get selectedDiscount(): PriceDiscount | undefined {
        if (this.state.discounts != null && this.state.selectedDiscountIndex != null)
            return this.state.discounts[this.state.selectedDiscountIndex];
        return undefined;
    }

    get selectedLevel(): PriceDiscountLevel | undefined {
        let d = this.selectedDiscount;
        if (d) {
            if (this.state.selectedLevelIndex != null)
                return d.levels[this.state.selectedLevelIndex];
        }
        return undefined;
    }

    componentDidUpdate() {
        requestAnimationFrame(() => {
            let e = document.getElementById("discountCodeEdit");
            if (e) {
                if ((e as HTMLInputElement).value === "") {
                    e.scrollIntoView();
                    e.focus();
                }
            }
        });
    }

    doAddPriceList(sourcePL?: PriceList) {
        if (!this.state.fetchingPriceLists && !this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let id = await Api.getNewPriceListId();
                if (id != null) {
                    let pl = new PriceList(sourcePL);
                    pl.priceId = id;
                    if (sourcePL)
                        pl.name = "Copy of " + pl.name;
                    this.setState({ selectedPLIndex: undefined, editPriceList: pl, priceListIsNew: true });
                }
                this.setState({fetchingCommand: false});
            });
        }
    }

    doCopyPriceList() {
        this.doAddPriceList(this.selectedPriceList);
    }

    doDeletePriceList() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let pl = this.selectedPriceList;
                if (pl)
                    await Api.deletePriceList(pl.priceId);
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchPriceLists();
                });
            });
        }
    }

    doSynchBackOffice() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                await Api.syncPriceLists();
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchPriceLists();
                });
            });
        }
    }

    swapDiscountInState(index: number, updatedD?: PriceDiscount) {
        if (index === this.state.selectedDiscountIndex && updatedD) {
            let pds = [...this.state.discounts];
            pds[index] = updatedD;
            this.setState({ discounts: pds });
        }
    }

    saveDiscount() {
        if (!this.state.fetchingCommand) {
            let error = "";
            if ((this.state.editDCode ?? "") === "")
                error += "code"
            if ((this.state.editDName ?? "") === "") {
                if (error != "")
                    error += " and ";
                error += "name"
            }
            if (error.length > 0) {
                this.setState({ discountEditError: `Please enter a ${error} for the discount.` });
            }
            else {
                this.setState({ fetchingCommand: true }, async () => {
                    let index = this.state.selectedDiscountIndex;
                    if (index != null) {
                        let pd = (Object.assign({}, this.state.discounts[index]) as any) as IPriceDiscount;
                        pd.discountCode = this.state.editDCode ?? "";
                        pd.discountType = this.state.editDType as DiscountType;
                        pd.name = this.state.editDName as string;

                        this.setState({ discountEditError: undefined });
                        let updatePd = await Api.updateDiscount(pd);
                        this.swapDiscountInState(index, updatePd);
                        this.selectDiscount(index, false);
                    }
                    this.setState({ fetchingCommand: false });
                });
            }
        }
    }

    saveLevel() {
        const INFINITE = 99999;

        let minQuantity = parseInt(this.state.editLevelMin ?? "");
        let maxQuantity = parseInt(this.state.editLevelMax ?? "");
        let amountOrPercent = parseFloat(this.state.editLevelAmount ?? "");

        //Max quantity is allowed to be null, but the other two values are required
        if (isNaN(minQuantity) || isNaN(amountOrPercent)) {
            this.setState({ levelEditError: "One of the values for this level isn't a valid number."});
            return;
        }

        if (minQuantity > (maxQuantity ?? INFINITE)) {
            this.setState({ levelEditError: "The start value for the range must be less than the end value." });
            return
        }

        let invalid = false;
        this.selectedDiscount?.levels?.forEach((l, fIndex) => {
            invalid = invalid ||
                (fIndex !== this.state.selectedLevelIndex &&
                    ((minQuantity >= l.minQuantity && minQuantity <= (l.maxQuantity ?? INFINITE)) ||
                    ((maxQuantity ?? INFINITE) >= l.minQuantity && (maxQuantity ?? INFINITE) <= (l.maxQuantity ?? INFINITE))));
        });
        if (invalid) {
            this.setState({ levelEditError: "The quanity range must not overlap other existing ranges for this discount." });
            return
        }

        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let index = this.state.selectedDiscountIndex;
                let levelIndex = this.state.selectedLevelIndex;
                if (index != null && levelIndex != null) {
                    let pd = (Object.assign({}, this.state.discounts[index]) as any) as IPriceDiscount;
                    let level = levelIndex > pd.levels.length - 1
                        ? (Object.assign({}, pd.levels[levelIndex]) as any) as IPriceDiscountLevel
                        : new PriceDiscountLevel();
                    level.minQuantity = minQuantity;
                    level.maxQuantity = maxQuantity;
                    level.amountOrPercent = amountOrPercent;
                    level.compound = this.state.editLevelCompound;
                    if (levelIndex < pd.levels.length - 1)
                        pd.levels[levelIndex] = level;
                    else pd.levels.push(level);

                    this.setState({ discountEditError: undefined, levelEditError: undefined });
                    let updatePd = await Api.updateDiscount(pd);
                    this.swapDiscountInState(index, updatePd);
                    this.selectDiscount(index, false);
                    this.selectLevel(levelIndex, false);
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    doAddDiscount() {
        if (!this.state.fetchingCommand && !this.state.fetchingDiscounts) {
            this.setState({ fetchingCommand: true }, async () => {
                let discounts = [...this.state.discounts];
                discounts.push(new PriceDiscount({
                    discountCode: "",
                    discountType: DiscountType.Amount,
                    name: "",
                    levels: []
                }));
                this.setState({ discounts: discounts });
                this.doSort(() => {
                    let sortedIndex = this.state.discounts.findIndex((d) => d.discountCode == "");
                    this.selectDiscount(sortedIndex, true);
                });
                this.setState({ fetchingCommand: false });
            });
        }
    }

    doDeleteDiscount() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let d = this.selectedDiscount
                if (d)
                    await Api.deleteDiscount(d.discountCode);
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchDiscounts();
                });
            });
        }
    }

    doAddLevel() {
        if (!this.state.fetchingCommand && this.selectedDiscount != null)
            this.selectLevel(this.selectedDiscount.levels.length, true);
    }

    get editLevelsArray() {
        let d = this.selectedDiscount;
        if (d) {
            if (this.state.editLevelMode)
                return (d.levels ?? []).concat([new PriceDiscountLevel()]);
            return d.levels ?? [];
        }
        return [];
    }

    doDeleteLevel() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let d = this.selectedDiscount;
                let index = this.state.selectedLevelIndex;
                if (d != null && d.levels != null && index != null && index >= 0 && index < d.levels.length) {
                    let index = this.state.selectedLevelIndex;
                    if (index != null) {
                        let changedPd = (Object.assign({}, d) as any) as IPriceDiscount;
                        changedPd.levels.splice(index, 1)
                        let updatePd = await Api.updateDiscount(changedPd);
                        this.swapDiscountInState(index, updatePd);
                        if (this.state.selectedDiscountIndex != null)
                            this.selectDiscount(this.state.selectedDiscountIndex, false);
                    }
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    doRefreshDiscount() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let index = this.state.selectedDiscountIndex;
                let d = this.selectedDiscount;
                if (d) {
                    let updatedD = await Api.getDiscount(d.discountCode);
                    if (updatedD && index != undefined && index === this.state.selectedDiscountIndex)
                        this.swapDiscountInState(index, updatedD);
                    this.setState({ editDiscountMode: false, discountEditError: undefined, selectedLevelIndex: undefined, levelEditError: undefined, editLevelMode: false })
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    onPriceListDlgSave(updatedPL: PriceList) {
        let index = this.state.priceLists.findIndex((p) => p.priceId === updatedPL.priceId);
        if (this.state.selectedPLIndex === index) {
            let arr = [...this.state.priceLists];
            arr[this.state.selectedPLIndex] = updatedPL;
            this.setState({ priceLists: arr, editPriceList: undefined });
        }
        else {
            this.setState({ selectedPLIndex: undefined, editPriceList: undefined }, () => {
                this.doFetchPriceLists(() => {
                    let index = this.state.priceLists.findIndex((p) => p.priceId === updatedPL.priceId);
                    if (index != null) {
                        this.selectPriceList(index, false);
                        let elm = document.getElementById(`priceList-${updatedPL.priceId}`);
                        if (elm != null)
                            elm.scrollIntoView();
                    }
                });
            });
        }
    }

    render() {
        return <div className="PriceLists">
            <header>
                <div>
                    <div>
                        <button className="RibbonButton" onClick={() => { this.props.history.push("/") }}><img src={ico_Home} />HOME</button>
                        <button className="RibbonButton" onClick={() => this.doAddPriceList()}><img src={ico_Plus} />ADD PRICE LIST</button>
                        <button className="RibbonButton" onClick={() => this.doDeletePriceList()} disabled={this.state.fetchingPriceLists || this.state.selectedPLIndex == null}><img src={ico_Trash} />DELETE PRICE LIST</button>
                        <button className="RibbonButton" onClick={() => this.doCopyPriceList()} disabled={this.state.fetchingPriceLists || this.state.selectedPLIndex == null}><img src={ico_Copy} />COPY PRICE LIST</button>
                        <button className="RibbonButton" onClick={() => this.doSynchBackOffice()}><img src={ico_Refresh} />SYNC BACK-OFFICE</button>
                    </div>
                </div>
                <div>
                    <ConnectionInfo />
                </div>
            </header>
            <div className={this.state.fetchingPriceLists || this.state.fetchingCommand ? "Spinner" : ""}></div>
            <div>
                <div className="PageTitle">
                    <img src={ico_Calculator} />
                    <span>Price Lists</span>
                </div>
                <div className="Search">
                    <input placeholder="Search for records" value={this.state.search ?? ""} onChange={(e) => this.setState({ search: e.target.value })} onBlur={() => { if (this._lastSearch !== this.state.search) this.doFetchPriceLists() }} onKeyPress={(e) => { if (e.key == "Enter") this.doFetchPriceLists() }} />
                    {!this.state.search && <img src={ico_Magnifier} onMouseDown={() => this.doFetchPriceLists()} />}
                    {this.state.search && <img id="imgCancelPriceListSearch" src={ico_Close} onMouseDown={() => this.clearSearch()} />}
                </div>
            </div>
            <div className="Columns">
                <div className="PriceListList">
                    <div className="PriceListHeader PriceListItem">
                        <span>Price List<img src={this.getSortIcon("PriceList")} onClick={() => this.toggleSort("PriceList")} /></span>
                        <span>Name<img src={this.getSortIcon("Name")} onClick={() => this.toggleSort("Name")} /></span>
                        <span>Currency<img src={this.getSortIcon("Currency")} onClick={() => this.toggleSort("Currency")} /></span>
                        <span>Effective<img src={this.getSortIcon("Effective")} onClick={() => this.toggleSort("Effective")} /></span>
                        <span>Expiration<img src={this.getSortIcon("Expiration")} onClick={() => this.toggleSort("Expiration")} /></span>
                        <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchPriceLists()} /></button></div>
                    </div>
                    <div className="PriceListScroller">
                        {this.state.priceLists.map((pl, index) => {
                            return <div className={`PriceListItem ${this.state.selectedPLIndex === index ? "Selected" : ""}`} key={index} onMouseDown={() => this.selectPriceList(index)}>
                                <span><a id={`priceList-${pl.priceId}`} onClick={() => this.selectPriceList(index, true)}>{pl.priceId}</a></span>
                                <span>{pl.name}</span>
                                <span>{pl.currencyCode}</span>
                                <span>{moment(pl.effectiveDate).format("M/D/YYYY")}</span>
                                <span>{moment(pl.expirationDate).format("M/D/YYYY")}</span>
                            </div>
                        })}
                    </div>
                    <div className="PriceListFooter">
                        <span>{`${this.state.priceLists.length} record${this.state.priceLists.length === 1 ? "" : "s"}`}</span>
                    </div>
                </div>
                <div className="PriceListDetail">
                    <div>
                        <h1>DISCOUNTS</h1>
                        <span>
                            <button onClick={() => this.doAddDiscount()} className="ChromelessButton"><img src={ico_Plus} /></button>
                            <button disabled={this.selectedDiscount == null} onClick={() => this.doDeleteDiscount()} className="ChromelessButton"><img src={ico_Trash} /></button>
                        </span>
                    </div>
                    <div>
                        {this.state.discountEditError && <div className="Error">{this.state.discountEditError}</div>}
                        <div className="DiscountHeader DiscountItem">
                            <div>
                                <span>Code/Name<img src={this.getDiscountSortIcon("CodeName")} onClick={() => this.toggleDiscountSort("CodeName")} /></span>
                                <span>Discount Type<img src={this.getDiscountSortIcon("DiscountType")} onClick={() => this.toggleDiscountSort("DiscountType")} /></span>
                                <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchDiscounts()} /></button></div>
                            </div>
                        </div>
                        <div className={`DiscountScroller${this.state.discountEditError ? " HasError" : ""}`}>
                            {this.state.discounts.map((d, index) => {
                                return <div className={`DiscountItem ${this.state.selectedDiscountIndex === index ? ("Selected" + (this.state.editDiscountMode ? " Editing" : "")) : ""}`} key={index} onClick={() => this.selectDiscount(index)}>
                                    <div>
                                        <span>
                                            {(!this.state.editDiscountMode || this.state.selectedDiscountIndex !== index || (this.selectedDiscount?.discountCode ?? "").length > 0) && <a onClick={() => this.selectDiscount(index, true)}>{d.discountCode}</a>}
                                            {this.state.editDiscountMode && this.state.selectedDiscountIndex === index && (this.selectedDiscount?.discountCode ?? "").length === 0 && <input id="discountCodeEdit" value={this.state.editDCode} onChange={(e) => this.setState({ editDCode: e.target.value })} />}
                                        </span>
                                        <span>
                                            {(!this.state.editDiscountMode || index !== this.state.selectedDiscountIndex) && DiscountType[d.discountType]}
                                            {this.state.editDiscountMode && index === this.state.selectedDiscountIndex && <select value={this.state.editDType} onChange={(e) => { this.setState({ editDType: parseInt(e.target.value) as DiscountType }) }}>
                                                <option value="0">Amount</option>
                                                <option value="1">Percent</option>
                                            </select>}
                                        </span>
                                        {this.state.editDiscountMode && index === this.state.selectedDiscountIndex && <span><img src={ico_Save} onMouseDown={() => { this.saveDiscount() }} /><img src={ico_Close} onMouseDown={() => this.doFetchDiscounts()} /></span>}
                                    </div>
                                    <div>
                                        {(!this.state.editDiscountMode || index !== this.state.selectedDiscountIndex) && d.name}
                                        {this.state.editDiscountMode && index === this.state.selectedDiscountIndex && <input id="priceGroupNameEdit" value={this.state.editDName} onChange={(e) => { this.setState({ editDName: e.target.value }) }} />}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <span>{`${this.state.discounts.length} record${this.state.discounts.length === 1 ? "" : "s"}`}</span>
                    <div>
                        <h1>{`${this.selectedDiscount != null ? (this.selectedDiscount.discountCode + " ") : ""}LEVELS`}</h1>
                        <span>
                            <button disabled={this.selectedDiscount == null} onClick={() => this.doAddLevel()} className="ChromelessButton"><img src={ico_Plus} /></button>
                            <button disabled={this.selectedLevel == null} onClick={() => this.doDeleteLevel()} className="ChromelessButton"><img src={ico_Trash} /></button>
                        </span>
                    </div>
                    <div>
                        {this.state.levelEditError && <div className="Error">{this.state.levelEditError}</div>}
                        <div className="LevelHeader LevelItem">
                            <span>Quantity</span>
                            <span>Discount</span>
                            <span>Compound</span>
                            <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doRefreshDiscount()} /></button></div>
                        </div>
                        <div className={`LevelScroller${this.state.levelEditError ? " HasError" : ""}`}>
                            {this.editLevelsArray.map((l, index) => {
                                return <div className={`LevelItem ${this.state.selectedLevelIndex === index ? ("Selected" + (this.state.editLevelMode ? " Editing" : "")) : ""}`} key={index} onClick={() => this.selectLevel(index)}>
                                    <span>
                                        {(!this.state.editLevelMode || this.state.selectedLevelIndex !== index) && <a onClick={() => this.selectLevel(index, true)}>{l.minQuantity}{l.maxQuantity != null ? ` - ${l.maxQuantity}` : "+"}</a>}
                                        {this.state.editLevelMode && this.state.selectedLevelIndex === index && <>
                                            {this.selectedLevel?.minQuantity == null && <input id="levelMinEdit" value={this.state.editLevelMin} onChange={(e) => this.setState({ editLevelMin: e.target.value })} />}
                                            {this.selectedLevel?.minQuantity != null && <span>{l.minQuantity}</span>}
                                            <span>&nbsp;-&nbsp;</span>
                                            <input value={this.state.editLevelMax} onChange={(e) => this.setState({ editLevelMax: e.target.value })} />
                                        </>}
                                    </span>
                                    <span>
                                        {(!this.state.editLevelMode || index !== this.state.selectedLevelIndex) && l.amountOrPercent.toFixed(2)}
                                        {this.state.editLevelMode && index === this.state.selectedLevelIndex && <input value={this.state.editLevelAmount} onChange={(e) => { this.setState({ editLevelAmount: e.target.value }) }} />}
                                    </span>
                                    <span>
                                        {(!this.state.editLevelMode || index !== this.state.selectedLevelIndex) && (l.compound ? "Yes" : "No")}
                                        {this.state.editLevelMode && index === this.state.selectedLevelIndex && <input type="checkbox" checked={this.state.editLevelCompound} onChange={(e) => { this.setState({ editLevelCompound: e.target.checked }) }} />}
                                    </span>
                                    {this.state.editLevelMode && index === this.state.selectedLevelIndex && <span><img src={ico_Save} onMouseDown={() => { this.saveLevel() }} /><img src={ico_Close} onMouseDown={() => this.doRefreshDiscount()} /></span>}
                                </div>
                            })}
                        </div>
                    </div>
                    <span>{`${this.selectedDiscount ? this.selectedDiscount.levels.length : 0} record${this.selectedDiscount?.levels.length === 1 ? "" : "s"}`}</span>
                </div>
            </div>
            {this.state.editPriceList && <EditPriceListDlg isNew={this.state.priceListIsNew ?? false} priceList={this.state.editPriceList} onSave={(updatedPL) => this.onPriceListDlgSave(updatedPL)} onCancel={() => this.setState({editPriceList: undefined})} />}
        </div>;
    }
}

export default withRouter(PriceLists);