import * as React from 'react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import moment from 'moment';
import ConnectionInfo from './ConnectionInfo';
import { Api, ICCMeta, IThreadProgress } from './Api';
import Dialog from './Dialog';
import DatePicker from 'react-datepicker';

import './BillingCodes.scss';
import ico_Home from './images/ico_home.png';
import ico_Refresh from './images/ico_refresh.png';
import ico_Magnifier from './images/ico_magnifier.png';
import ico_Close from './images/ico_close.png';
import ico_Calculator from './images/ico_calculator.png';
import ico_Register from './images/ico_register.png';
import ico_Task16 from './images/ico_task16.png';
import { BillPartType, LicenseBill } from '@testout/testout-commerce/models/commerce/LicenseBill';
import { getUsername } from './ADAuth';
import { FormEvent, RefObject } from 'react';

interface IBillingCodesProps extends RouteComponentProps {

}

interface IBillingCodesState {
    fetchingList: boolean;
    fetchingCommand: boolean;
    selectedIndex?: number;
    selectedLength?: number;
    endDate: Date;
    search?: string;
    bills: LicenseBill[];

    transferBillingCustomer?: LicenseBill;
    transferShippingCustomer?: LicenseBill;
    showTransferDialog: boolean;
    transferEmail: string;
    transferThread?: IThreadProgress;

    showImportDialog: boolean;
    importStatus?: string;
}

class BillingCodes extends React.PureComponent<IBillingCodesProps, IBillingCodesState> {
    _fileInput: RefObject<HTMLInputElement>;
    constructor(props: IBillingCodesProps) {
        super(props);
        this.state = {
            fetchingList: false, fetchingCommand: false, bills: [], endDate: new Date(), showTransferDialog: false, showImportDialog: false,
            transferEmail: getUsername() ?? "jnielsen@testout.com"
        };
        this._fileInput = React.createRef<HTMLInputElement>();
    }

    componentDidMount() {
        this.doFetchList();
    }

    doFetchList() {
        if (!this.state.fetchingList) {
            this.setState({ fetchingList: true, selectedIndex: undefined }, async () => {
                let bills = await Api.getLicenseBills(this.state.search, this.state.endDate);
                this.setState({ fetchingList: false, bills: bills });
            });
        }
    }

    clearSearch() {
        this.setState({ search: undefined }, () => {
            this.doFetchList();
        });
    }

    selectItem(index: number) {
        if (!this.state.fetchingList) {
            let selectedLength = 1;
            if (index != null) {
                while (index > 0 && this.state.bills[index].billPartType === BillPartType.Product)
                    index--;
                while (index + selectedLength < this.state.bills.length &&
                    this.state.bills[index + selectedLength].billPartType < this.state.bills[index].billPartType)
                    selectedLength++;
            }
            this.setState({ selectedIndex: index, selectedLength: selectedLength });
        }
    }

    isItemSelected(index: number): boolean {
        if (this.state.selectedIndex != null && this.state.selectedLength != null)
            return index >= this.state.selectedIndex && index <= this.state.selectedIndex + this.state.selectedLength - 1;
        return false;
    }

    doCreateSalesOrder() {
        if (!this.state.fetchingCommand && this.state.selectedIndex != null) {
            let bIndex = this.state.selectedIndex;
            while (bIndex > 0 && this.state.bills[bIndex].billPartType !== BillPartType.Billing)
                bIndex--;
            this.setState({
                transferBillingCustomer: this.state.bills[bIndex],
                transferShippingCustomer: this.state.bills[this.state.selectedIndex].billPartType == BillPartType.Shipping
                    ? this.state.bills[this.state.selectedIndex]
                    : undefined,
                showTransferDialog: true
            });
        }
    }

    get transferCustomerCount() {
        if (this.state.selectedIndex != null)
            return this.state.bills[this.state.selectedIndex].billPartType === BillPartType.Billing
                ? this.state.bills[this.state.selectedIndex].billParts.length
                : 1;
        return 0;
    }

    get isThreadComplete(): boolean {
        return this.state.transferThread != null && this.state.transferThread.progress >= 100 && this.state.transferThread.status == "Complete";
    }

    async doCheckStatus() {
        if (this.state.transferThread != null) {
            let thread = await Api.getThreadProgress(this.state.transferThread.id);
            this.setState({ transferThread: thread }, () => {
                if (this.isThreadComplete)
                    this.setState({ fetchingCommand: false });
                else setTimeout(() => this.doCheckStatus(), 5000);
            });
        }
    }

    doTransferSalesOrder() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                if (this.state.transferBillingCustomer) {
                    let threadId = await Api.doTransferActivationCodes(this.state.transferBillingCustomer?.customer?.customerNumber,
                        this.state.transferShippingCustomer?.customer?.customerNumber, this.state.transferShippingCustomer?.priceCode, this.state.search ?? "", this.state.endDate, this.state.transferEmail);
                    if (threadId != null) {
                        this.setState({ transferThread: { id: threadId, progress: 0, status: "Starting Transfer..." } as IThreadProgress }, () => {
                            this.doCheckStatus();
                        });
                    }
                }
            })
        }
    }

    doImportCustomerIdList() {
        if (!this.state.fetchingCommand)
            this.setState({ showImportDialog: true, importStatus: undefined });
    }

    endTransferSalesOrder() {
        this.setState({ showTransferDialog: false, transferThread: undefined, fetchingCommand: false }, () => {
            this.doFetchList();
        });
    }

    isBillValid(bill: LicenseBill) {
        let valid = bill.totalAmount !== 0 || bill.quantity === 0;
        bill.billParts.forEach(bp => valid = valid && this.isBillValid(bp));
        return valid;
    }

    get isTransferValid() {
        if (this.state.selectedIndex != null) {
            let bill = this.state.bills[this.state.selectedIndex];
            return this.isBillValid(bill);
        }
        return false;
    }

    async handleImportFile(e: FormEvent) {
        e.preventDefault();
        let text: string | undefined = undefined;
        let files = this._fileInput.current?.files;
        if (files && files.length > 0)
            text = await files[0].text();
        if (text) {
            this.setState({ fetchingCommand: true, importStatus: "Submitting customers..." }, async () => {
                let newStatus = await Api.submitCustomerCSV(text as string);
                this.setState({ fetchingCommand: false, importStatus: newStatus });
            });
        }
    }

    _timeout: any = undefined;
    scheduleRefresh() {
        if (this._timeout != null)
            clearTimeout(this._timeout);
        this._timeout = setTimeout(() => this.doFetchList(), 1000);
    }

    render() {
        return <div className="BillingCodes">
            <header>
                <div>
                    <div>
                        <button className="RibbonButton" onClick={() => { this.props.history.push("/") }}><img src={ico_Home} />HOME</button>
                        <button className="RibbonButton" onClick={() => this.doCreateSalesOrder()} disabled={this.state.fetchingList || this.state.selectedIndex == null}><img src={ico_Register} />CREATE SALES ORDER</button>
                        <button className="RibbonButton" onClick={() => this.doImportCustomerIdList()} disabled={this.state.fetchingList}><img src={ico_Task16} />IMPORT CUSTOMER ID LIST</button>
                    </div>
                </div>
                <div>
                    <ConnectionInfo />
                </div>
            </header>
            <div className={this.state.fetchingList || this.state.fetchingList || this.state.fetchingCommand ? "Spinner" : ""}></div>
            <div>
                <div className="PageTitle">
                    <img src={ico_Calculator} />
                    <span>Billing (Activation Codes)</span>
                </div>
                <div className="DatePicker">
                    <span>Bills Before</span>
                    <DatePicker selected={this.state.endDate} onChange={(d) => { this.setState({ endDate: d ?? new Date() }); this.scheduleRefresh() }} />
                </div>
                <div className="Search">
                    <input placeholder="Search for records" value={this.state.search ?? ""} onChange={(e) => this.setState({ search: e.target.value })} onBlur={() => this.doFetchList} onKeyPress={(e) => { if (e.key == "Enter") this.doFetchList() }} />
                    {!this.state.search && <img src={ico_Magnifier} onMouseDown={() => this.doFetchList()} />}
                    {this.state.search && <img src={ico_Close} onMouseDown={() => this.clearSearch()} />}
                </div>
            </div>
            <div className="BillingCodeList">
                <div className="BillingCodeHeader BillingCodeItem">
                    <span>Billing Customer</span>
                    <span>Shipping Customer</span>
                    <span>Product</span>
                    <span>Unit</span>
                    <span>Price List</span>
                    <span>Quantity</span>
                    <span>Total Amount</span>
                    <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchList()} /></button></div>
                </div>
                <div className="BillingCodeScroller">
                    {this.state.bills.map((b, index) => {
                        return <div className={`BillingCodeItem ${this.isItemSelected(index) ? "Selected" : ""} ${b.totalAmount === 0 ? "Red" : ""}`} key={index} onClick={() => this.selectItem(index)}>
                            {b.billPartType === BillPartType.Billing && <>
                                <span className="Billing">
                                    <span>{b.customer?.customerNumber}&nbsp;-&nbsp;
                                        <span>
                                            <span>{b.customer?.name}</span>
                                            <span>{b.customer?.address?.postal.toString()}</span>
                                        </span>
                                    </span>
                                </span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </>}
                            {b.billPartType == BillPartType.Shipping && <>
                                <span className="Shipping">
                                    <span>{b.customer?.customerNumber}&nbsp;-&nbsp;
                                        <span>
                                            <span>{b.customer?.name}</span>
                                            <span>{b.customer?.address?.postal.toString()}</span>
                                        </span>
                                    </span>
                                </span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span>{b.priceCode}</span>
                            </>}
                            {b.billPartType === BillPartType.Product && <>
                                <span className="Product">{b.productNumber} - {b.title}</span>
                                <span></span>
                                <span></span>
                                <span>{b.unitCode}</span>
                                <span></span>
                            </>}
                            <span>{b.quantity}</span>
                            <span>{b.totalAmount !== 0 ? b.totalAmount.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : '?'}</span>
                        </div>
                    })}
                </div>
                <div className="BillingCodeFooter">
                    <span>{`${this.state.bills.length}${this.state.bills.length === 100 ? "+" : ""} record${this.state.bills.length === 1 ? "" : "s"}`}</span>
                </div>
            </div>
            {this.state.showTransferDialog && <Dialog>
                <div className="TransferLicenseBill">
                    <h1>TRANSFER LICENSE BILL</h1>
                    <div>
                        <span>{this.state.transferBillingCustomer?.customer?.customerNumber}&nbsp;-&nbsp;{this.state.transferBillingCustomer?.customer?.name}</span>
                        <span>{this.state.transferBillingCustomer?.customer?.address?.postal.toString()}</span>
                    </div>
                    {!this.isTransferValid && <div className="Red">
                        This license bill can not be transferred because pricing for at least one product could not be determined. Please ensure the billing and shipping customer numbers are active and that all products numbers exist on the shipping customers price list.
                    </div>}
                    {this.isTransferValid && <>
                        <div>
                            Sales orders for {this.transferCustomerCount} customer{this.transferCustomerCount === 1 ? "" : "s"} will be transferred. Provide an email address below if you want to be sent a list of created order numbers.
                        </div>
                        <div>
                            <span>Email</span>
                            <span><input disabled={this.state.fetchingCommand || this.isThreadComplete} value={this.state.transferEmail} onChange={(e) => this.setState({ transferEmail: e.target.value })} /></span>
                        </div>
                    </>}
                    {this.state.transferThread != null && <>
                        {!this.isThreadComplete && <div className="Status">
                            <div className="Progress"><div style={{ width: `${this.state.transferThread.progress}%` }}></div></div>
                            <div>{this.state.transferThread.status}</div>
                        </div>}
                        {this.isThreadComplete && <div className="Orders">
                                <span>Orders</span>
                            <div>{(JSON.parse(this.state.transferThread.resultData) as string[]).map((s, index) => <span key={index}>{s}</span>)}</div>
                        </div>}
                    </>}
                    <div>
                        <button onClick={() => this.endTransferSalesOrder()}>{this.isThreadComplete ? "Close" : "Cancel"}</button>
                        {this.isTransferValid && !this.state.fetchingCommand && !this.isThreadComplete && <button onClick={() => this.doTransferSalesOrder()}>Transfer</button>}
                    </div>
                </div>
            </Dialog>}
            {this.state.showImportDialog && <Dialog>
                <div className="ImportCustomers">
                    <form onSubmit={(e) => this.handleImportFile(e)}>
                        <h1>IMPORT CUSTOMER ID LIST</h1>
                        <div><span>The file should be formatted as comma-separated values with each line containing a billing customer number, shipping customer number, and billing customer's identifier for the shipping customer. All existing identifiers for the billing customer will be replaced with those in the selected file.</span></div>
                        {!this.state.fetchingCommand && !this.state.importStatus && <>
                            <div>
                                <span>Import File</span>
                                <input type="file" ref={this._fileInput} />
                            </div>
                            <div>
                                <button onClick={() => this.setState({ showImportDialog: false })}>Cancel</button>
                                <button type="submit">Import</button>
                            </div>
                        </>}
                        {(this.state.fetchingCommand || this.state.importStatus) && <>
                            <div className={(this.state.importStatus ?? "").indexOf("Error:") > -1 ? "Error" : "Results"}>{(this.state.importStatus ?? "").split('\r\n').map((s, index) => <div key={index}>{s}</div>)}</div>
                            <div>
                                <button onClick={() => this.setState({ showImportDialog: false })}>{this.state.fetchingCommand ? "Cancel" : "Close"}</button>
                            </div>
                        </>}
                    </form>
                </div>
            </Dialog>}
        </div>;
    }
}

export default withRouter(BillingCodes);