import * as React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import './App.scss';
import { signIn } from './ADAuth';
import Home from './Home';
import Tasks from './Tasks';
import Carts from './Carts';
import PriceGroups from './PriceGroups';
import PriceLists from './PriceLists';
import Products from './Products';
import BillingCodes from './BillingCodes';
import BillingActivations from './BillingActivations';

import "react-datepicker/dist/react-datepicker.css";

interface IAppUIProps {
}

interface IAppUIState {
    showLoginButton: boolean;
    authenticated: boolean;
}

export default class App extends React.PureComponent<IAppUIProps, IAppUIState> {
    constructor(props: IAppUIProps) {
        super(props);
        this.state = { showLoginButton: false, authenticated: false };
    }

    componentDidMount() {
        this.doSignOn();
    }

    doSignOn() {
        signIn((authenticated: boolean) => {
            this.setState({ showLoginButton: !authenticated, authenticated: authenticated });
        });
    }

    render() {
        return (
            <div className="App">
                {!this.state.authenticated && this.state.showLoginButton && <div className="Padded">
                    <a className="Button" onClick={() => { this.doSignOn() }}>Click here</a> to sign in to the Commerce Administration tool.
                </div>}
                {this.state.authenticated && <div>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/Tasks" component={Tasks} />
                            <Route path="/Carts" component={Carts} />
                            <Route path="/PriceGroups" component={PriceGroups} />
                            <Route path="/PriceLists" component={PriceLists} />
                            <Route path="/Products" component={Products} />
                            <Route path="/BillingCodes" component={BillingCodes} />
                            <Route path="/BillingActivations" component={BillingActivations} />
                        </Switch>
                    </BrowserRouter>
                </div>}
            </div>
        );
    }
}