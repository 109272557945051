import * as React from 'react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ConnectionInfo from './ConnectionInfo';
import { PriceList } from "@testout/testout-commerce/models/commerce/PriceList";
import { Api, ICCMeta } from './Api';
import Dialog from './Dialog';
import { IPriceList } from '@testout/testout-commerce/models/commerce/PriceList';
import { Product, ProductStatus } from '@testout/testout-commerce/models/commerce/Product';

import './EditPriceListDlg.scss';
import ico_Refresh from './images/ico_refresh.png';
import ico_Magnifier from './images/ico_magnifier.png';
import ico_Close from './images/ico_close.png';
import ico_Sort from './images/ico_sort.png';
import ico_SortUp from './images/ico_sortup.png';
import ico_SortDown from './images/ico_sortdown.png';
import ico_Trash from './images/ico_trash.png';
import ico_Calc from './images/ico_calculator.png';
import ico_Plus from './images/ico_plus.png';
import ico_Save from './images/ico_save.png';
import { Price, PriceType } from '@testout/testout-commerce/models/commerce/Price';
import { RoundingPolicy } from '@testout/testout-commerce/models/Currency';
import { PriceDiscount } from '@testout/testout-commerce/models/commerce/PriceDiscount';

interface IPriceListDlgProps extends RouteComponentProps {
    isNew: boolean;
    priceList: PriceList;
    onSave: (priceList: PriceList) => void;
    onCancel: () => void;
}

interface IPriceListDlgState {
    fetchingCommand: boolean;
    sortColumn: string;
    sortDirection: string;
    editMode: boolean;
    priceIsNew?: boolean;

    saveError?: string;
    priceId: string;
    name: string;
    currencyCode: string;
    effectiveDate: Date;
    expirationDate: Date;
    prices: Price[];
    selectedIndex?: number;

    editError?: string;
    editProduct?: string;
    editUnit?: string;
    editPriceType?: PriceType;
    editAmount?: string;
    editRounding?: RoundingPolicy;
    editDiscount?: string;

    showDiscountSearch: boolean;
    discountSearch?: string;
    discounts?: PriceDiscount[];
    showProductSearch: boolean;
    productSearch?: string;
    products?: Product[];
}

class PriceLists extends React.PureComponent<IPriceListDlgProps, IPriceListDlgState> {
    constructor(props: IPriceListDlgProps) {
        super(props);
        this.state = this.applyProps(props);
    }

    _productSearchRef = React.createRef<HTMLInputElement>();

    componentWillReceiveProps(props: IPriceListDlgProps) {
        this.setState(this.applyProps(props))
    }

    applyProps(props: IPriceListDlgProps) {
        return {
            priceId: props.priceList.priceId.toString(),
            name: props.priceList.name ?? "",
            currencyCode: props.priceList.currencyCode ?? "USD",
            effectiveDate: props.priceList.effectiveDate ? moment(props.priceList.effectiveDate).toDate() : moment().toDate(),
            expirationDate: props.priceList.expirationDate ? moment(props.priceList.expirationDate).toDate() : moment().add(1, 'day').toDate(),
            prices: [...props.priceList.prices],
            fetchingCommand: false, sortColumn: "Product", sortDirection: "Up", editMode: false,
            showDiscountSearch: false
        } as IPriceListDlgState;
    }

    selectPrice(index: number, doEdit: boolean | undefined = undefined, priceIsNew: boolean = false) {
        if (!this.state.fetchingCommand) {
            if (doEdit != null)
                setTimeout(() => {
                    let price = this.state.prices[index];
                    this.setState({
                        selectedIndex: index, editMode: doEdit, editError: undefined, priceIsNew: priceIsNew,
                        editProduct: price.product?.productNumber, editUnit: price.unitCode,
                        editPriceType: price.priceType, editAmount: price.amountOrPercent.toFixed(2),
                        editRounding: price.roundingPolicy, editDiscount: price.discountCode
                    })
                }, 1);
            else this.setState({ selectedIndex: index, editMode: this.state.editMode && this.state.selectedIndex === index });
        }
    }

    doSort(cb?: () => void) {
        let arr = [...this.state.prices];
        arr.sort((a, b) => {
            let result = 0;
            switch (this.state.sortColumn) {
                case "Product": result = a.productNumber.localeCompare(b.productNumber); break;
                case "Unit": result = a.unitCode.localeCompare(b.unitCode); break;
                case "PriceType": result = a.priceType - b.priceType; break;
                case "Price": result = a.amountOrPercent - b.amountOrPercent; break;
                case "Rounding": result = a.roundingPolicy - b.roundingPolicy; break;
                case "Discount": result = (a.discountCode ?? "").localeCompare((b.discountCode ?? "")); break;
            }
            if (this.state.sortDirection === "Down")
                result = result * -1;
            return result;
        });
        this.setState({ prices: arr, selectedIndex: undefined }, () => {
            if (cb)
                cb();
        });
    }

    toggleSort(column: string) {
        if (this.state.sortColumn === column) {
            this.setState({ sortDirection: this.state.sortDirection === "Up" ? "Down" : "Up" }, () => this.doSort());
        }
        else this.setState({ sortColumn: column, sortDirection: "Down" }, () => this.doSort());
    }

    getSortIcon(column: string) {
        if (this.state.sortColumn === column) {
            switch (this.state.sortDirection) {
                case "Up": return ico_SortUp;
                case "Down": return ico_SortDown;
            }
        }
        return ico_Sort;
    }

    get selectedPrice(): Price | undefined {
        if (this.state.prices != null && this.state.selectedIndex != null)
            return this.state.prices[this.state.selectedIndex];
        return undefined;
    }

    componentDidMount() {
        this.doFetchProducts();
    }

    componentDidUpdate() {
        requestAnimationFrame(() => {
            let e = document.getElementById("PriceListEditAmount");
            if (e) {
                if (this.state.selectedIndex == this.state.prices.length - 1) {
                    e.id = "";
                    e.scrollIntoView();
                    e.focus();
                }
            }
        });
    }

    doDeletePrice() {
        let index = this.state.selectedIndex;
        if (index != null) {
            let arr = [...this.state.prices];
            arr.splice(index, 1);
            this.setState({ editMode: false, editError: undefined, selectedIndex: undefined, prices: arr });
        }
    }

    applyPrice() {
        let index = this.state.selectedIndex;
        if (index != null) {
            let amount = parseFloat(this.state.editAmount ?? "");
            if (isNaN(amount))
                this.setState({ editError: "Please enter a price amount or a percentage of list price for the product." });
            else {
                let price = this.state.prices[index];
                price.unitCode = this.state.editUnit ?? "";
                price.priceType = this.state.editPriceType as PriceType;
                price.amountOrPercent = isNaN(amount) ? 0 : amount;
                price.roundingPolicy = this.state.editRounding as RoundingPolicy;
                price.discountCode = this.state.editDiscount;
                this.swapPriceInState(index, price);
                this.setState({ editMode: false, editError: undefined });
            }
        }
    }

    swapPriceInState(index: number, updatePrice?: Price) {
        if (index === this.state.selectedIndex && updatePrice) {
            let arr = [...this.state.prices];
            arr[index] = updatePrice;
            this.setState({ prices: arr });
        }
    }

    doRefreshPrices() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true, editMode: false, editError: undefined, selectedIndex: undefined }, async () => {
                let pl = await Api.getPriceList(this.state.priceId);
                if (pl)
                    this.setState({ prices: [...pl.prices] });
                this.setState({ fetchingCommand: false });
            })
        }
    }

    doFetchDiscounts(cb?: () => void) {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let discounts = await Api.getDiscounts(this.state.discountSearch);
                this.setState({ fetchingCommand: false, discounts: discounts }, () => {
                    if (cb)
                        cb();
                });
            });
        }
    }

    clearDiscountSearch() {
        this.setState({ discountSearch: undefined }, () => {
            this.doFetchDiscounts();
        });
    }

    showDiscountSearch() {
        if (!this.state.fetchingCommand) {
            this.setState({ discountSearch: undefined }, () => {
                this.doFetchDiscounts(() => {
                    this.setState({ showDiscountSearch: true, discountSearch: undefined });
                });
            });
        }
    }

    doSavePriceList() {
        if (!this.state.fetchingCommand) {
            let error = "";
            if (this.state.name.length == 0)
                error = "Please enter a name for the price list. ";

            if (moment(this.state.expirationDate).startOf('day').toDate() <= moment(this.state.effectiveDate).startOf('day').toDate())
                error += "The expiration date must be greater than the effective date.";

            if (error.length > 0)
                this.setState({ saveError: error });
            else {
                this.setState({ saveError: undefined, fetchingCommand: true }, async () => {
                    let pl = new PriceList(this.props.priceList);
                    pl.name = this.state.name;
                    pl.currencyCode = this.state.currencyCode;
                    pl.effectiveDate = moment(this.state.effectiveDate).format("M/D/YYYY");
                    pl.expirationDate = moment(this.state.expirationDate).format("M/D/YYYY");
                    pl.prices = this.state.prices;

                    let updatedPL = await Api.updatePriceList(pl);

                    if (this.props.onSave && updatedPL)
                        this.props.onSave(updatedPL);
                });
            }
        }
    }

    doCancelPriceList() {
        if (this.props.onCancel)
            this.props.onCancel();
    }

    doFetchProducts(cb?: () => void) {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let products = await Api.getProducts(this.state.productSearch);
                this.setState({ fetchingCommand: false, products: products }, () => {
                    if (cb)
                        cb();
                });
            });
        }
    }

    clearProductSearch() {
        this.setState({ productSearch: undefined }, () => {
            this.doFetchProducts();
        });
    }

    doAddPrice() {
        if (!this.state.fetchingCommand) {
            this.setState({ productSearch: undefined }, () => {
                this.doFetchProducts(() => {
                    this.setState({ showProductSearch: true, productSearch: undefined }, () => {
                        const inpProductSearch = this._productSearchRef.current as HTMLInputElement;
                        inpProductSearch?.focus();
                    });
                });
            });
        }
    }

    addProductAsPrice(product: Product) {
        let prices = [...this.state.prices];
        let p = new Price();
        p.productNumber = product.productNumber;
        p.unitCode = product.defaultUnitCode ?? "1-Year";
        p.amountOrPercent = product.listPrice;
        prices.push(p);
        this.setState({ showProductSearch: false, prices: prices }, () => {
            this.selectPrice(prices.length - 1, true, true);
        });
    }

    cancelPrice() {
        if (this.state.priceIsNew)
            this.doDeletePrice()
        else this.setState({ editMode: false, editError: undefined })
    }

    render() {
        return <div className="PriceListEditDlg">
            <header>
                <div>
                    <div>
                        <button className="RibbonButton" onClick={() => this.doSavePriceList()}><img src={ico_Save} />SAVE AND RETURN</button>
                        <button className="RibbonButton" onClick={() => this.doCancelPriceList()}><img src={ico_Close} />CANCEL CHANGES</button>
                    </div>
                </div>
                <div>
                    <ConnectionInfo />
                </div>
            </header>
            <div className={this.state.fetchingCommand ? "Spinner" : ""}></div>
            <div className="PageTitle">
                <div>
                    <img src={ico_Calc} />
                    <div>
                        {this.props.isNew && <span>NEW PRICE LIST</span>}
                        {!this.props.isNew && <span>PRICE LIST: {this.props.priceList.priceId}</span>}
                        <span>{this.props.isNew ? "New" : this.props.priceList.name}</span>
                    </div>
                </div>
                <div>
                    <span>Staus</span>
                    <span>{this.props.isNew ? "New" : (this.state.expirationDate < moment().startOf('day').toDate() ? "Expired" : "Active")}</span>
                </div>
            </div>
            <div className="Columns">
                <div className="PriceListFields">
                    <div>
                        <h1>DETAILS</h1>
                    </div>
                    {this.state.saveError && <div className="Error">{this.state.saveError}</div>}
                    <div>
                        <div><span>Price List</span><span><input disabled={true} value={this.state.priceId} onChange={(e) => {this.setState({priceId: e.target.value})}} /></span></div>
                        <div><span>Name</span><span><input value={this.state.name} onChange={(e) => {this.setState({name: e.target.value})}} /></span></div>
                        <div><span>Currency</span><span>
                            <select value={this.state.currencyCode} onChange={(e) => {this.setState({currencyCode: e.target.value})}}>
                                <option value="AUD">AUD - Australian Dollar</option>
                                <option value="CAD">CAD - Canadian Dollar</option>
                                <option value="EUR">EUR - Euro</option>
                                <option value="GBP">GBP - GBP - British Pound</option>
                                <option value="NZD">NZD - New Zealand Dollar</option>
                                <option value="ZAR">ZAR - South African Rand</option>
                                <option value="USD">USD - US Dollar</option>
                            </select>
                        </span></div>
                        <div><span>Effective Date</span><span><DatePicker selected={this.state.effectiveDate} onChange={(d) => { this.setState({effectiveDate: d ?? new Date()}) }} /></span></div>
                        <div><span>Expiration Date</span><span><DatePicker selected={this.state.expirationDate} onChange={(d) => {this.setState({expirationDate: d ?? new Date()})}} /></span></div>
                    </div>
                </div>
                <div className="Prices">
                    <div>
                        <h1>PRICES</h1>
                        <span>
                            <button onClick={() => this.doAddPrice()} className="ChromelessButton"><img src={ico_Plus} /></button>
                            <button disabled={this.selectedPrice == null} onClick={() => this.doDeletePrice()} className="ChromelessButton"><img src={ico_Trash} /></button>
                        </span>
                    </div>
                    {this.state.editError && <div className="Error">{this.state.editError}</div>}
                    <div className="PricesHeader PriceItem">
                        <div>
                            <span>Product<img src={this.getSortIcon("Product")} onClick={() => this.toggleSort("Product")} /></span>
                            <span>Unit<img src={this.getSortIcon("Unit")} onClick={() => this.toggleSort("Unit")} /></span>
                            <span>Price Type<img src={this.getSortIcon("PriceType")} onClick={() => this.toggleSort("PriceType")} /></span>
                            <span>Price<img src={this.getSortIcon("Price")} onClick={() => this.toggleSort("Price")} /></span>
                            <span>Rounding<img src={this.getSortIcon("Rounding")} onClick={() => this.toggleSort("Rounding")} /></span>
                            <span>Discount<img src={this.getSortIcon("Discount")} onClick={() => this.toggleSort("Discount")} /></span>
                            <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doRefreshPrices()} /></button></div>
                        </div>
                    </div>
                    <div className={`PricesScroller${this.state.editError ? " HasError" : ""}`}>
                        {this.state.prices.map((p, index) => {
                            return <div className={`PriceItem ${this.state.selectedIndex === index ? "Selected" : ""}`} key={index} onMouseDown={() => this.selectPrice(index)}>
                                <div>
                                    <span>
                                        {(!this.state.editMode || index !== this.state.selectedIndex) && <a onClick={() => this.selectPrice(index, true)}>{p.productNumber}</a>}
                                        {(this.state.editMode && index === this.state.selectedIndex) && p.productNumber}
                                    </span>
                                    <span>
                                        {(!this.state.editMode || index !== this.state.selectedIndex) && p.unitCode}
                                        {this.state.editMode && index === this.state.selectedIndex && <select value={this.state.editUnit} onChange={(e) => { this.setState({ editUnit: e.target.value }) }}>
                                            <option value="Each">Each</option>
                                            <option value="18-Month">18-Month</option>
                                            <option value="1-Month">1-Month</option>
                                            <option value="1-Year">1-Year</option>
                                            <option value="2-Year">2-Year</option>
                                            <option value="5-Month">5-Month</option>
                                            <option value="6-Month">6-Month</option>
                                        </select>}
                                    </span>
                                    <span>
                                        {(!this.state.editMode || index !== this.state.selectedIndex) && PriceType[p.priceType]}
                                        {this.state.editMode && index === this.state.selectedIndex && <select value={this.state.editPriceType} onChange={(e) => { this.setState({ editPriceType: parseInt(e.target.value) as PriceType }) }}>
                                            <option value="0">Amount</option>
                                            <option value="1">Percent</option>
                                        </select>}
                                    </span>
                                    <span>
                                        {(!this.state.editMode || index !== this.state.selectedIndex) && <>${p.amountOrPercent.toFixed(2)}</>}
                                        {this.state.editMode && index === this.state.selectedIndex && <input id="PriceListEditAmount" value={this.state.editAmount} onChange={(e) => { this.setState({ editAmount: e.target.value }) }} />}
                                    </span>
                                    <span>
                                        {(!this.state.editMode || index !== this.state.selectedIndex) && RoundingPolicy[p.roundingPolicy]}
                                        {this.state.editMode && index === this.state.selectedIndex && <select value={this.state.editRounding} onChange={(e) => { this.setState({ editRounding: parseInt(e.target.value) as RoundingPolicy }) }}>
                                            <option value="0">RoundUp</option>
                                            <option value="1">RoundDown</option>
                                            <option value="2">RoundMid</option>
                                        </select>}
                                    </span>
                                    <span>
                                        {this.state.editMode && index === this.state.selectedIndex && <button onClick={() => this.showDiscountSearch()}>Select</button>}
                                        <span>{this.state.editMode && index === this.state.selectedIndex ? this.state.editDiscount : p.discountCode}</span>
                                    </span>
                                    {this.state.editMode && index === this.state.selectedIndex && <span><img src={ico_Save} onClick={() => { this.applyPrice() }} /><img src={ico_Close} onClick={() => this.cancelPrice()} /></span>}
                                </div>
                                <div>{this.state.products?.find((fp) => { return p.productNumber === fp.productNumber })?.title}</div>
                            </div>
                        })}
                    </div>
                    <div className="PriceListFooter">
                        <span>{`${this.state.prices.length} record${this.state.prices.length === 1 ? "" : "s"}`}</span>
                    </div>
                </div>
            </div>
            {this.state.showDiscountSearch && <Dialog>
                <div className="SelectDiscount">
                    <div><h1>Select a Discount</h1>
                        <div className="Search">
                            <input placeholder="Search for records" value={this.state.discountSearch ?? ""} onChange={(e) => this.setState({ discountSearch: e.target.value })} onBlur={() => this.doFetchDiscounts()} onKeyPress={(e) => { if (e.key == "Enter") this.doFetchDiscounts() }} />
                            {!this.state.discountSearch && <img src={ico_Magnifier} onMouseDown={() => this.doFetchDiscounts()} />}
                            {this.state.discountSearch && <img src={ico_Close} onMouseDown={() => this.clearDiscountSearch()} />}
                        </div>
                    </div>
                    <div>{(this.state.discounts ?? [] as PriceDiscount[]).map((d) => <div onMouseDown={() => this.setState({ showDiscountSearch: false, editDiscount: d.discountCode })}><span>{d.discountCode}</span><span></span>{d.name}</div>)}</div>
                    <div><button onClick={() => this.setState({ showDiscountSearch: false })}>Cancel</button></div>
                </div>
            </Dialog>}
            {this.state.showProductSearch && <Dialog>
                <div className="SelectProduct">
                    <div><h1>Select a Product</h1>
                        <div className="Search">
                            <input ref={this._productSearchRef} placeholder="Search for records" value={this.state.productSearch ?? ""} onChange={(e) => this.setState({ productSearch: e.target.value })} onBlur={() => this.doFetchProducts()} onKeyPress={(e) => { if (e.key == "Enter") this.doFetchProducts() }} />
                            {!this.state.productSearch && <img src={ico_Magnifier} onMouseDown={() => this.doFetchProducts()} />}
                            {this.state.productSearch && <img src={ico_Close} onMouseDown={() => this.clearProductSearch()} />}
                        </div>
                    </div>
                    <div>{(this.state.products ?? [] as Product[]).map((p) => <div onMouseDown={() => this.addProductAsPrice(p)}><span>{p.productNumber}</span><span></span>{p.title}</div>)}</div>
                    <div><button onClick={() => this.setState({ showProductSearch: false })}>Cancel</button></div>
                </div>
            </Dialog>}
        </div>;
    }
}

export default withRouter(PriceLists);