//@ts-ignore
import { PublicClientApplication } from "@azure/msal-browser";
//@ts-ignore
import { AuthenticationResult, AccountInfo } from "@azure/msal-common";

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENTID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANTID}/`,
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    },
}

const msalApp = new PublicClientApplication(msalConfig as any);

let accessToken: string | null = null;
let signOnCB: ((authenticated: boolean) => void) | null = null;

function handleTokenResponse(response: AuthenticationResult) {
    accessToken = response.accessToken;
    if (signOnCB != null) {
        signOnCB(accessToken != null);
        signOnCB = null;
    }
}

function acquireToken(account: AccountInfo) {
    const tokenRequest = {
        scopes: [process.env.REACT_APP_MSAL_TOKENSCOPE],
        account: account
    };

    return msalApp.acquireTokenSilent(tokenRequest as any)
        .then(response => {
            handleTokenResponse(response);
            setTimeout(() => { acquireToken(account) }, 900000 /*15 minutes*/);
        })
        .catch(error => {
            if (error) {
                return msalApp.acquireTokenPopup(tokenRequest as any)
                    .then(handleTokenResponse)
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                console.warn(error);
                //Even though something bad happened this time, we might still succeed again on the next retry
                setTimeout(() => { acquireToken(account) }, 900000 /*15 minutes*/);
            }
        });
}

function getAuthState() {
    const currentAccounts = msalApp.getAllAccounts();

    if (currentAccounts == null || currentAccounts.length === 0) {
        return {
            username: null,
            account: null,
            isAuthenticated: false
        };
    }
    else if (currentAccounts.length > 0) {
        return {
            username: currentAccounts[0].username,
            account: msalApp.getAccountByUsername(currentAccounts[0].username),
            isAuthenticated: true
        };
    }
}

export function getUserDisplayName() {
    const currentAccounts = msalApp.getAllAccounts();

    if (currentAccounts != null && currentAccounts.length > 0)
        return currentAccounts[0].name;

    return null;
}

export function getUsername() {
    const currentAccounts = msalApp.getAllAccounts();

    if (currentAccounts != null && currentAccounts.length > 0)
        return currentAccounts[0].username;

    return null;
}

export function signIn(cb: (authenticated: boolean) => void) {
    signOnCB = cb;
    let currentAuth = getAuthState() as any;
    if (currentAuth.isAuthenticated) {
        acquireToken(currentAuth.account);
    }
    else {
        const loginRequest = {
            scopes: ["openid", "profile", "offline_access"]
        }

        msalApp.loginPopup(loginRequest)
            .then((response) => { acquireToken(response.account as any); })
            .catch(err => {
                if (signOnCB) signOnCB(false);
            });
    }
}

export function makeFetch(uri: string, method: "GET" | "PUT" | "POST" | "DELETE" = "GET", body: string | null = null): Promise<Response> {
    return fetch(uri, {
        method: method,
        headers:
            accessToken != null
                ? {
                    "Authorization": `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
                : { 'Content-Type': 'application/json' },
        body: body
    })
}