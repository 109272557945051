import * as React from 'react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import moment from 'moment';
import ConnectionInfo from './ConnectionInfo';
import { Cart, CartStatus } from "@testout/testout-commerce/models/commerce/Cart";
import { Api, ICCMeta } from './Api';
import Dialog from './Dialog';

import './Carts.scss';
import ico_Home from './images/ico_home.png';
import ico_Cart from './images/ico_cart.png';
import ico_LeftArrowLine from './images/ico_leftarrowline.png';
import ico_LeftArrow from './images/ico_leftarrow.png';
import ico_RightArrow from './images/ico_rightarrow.png';
import ico_Refresh from './images/ico_refresh.png';
import ico_Magnifier from './images/ico_magnifier.png';
import ico_Close from './images/ico_close.png';
import ico_Sort from './images/ico_sort.png';
import ico_SortUp from './images/ico_sortup.png';
import ico_SortDown from './images/ico_sortdown.png';
import ico_Register from './images/ico_register.png';
import { Customer } from '@testout/testout-commerce/models/commerce/Customer';

interface ICartsProps extends RouteComponentProps {

}

interface ICartsState {
    fetchingList: boolean;
    fetchingCart: boolean;
    fetchingCommand: boolean;
    carts: Cart[];
    pageIndex: number;
    selectedIndex?: number;
    search?: string;
    sortColumn: string;
    sortDirection: string;
    showCartDetails?: boolean;

    showPayment?: boolean;
    paymentErrors?: JSX.Element[];
    cardType?: string;
    cardSuffix?: string;
    cardMM?: string;
    cardYY?: string;
    payTransCode?: string;
}

class Carts extends React.PureComponent<ICartsProps, ICartsState> {
    constructor(props: ICartsProps) {
        super(props);
        this.state = { fetchingList: false, fetchingCart: false, fetchingCommand: false, pageIndex: 0, carts: [], sortColumn:"CartDate", sortDirection: "Down" };
    }

    componentDidMount() {
        this.doFetchList();
    }

    doFetchList() {
        if (!this.state.fetchingList) {
            this.setState({ fetchingList: true, selectedIndex: undefined }, async () => {
                let pageIndex = this.state.pageIndex;
                let carts = await Api.getCarts(this.state.search, pageIndex);
                if (this.state.pageIndex === pageIndex)
                    this.setState({ fetchingList: false, carts: carts });
                this.doSort();
            });
        }
    }

    clearSearch() {
        this.setState({ search: undefined }, () => {
            this.doFetchList();
        });
    }

    selectItem(index: number, showCartDetails: boolean | undefined = undefined) {
        if (!this.state.fetchingList) {
            this.setState({ selectedIndex: index }, () => {
                if (index != null) {
                    this.setState({ fetchingCart: true }, async () => {
                        let cart = await Api.getCart(this.state.carts[index].orderNumber);
                        if (this.state.selectedIndex === index && cart != null) {
                            let carts = [...this.state.carts];
                            carts[index] = cart;
                            this.setState({ carts: carts });
                            if (showCartDetails != null)
                                this.setState({ showCartDetails: showCartDetails });
                        }
                        this.setState({ fetchingCart: false });
                    });
                }
            });
        }
    }

    setPageIndex(pageIndex: number) {
        if (pageIndex < 0)
            pageIndex = 0;
        this.setState({ pageIndex: pageIndex, selectedIndex: undefined }, () => {
            this.doFetchList();
        });
    }

    showPaymentOrSubmit() {
        let doSubmit = false;
        if (!this.state.fetchingList && !this.state.fetchingCart && this.state.selectedIndex != undefined) {
            this.setState({ fetchingCart: true }, async () => {
                if (this.selectedCart && this.state.selectedIndex != undefined) {
                    let index = this.state.selectedIndex;
                    let cart = await Api.getCart(this.selectedCart?.orderNumber);
                    if (this.state.selectedIndex === index && cart != null) {
                        let carts = [...this.state.carts];
                        carts[index] = cart;
                        this.setState({ carts: carts });
                        if (cart.balanceDueAmount > 0)
                            this.setState({ showPayment: true, cardType: "VI", cardSuffix: "", cardMM: "01", cardYY: (new Date()).getFullYear().toString(), payTransCode: "", paymentErrors: undefined });
                        else doSubmit = true;
                    }
                    this.setState({ fetchingCart: false }, () => {
                        if (doSubmit)
                            this.doSubmitAsOrder();
                    });
                }
            });
        }
    }

    attemptPayment() {
        let errors = [] as JSX.Element[];
        if (!this.state.cardSuffix)
            errors.push(<div>Please enter a Card Suffix (the last 4 digits of the card number.)</div>);
        if (!this.state.payTransCode)
            errors.push(<div>Please enter a Card Transaction Code</div>);
        if (errors.length > 0) {
            this.setState({ paymentErrors: errors });
            return;
        }

        this.setState({ showPayment: false }, () => {
            let ccMeta = {
                cardBrand: this.state.cardType,
                cardSuffix: this.state.cardSuffix,
                expMonth: this.state.cardMM,
                expYear: this.state.cardYY,
                transCode: this.state.payTransCode
            } as ICCMeta;
            this.doSubmitAsOrder(ccMeta);
        });
    }

    async doSubmitAsOrder(ccMeta: ICCMeta | undefined = undefined) {
        if (!this.state.fetchingCart && !this.state.fetchingCommand && this.state.selectedIndex != null) {
            this.setState({ fetchingCommand: true }, async () => {
                let index = this.state.selectedIndex;
                let cart = this.selectedCart;
                if (index != null && cart != null) {
                    let updatedCart = await Api.submitCartAsOrder(cart.orderNumber, ccMeta);
                    if (this.state.selectedIndex === index && updatedCart != null) {
                        let carts = [...this.state.carts];
                        carts[index] = updatedCart;
                        this.setState({ carts: carts });
                    }
                    this.setState({ fetchingCommand: false });
                }
            });
        }
    }

    doSort() {
        let arr = [...this.state.carts];
        arr.sort((a, b) => {
            let result = 0;
            switch (this.state.sortColumn) {
                case "CartNumber": result = a.orderNumber.localeCompare(b.orderNumber); break;
                case "CartDate": result = moment(a.orderDate).valueOf() - moment(b.orderDate).valueOf(); break;
                case "Status": result = a.status - b.status; break;
                case "Institution": result = a.institutionCustomer.name.localeCompare(b.institutionCustomer.name); break;
                case "ShippingCustomer": result = a.shippingCustomer.address.contactPerson.toString().localeCompare(b.shippingCustomer.address.contactPerson.toString()); break;
                case "CartTotal": result = a.totalAmount - b.totalAmount; break;
            }
            if (this.state.sortDirection === "Down")
                result = result * -1;
            return result;
        });
        this.setState({ carts: arr, selectedIndex: undefined });
    }

    toggleSort(column: string) {
        if (this.state.sortColumn === column) {
            this.setState({ sortDirection: this.state.sortDirection === "Up" ? "Down" : "Up" }, () => this.doSort());
        }
        else this.setState({ sortColumn: column, sortDirection: "Down" }, () => this.doSort());
    }

    getSortIcon(column: string) {
        if (this.state.sortColumn === column) {
            switch (this.state.sortDirection) {
                case "Up": return ico_SortUp;
                case "Down": return ico_SortDown;
            }
        }
        return ico_Sort;
    }

    get selectedCart(): Cart | undefined {
    if (this.state.carts != null && this.state.selectedIndex != null)
            return this.state.carts[this.state.selectedIndex];
        return undefined;
    }

    customerRender(billing: boolean) {
        let customer = billing ?
            this.selectedCart?.billingCustomer ?? this.selectedCart?.shippingCustomer :
            this.selectedCart?.shippingCustomer;
        return (
            <div>
                <span>{billing ? "Billed To:" : "Deliver To:"}</span>
                <span>{customer?.address.contactPerson.toString()}</span>
                <span>{customer?.address.postal.line1}</span>
                <span>{customer?.address.postal.line2}</span>
                <span>{customer?.address.postal.line3}</span>
                <span>{customer?.address.postal.city}, {customer?.address.postal.stateOrProvince} {customer?.address.postal.postalCode}</span>
                <span>{customer?.address.phone}</span>
                <span>{customer?.address.email}</span>
            </div>);
    }

    get years() {
        let years = [];
        for (let i = 0; i <= 9; i++)
            years.push(new Date().getFullYear() + i);
        return years;
    }

    render() {
        return <div className="Carts">
            <header>
                <div>
                    <div>
                        <button className="RibbonButton" onClick={() => { this.props.history.push("/") }}><img src={ico_Home} />HOME</button>
                        <button className="RibbonButton" onClick={() => this.showPaymentOrSubmit()} disabled={this.state.fetchingCart || this.state.selectedIndex == null || this.state.carts[this.state.selectedIndex].status == CartStatus.Closed}><img src={ico_Register} />SUBMIT CART AS SALES ORDER</button>
                    </div>
                </div>
                <div>
                    <ConnectionInfo />
                </div>
            </header>
            <div className={this.state.fetchingList || this.state.fetchingCart || this.state.fetchingCommand ? "Spinner" : ""}></div>
            <div>
                <div className="PageTitle">
                    <img src={ico_Cart} />
                    <span>Shopping Carts</span>
                </div>
                <div className="Search">
                    <input placeholder="Search for records" value={this.state.search ?? ""} onChange={(e) => this.setState({ search: e.target.value })} onBlur={() => this.doFetchList} onKeyPress={(e) => { if (e.key == "Enter") this.doFetchList() }} />
                    {!this.state.search && <img src={ico_Magnifier} onMouseDown={() => this.doFetchList()} />}
                    {this.state.search && <img src={ico_Close} onMouseDown={() => this.clearSearch()} />}
                </div>
            </div>
            <div className="CartList">
                <div className="CartHeader CartItem">
                    <span>Cart Number<img src={this.getSortIcon("CartNumber")} onClick={() => this.toggleSort("CartNumber")} /></span>
                    <span>Cart Date<img src={this.getSortIcon("CartDate")} onClick={() => this.toggleSort("CartDate")} /></span>
                    <span>Status<img src={this.getSortIcon("Status")} onClick={() => this.toggleSort("Status")} /></span>
                    <span>Institution<img src={this.getSortIcon("Institution")} onClick={() => this.toggleSort("Institution")} /></span>
                    <span>Shipping Customer<img src={this.getSortIcon("ShippingCustomer")} onClick={() => this.toggleSort("ShippingCustomer")} /></span>
                    <span>Cart Total<img src={this.getSortIcon("CartTotal")} onClick={() => this.toggleSort("CartTotal")} /></span>
                    <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchList()} /></button></div>
                </div>
                <div className="CartScroller">
                    {this.state.carts.map((cart, index) => {
                        return <div className={`CartItem ${this.state.selectedIndex === index ? "Selected" : ""}`} key={index} onClick={() => this.selectItem(index)}>
                            <span><a onClick={() => this.selectItem(index, true)}>{cart.orderNumber}</a></span>
                            <span>{moment(cart.orderDate).format("M/D/YYYY h:mm:ss A")}</span>
                            <span>{CartStatus[cart.status]}</span>
                            <span>{cart.institutionCustomer.name}</span>
                            <span>{cart.shippingCustomer.address.contactPerson.toString()}</span>
                            <span>{"$" + cart.totalAmount.toFixed(2)}</span>
                        </div>
                    })}
                </div>
                <div className="CartFooter">
                    <span>{`${this.state.carts.length}${this.state.carts.length === 100 ? "+" : ""} record${this.state.carts.length === 1 ? "" : "s"}`}</span>
                    <div>
                        <button disabled={this.state.pageIndex <= 1} onClick={() => this.setPageIndex(0)}><img src={ico_LeftArrowLine} /></button>
                        <button disabled={this.state.pageIndex === 0} onClick={() => this.setPageIndex(this.state.pageIndex - 1)}><img src={ico_LeftArrow} /></button>
                        <span>{`Page ${this.state.pageIndex + 1}`}</span>
                        <button disabled={this.state.carts.length < 100} onClick={() => this.setPageIndex(this.state.pageIndex + 1)}><img src={ico_RightArrow} /></button>
                    </div>
                </div>
            </div>
            {this.state.showCartDetails && <Dialog>
                <div className="CartDetail">
                    <div>
                        <span><span>Date:</span>{moment(this.selectedCart?.orderDate).format("M/D/YYYY h:mm:ss A")}</span>
                        <span><span>Terms:</span>{this.selectedCart?.paymentTerms}</span>
                    </div>
                    <div>{CartStatus[this.selectedCart?.status ?? 0]} Cart</div>
                    <div>{this.selectedCart?.orderNumber}</div>
                    <div><span>Institution:</span>{this.selectedCart?.institutionCustomer.name}</div>
                    <div>
                        {this.customerRender(true)}
                        {this.customerRender(false)}
                    </div>
                    <div><span>Delivery:</span>{this.selectedCart?.fulfillmentProvider.map((kv) => <span key={kv.Key}>{kv.Value}</span> )}</div>
                    <div>{this.selectedCart?.fulfillmentComment}</div>
                    <div>
                        <span>Item</span>
                        <span>Description</span>
                        <span>Qty</span>
                        <span>Price</span>
                        <span>Total</span>
                    </div>
                    <div>
                        {this.selectedCart?.items.map((ci, index) => <div key={index}>
                            <span>{ci.productNumber.toString()}</span>
                            <span>{ci.title}</span>
                            <span>{ci.quantity}</span>
                            <span>${ci.unitPrice.toFixed(2)}</span>
                            <span>${(ci.quantity * ci.unitPrice).toFixed(2)}</span>
                        </div>)}
                    </div>
                    <div><span></span><span>Subtotal</span><span>${this.selectedCart?.totalItemAmount.toFixed(2)}</span></div>
                    <div><span></span><span>- Discount</span><span>${this.selectedCart?.totalDiscountAmount.toFixed(2)}</span></div>
                    <div><span></span><span>Sales Tax</span><span>${this.selectedCart?.totalTaxAmount.toFixed(2)}</span></div>
                    <div><span></span><span>Freight</span><span>${this.selectedCart?.freightAmount.toFixed(2)}</span></div>
                    <div><span></span><span>Total</span><span>${this.selectedCart?.totalAmount.toFixed(2)}</span></div>
                    <div><span></span><span>- Payments</span><span>${this.selectedCart?.totalPaymentAmount.toFixed(2)}</span></div>
                    <div><span></span><span>Due</span><span>${this.selectedCart?.balanceDueAmount.toFixed(2)}</span></div>
                    <div><button className="Button" onClick={() => { this.setState({ showCartDetails: false }) }}>Close</button></div>
                </div>
            </Dialog>}
            {this.state.showPayment && <Dialog>
                <div className="Payment">
                    <span>PAYMENT</span>
                    <div className={this.state.paymentErrors ? "Error" : ""}><span>{this.state.paymentErrors}</span></div>
                    <div><span>Amount</span><span>${this.selectedCart?.totalAmount.toFixed(2)}</span></div>
                    <div><span>Card Type</span><span>
                        <select value={this.state.cardType} onChange={(e) => this.setState({ cardType: e.target.value })}>
                            <option value="VI">Visa</option>
                            <option value="MC">MasterCard</option>
                            <option value="DS">Discover</option>
                            <option value="AE">American Express</option>
                            <option value="PP">PayPal</option>
                        </select>
                    </span></div>
                    <div><span>Card Suffix (4 digits)</span><span>
                        <input value={this.state.cardSuffix} onChange={(e) => this.setState({ cardSuffix: e.target.value })} />
                    </span></div>
                    <div><span>Card Expiration (MMYY)</span><div>
                        <select value={this.state.cardMM} onChange={(e) => this.setState({ cardMM: e.target.value })}>
                            <option value="01">01 - January</option>
                            <option value="02">02 - February</option>
                            <option value="03">03 - March</option>
                            <option value="04">04 - April</option>
                            <option value="05">05 - May</option>
                            <option value="06">06 - June</option>
                            <option value="07">07 - July</option>
                            <option value="08">08 - August</option>
                            <option value="09">09 - September</option>
                            <option value="10">10 - October</option>
                            <option value="11">11 - November</option>
                            <option value="12">12 - December</option>
                        </select>
                        <select value={this.state.cardYY} onChange={(e) => this.setState({ cardYY: e.target.value })}>
                            {this.years.map((yyyy) => <option key={yyyy} value={yyyy}>{yyyy}</option>)}
                        </select>
                    </div></div>
                    <div><span>Card Transaction Code</span><span>
                        <input value={this.state.payTransCode} onChange={(e) => this.setState({ payTransCode: e.target.value })} />
                    </span></div>
                    <div>
                        <button className="Button" onClick={() => { this.attemptPayment() }}>Continue</button>
                        <button className="ChromelessButton Underlined" onClick={() => { this.setState({ showPayment: false }) }}>Cancel</button>
                    </div>
                </div>
            </Dialog>}
        </div>;
    }
}

export default withRouter(Carts);