import * as React from 'react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import moment from 'moment';
import ConnectionInfo from './ConnectionInfo';
import { Product, ProductStatus, ProductType } from "@testout/testout-commerce/models/commerce/Product";
import { ProductUnitType, IProductUnitType } from "@testout/testout-commerce/models/commerce/ProductUnitType";
import { Api, ICCMeta } from './Api';
import EditProductDlg from './EditProductDlg';

import './Products.scss';
import ico_Home from './images/ico_home.png';
import ico_Refresh from './images/ico_refresh.png';
import ico_Magnifier from './images/ico_magnifier.png';
import ico_Close from './images/ico_close.png';
import ico_Sort from './images/ico_sort.png';
import ico_SortUp from './images/ico_sortup.png';
import ico_SortDown from './images/ico_sortdown.png';
import ico_Trash from './images/ico_trash.png';
import ico_Box from './images/ico_box.png';
import ico_Plus from './images/ico_plus.png';
import ico_Save from './images/ico_save.png';
import ico_Sunrise from './images/ico_sunrise.png';
import ico_Sunset from './images/ico_sunset.png';
import { ProductFamily, IProductFamily } from '@testout/testout-commerce/models/commerce/ProductFamily';
import { IProductUnit, ProductUnit } from '@testout/testout-commerce/models/commerce/ProductUnit';

interface IProductsProps extends RouteComponentProps {

}

interface IProductsState {
    fetchingProducts: boolean;
    fetchingUnitTypes: boolean;
    fetchingFamilies: boolean;
    fetchingCommand: boolean;

    products: Product[];
    unitTypes: ProductUnitType[];
    families: ProductFamily[];

    selectedPIndex?: number;
    selectedUTIndex?: number;
    selectedUIndex?: number;
    selectedFIndex?: number;

    search?: string;
    sortColumn: string;
    sortDirection: string;
    uSortColumn: string;
    uSortDirection: string;
    fSortColumn: string;
    fSortDirection: string;

    editProduct?: Product;
    productIsNew?: boolean;

    unitTypeEditError?: string;
    editUnitTypeMode: boolean;
    editUnitType?: string;
    editBaseUnit?: string;

    unitEditError?: string;
    editUnitMode: boolean;
    editUnitCode?: string;
    editQuantity?: string;

    familyEditError?: string;
    editFamilyMode: boolean
    editFamilyCode?: string;
    editFamilyName?: string;
}

class Products extends React.PureComponent<IProductsProps, IProductsState> {
    constructor(props: IProductsProps) {
        super(props);
        this.state = {
            fetchingProducts: false, fetchingUnitTypes: false, fetchingFamilies: false, fetchingCommand: false,

            products: [], unitTypes: [], families: [],

            sortColumn: "Title", sortDirection: "Up",
            uSortColumn: "UnitType", uSortDirection: "Up",
            fSortColumn: "Family", fSortDirection: "Up",

            editUnitTypeMode: false,
            editUnitMode: false,
            editFamilyMode: false
        };
    }

    componentDidMount() {
        this.doFetchProducts();
        this.doFetchUnitTypes();
        this.doFetchFamilies();
    }

    _lastSearch: string | undefined = undefined;
    doFetchProducts(cb?: () => void) {
        if (!this.state.fetchingProducts) {
            this.setState({ fetchingProducts: true, selectedPIndex: undefined }, async () => {
                this._lastSearch = this.state.search;
                let products = await Api.getProducts(this.state.search, true);
                this.setState({ fetchingProducts: false, products: products });
                this.doSortProducts();
                if (cb != null)
                    cb();
            });
        }
    }

    clearSearch() {
        this.setState({ search: undefined }, () => {
            this.doFetchProducts();
        });
    }

    selectProduct(index: number, doEdit: boolean | undefined = undefined) {
        if (!this.state.fetchingProducts) {
            if (doEdit)
                setTimeout(() => {
                    this.setState({ selectedPIndex: index, editProduct: this.state.products[index], productIsNew: false });
                }, 1);
            else this.setState({ selectedPIndex: index });
        }
    }

    doSortProducts(cb?: () => void) {
        let arr = [...this.state.products];
        arr.sort((a, b) => {
            let result = 0;
            switch (this.state.sortColumn) {
                case "ProductNumber": result = (a.productNumber ?? "").localeCompare(b.productNumber ?? ""); break;
                case "Title": result = (a.title ?? "").localeCompare(b.title ?? ""); break;
                case "Culture": result = (a.culture ?? "").localeCompare(b.culture ?? ""); break;
                case "ProductType": result = a.productType - b.productType; break;
                case "UnitType": result = (a.unitType ?? "").localeCompare(b.unitType ?? ""); break;
            }
            if (this.state.sortDirection === "Down")
                result = result * -1;
            return result;
        });
        this.setState({ products: arr, selectedPIndex: undefined }, () => {
            if (cb)
                cb();
        });
    }

    toggleProductSort(column: string) {
        if (this.state.sortColumn === column) {
            this.setState({ sortDirection: this.state.sortDirection === "Up" ? "Down" : "Up" }, () => this.doSortProducts());
        }
        else this.setState({ sortColumn: column, sortDirection: "Down" }, () => this.doSortProducts());
    }

    getProductSortIcon(column: string) {
        if (this.state.sortColumn === column) {
            switch (this.state.sortDirection) {
                case "Up": return ico_SortUp;
                case "Down": return ico_SortDown;
            }
        }
        return ico_Sort;
    }

    get selectedProduct(): Product | undefined {
        if (this.state.products != null && this.state.selectedPIndex != null)
            return this.state.products[this.state.selectedPIndex];
        return undefined;
    }

    componentDidUpdate() {
        requestAnimationFrame(() => {
            let e = document.getElementById("unitTypeEdit");
            if (!e)
                e = document.getElementById("familyEdit");
            if (!e)
                e = document.getElementById("unitEdit");
            if (e) {
                if ((e as HTMLInputElement).value === "") {
                    e.scrollIntoView();
                    e.focus();
                }
            }
        });
    }

    doAddProduct() {
        if (!this.state.fetchingProducts && !this.state.fetchingCommand) {
            let p = new Product();
            p.defaultPriceId = 15;
            p.unitType = "License";
            p.defaultUnitCode = "1-Month";
            this.setState({ selectedPIndex: undefined, editProduct: p, productIsNew: true });
        }
    }

    doRetireProduct() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let p = this.selectedProduct;
                if (p)
                    await Api.retireProduct(p.productNumber);
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchProducts();
                });
            });
        }
    }

    doReactivateProduct() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let p = this.selectedProduct;
                if (p)
                    await Api.reactivateProduct(p.productNumber);
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchProducts();
                });
            });
        }
    }

    doDeleteProduct() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let p = this.selectedProduct;
                if (p)
                    await Api.deleteProduct(p.productNumber);
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchProducts();
                });
            });
        }
    }

    doSynchBackOffice() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                await Api.syncProducts();
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchProducts();
                });
            });
        }
    }

    doFetchUnitTypes(cb?: () => void) {
        if (!this.state.fetchingUnitTypes) {
            this.setState({ fetchingUnitTypes: true, selectedUTIndex: undefined, editUnitTypeMode: false, unitTypeEditError: undefined, selectedUIndex: undefined }, async () => {
                let unitTypes = await Api.getUnitTypes();
                this.setState({ fetchingUnitTypes: false, unitTypes: unitTypes });
                this.doSortUnitTypes();
                if (cb != null)
                    cb();
            });
        }
    }

    selectUnitType(index: number, doEdit: boolean | undefined = undefined) {
        if (!this.state.fetchingUnitTypes) {
            if (doEdit != null && doEdit != this.state.editUnitMode)
                setTimeout(() => {
                    let ut = this.state.unitTypes[index];
                    this.setState({ selectedUTIndex: index, editUnitTypeMode: doEdit, unitTypeEditError: undefined, editUnitType: ut.unitType, editBaseUnit: ut.baseUnitCode, selectedUIndex: undefined })
                }, 1);
            else this.setState({ selectedUTIndex: index, editUnitTypeMode: this.state.editUnitTypeMode && this.state.selectedUTIndex === index, selectedUIndex: undefined });
        }
    }

    doSortUnitTypes(cb?: () => void) {
        let arr = [...this.state.unitTypes];
        arr.sort((a, b) => {
            let result = 0;
            switch (this.state.uSortColumn) {
                case "UnitType": result = (a.unitType ?? "").localeCompare(b.unitType ?? ""); break;
                case "BaseUnit": result = (a.baseUnitCode ?? "").localeCompare(b.baseUnitCode ?? ""); break;
            }
            if (this.state.uSortDirection === "Down")
                result = result * -1;
            return result;
        });
        this.setState({ unitTypes: arr, selectedUTIndex: undefined }, () => {
            if (cb)
                cb();
        });
    }

    toggleUnitTypeSort(column: string) {
        if (this.state.uSortColumn === column) {
            this.setState({ uSortDirection: this.state.uSortDirection === "Up" ? "Down" : "Up" }, () => this.doSortUnitTypes());
        }
        else this.setState({ uSortColumn: column, uSortDirection: "Down" }, () => this.doSortUnitTypes());
    }

    getUnitTypeSortIcon(column: string) {
        if (this.state.uSortColumn === column) {
            switch (this.state.uSortDirection) {
                case "Up": return ico_SortUp;
                case "Down": return ico_SortDown;
            }
        }
        return ico_Sort;
    }

    get selectedUnitType(): ProductUnitType | undefined {
        if (this.state.unitTypes != null && this.state.selectedUTIndex != null)
            return this.state.unitTypes[this.state.selectedUTIndex];
        return undefined;
    }


    doAddUnitType() {
        if (!this.state.fetchingCommand && !this.state.fetchingUnitTypes) {
            this.setState({ fetchingCommand: true }, async () => {
                let unitTypes = [...this.state.unitTypes];
                unitTypes.push(new ProductUnitType({
                    unitType: "",
                    baseUnitCode: "",
                    units: []
                }));
                this.setState({ unitTypes: unitTypes }, () => {
                    this.doSortUnitTypes(() => {
                        let sortedIndex = this.state.unitTypes.findIndex((ut) => ut.unitType == "");
                        this.selectUnitType(sortedIndex, true);
                    });
                });
                this.setState({ fetchingCommand: false });
            });
        }
    }

    doDeleteUnitType() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let ut = this.selectedUnitType
                if (ut)
                    await Api.deleteUnitType(ut.unitType);
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchUnitTypes();
                });
            });
        }
    }

    swapUnitTypeInState(index: number, updatedUT?: ProductUnitType) {
        if (index === this.state.selectedUTIndex && updatedUT) {
            let uts = [...this.state.unitTypes];
            uts[index] = updatedUT;
            this.setState({ unitTypes: uts });
        }
    }

    saveUnitType() {
        if (!this.state.fetchingCommand) {
            let error = "";
            if ((this.state.editUnitType ?? "") === "")
                error += "unit type"
            if ((this.state.editBaseUnit ?? "") === "") {
                if (error != "")
                    error += " and ";
                error += "base unit"
            }
            if (error.length > 0) {
                this.setState({ unitTypeEditError: `Please enter a ${error} for the unit type.` });
            }
            else {
                this.setState({ fetchingCommand: true }, async () => {
                    let index = this.state.selectedUTIndex;
                    if (index != null) {
                        let ut = (Object.assign({}, this.state.unitTypes[index]) as any) as IProductUnitType;
                        ut.unitType = this.state.editUnitType ?? "";
                        ut.baseUnitCode = this.state.editBaseUnit ?? "";

                        this.setState({ unitTypeEditError: undefined });
                        let updatedUT = await Api.updateUnitType(ut);
                        this.swapUnitTypeInState(index, updatedUT);
                        this.selectUnitType(index, false);
                    }
                    this.setState({ fetchingCommand: false });
                });
            }
        }
    }

    doFetchFamilies(cb?: () => void) {
        if (!this.state.fetchingFamilies) {
            this.setState({ fetchingFamilies: true, selectedFIndex: undefined }, async () => {
                let families = await Api.getProductFamilies();
                this.setState({ fetchingFamilies: false, families: families });
                this.doSortFamilies();
                if (cb != null)
                    cb();
            });
        }
    }

    selectFamily(index: number, doEdit: boolean | undefined = undefined) {
        if (!this.state.fetchingFamilies) {
            if (doEdit != null)
                setTimeout(() => {
                    let f = this.state.families[index];
                    this.setState({ selectedFIndex: index, editFamilyMode: doEdit, familyEditError: undefined, editFamilyCode: f.familyCode, editFamilyName: f.name })
                }, 1);
            else this.setState({ selectedFIndex: index, editFamilyMode: this.state.editFamilyMode && this.state.selectedFIndex === index });
        }
    }

    doSortFamilies(cb?: () => void) {
        let arr = [...this.state.families];
        arr.sort((a, b) => {
            let result = 0;
            switch (this.state.fSortColumn) {
                case "Family": result = (a.familyCode ?? "").localeCompare(b.familyCode ?? ""); break;
                case "Name": result = (a.name ?? "").localeCompare(b.name ?? ""); break;
            }
            if (this.state.fSortDirection === "Down")
                result = result * -1;
            return result;
        });
        this.setState({ families: arr, selectedFIndex: undefined }, () => {
            if (cb)
                cb();
        });
    }

    toggleFamilySort(column: string) {
        if (this.state.fSortColumn === column) {
            this.setState({ fSortDirection: this.state.fSortDirection === "Up" ? "Down" : "Up" }, () => this.doSortFamilies());
        }
        else this.setState({ fSortColumn: column, fSortDirection: "Down" }, () => this.doSortFamilies());
    }

    getFamilySortIcon(column: string) {
        if (this.state.fSortColumn === column) {
            switch (this.state.sortDirection) {
                case "Up": return ico_SortUp;
                case "Down": return ico_SortDown;
            }
        }
        return ico_Sort;
    }

    get selectedFamily(): ProductFamily | undefined {
        if (this.state.families != null && this.state.selectedFIndex != null)
            return this.state.families[this.state.selectedFIndex];
        return undefined;
    }

    doDeleteFamily() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let f = this.selectedFamily
                if (f)
                    await Api.deleteProductFamily(f.familyCode);
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchFamilies();
                });
            });
        }
    }

    swapFamilyInState(index: number, updatedFamily?: ProductFamily) {
        if (index === this.state.selectedFIndex && updatedFamily) {
            let fams = [...this.state.families];
            fams[index] = updatedFamily;
            this.setState({ families: fams });
        }
    }

    saveFamily() {
        if (!this.state.fetchingCommand) {
            let error = "";
            if ((this.state.editFamilyCode ?? "") === "")
                error += "family code"
            if ((this.state.editFamilyName ?? "") === "") {
                if (error != "")
                    error += " and ";
                error += "name"
            }
            if (error.length > 0) {
                this.setState({ unitTypeEditError: `Please enter a ${error} for the product family.` });
            }
            else {
                this.setState({ fetchingCommand: true }, async () => {
                    let index = this.state.selectedFIndex;
                    if (index != null) {
                        let pf = (Object.assign({}, this.state.unitTypes[index]) as any) as IProductFamily;
                        pf.familyCode = this.state.editFamilyCode ?? "";
                        pf.name = this.state.editFamilyName ?? "";

                        this.setState({ familyEditError: undefined });
                        let updatedFamily = await Api.updateProductFamily(pf);
                        this.swapFamilyInState(index, updatedFamily);
                        this.selectFamily(index, false);
                    }
                    this.setState({ fetchingCommand: false });
                });
            }
        }
    }

    doAddUnit() {
        if (!this.state.fetchingCommand && this.selectedUnitType != null)
            this.selectUnit(this.selectedUnitType.units.length, true);
    }

    doDeleteUnit() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let ut = this.selectedUnitType;
                let index = this.state.selectedUIndex;
                if (ut != null && ut.units != null && index != null && index >= 0 && index < ut.units.length) {
                    let index = this.state.selectedUIndex;
                    if (index != null) {
                        let changedUT = (Object.assign({}, ut) as any) as IProductUnitType;
                        changedUT.units.splice(index, 1)
                        let updatedUT = await Api.updateUnitType(changedUT);
                        this.swapUnitTypeInState(index, updatedUT);
                        if (this.state.selectedUTIndex != null)
                            this.selectUnitType(this.state.selectedUTIndex, false);
                    }
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    get selectedUnit(): ProductUnit | undefined {
        let ut = this.selectedUnitType;
        if (ut) {
            if (this.state.selectedUIndex != null)
                return ut.units[this.state.selectedUIndex];
        }
        return undefined;
    }

    doRefreshUnitType() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let index = this.state.selectedUTIndex;
                let ut = this.selectedUnitType;
                if (ut) {
                    let updatedUT = await Api.getUnitType(ut.unitType);
                    if (updatedUT && index != undefined && index === this.state.selectedUTIndex)
                        this.swapUnitTypeInState(index, updatedUT);
                    this.setState({ editUnitTypeMode: false, unitTypeEditError: undefined, selectedUIndex: undefined, unitEditError: undefined, editUnitMode: false })
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    get editUnitsArray() {
        let ut = this.selectedUnitType;
        if (ut) {
            if (this.state.editUnitMode)
                return (ut.units ?? []).concat([new ProductUnit()]);
            return ut.units ?? [];
        }
        return [];
    }

    selectUnit(index: number, doEdit: boolean | undefined = undefined) {
        if (!this.state.fetchingUnitTypes) {
            if (doEdit != null && doEdit != this.state.editUnitTypeMode)
                setTimeout(() => {
                    if (this.selectedUnitType) {
                        let u = index < this.selectedUnitType.units.length - 1 ? this.selectedUnitType.units[index] : undefined;
                        this.setState({
                            selectedUIndex: index,
                            editUnitMode: doEdit,
                            unitEditError: undefined,
                            editUnitCode: u?.unitCode.toString() ?? "",
                            editQuantity: u?.quantity?.toString() ?? "",
                        });
                    }
                    else this.setState({ selectedUIndex: undefined, editUnitMode: false });
                }, 1);
            else this.setState({ selectedUIndex: index, editUnitMode: this.state.editUnitMode && this.state.selectedUIndex === index });
        }
    }

    saveUnit() {
        let unitCode = this.state.editUnitCode ?? "";
        let quantity = parseInt(this.state.editQuantity ?? "");

        if (unitCode.length === 0) {
            this.setState({ unitEditError: "Please enter a Unit Code for the Unit." });
            return
        }

        if (isNaN(quantity) || quantity < 1) {
            this.setState({ unitEditError: "Please enter a quantity value for the Unit." });
            return
        }

        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let index = this.state.selectedUTIndex;
                let uIndex = this.state.selectedUIndex;
                if (index != null && uIndex != null) {
                    let ut = (Object.assign({}, this.state.unitTypes[index]) as any) as IProductUnitType;
                    let unit = uIndex > ut.units.length - 1
                        ? (Object.assign({}, ut.units[uIndex]) as any) as IProductUnit
                        : new ProductUnit();
                    unit.unitCode = unitCode;
                    unit.quantity = quantity.toString();
                    if (uIndex < ut.units.length - 1)
                        ut.units[uIndex] = unit;
                    else ut.units.push(unit);

                    this.setState({ unitTypeEditError: undefined, unitEditError: undefined });
                    let updatedUT = await Api.updateUnitType(ut);
                    this.swapUnitTypeInState(index, updatedUT);
                    this.selectUnitType(index, false);
                    this.selectUnit(uIndex, false);
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    doAddFamily() {
        if (!this.state.fetchingCommand && !this.state.fetchingFamilies) {
            this.setState({ fetchingCommand: true }, async () => {
                let families = [...this.state.families];
                families.push(new ProductFamily({
                    familyCode: "",
                    name: ""
                }));
                this.setState({ families: families }, () => {
                    this.doSortFamilies(() => {
                        let sortedIndex = this.state.families.findIndex((f) => f.familyCode == "");
                        this.selectFamily(sortedIndex, true);
                    });
                });
                this.setState({ fetchingCommand: false });
            });
        }
    }

    onProductDlgSave(updatedP: Product) {
        let index = this.state.products.findIndex((p) => p.productNumber === updatedP.productNumber);
        if (this.state.selectedPIndex === index) {
            let arr = [...this.state.products];
            arr[this.state.selectedPIndex] = updatedP;
            this.setState({ products: arr, editProduct: undefined });
        }
        else {
            this.setState({ selectedPIndex: undefined, editProduct: undefined }, () => {
                this.doFetchProducts(() => {
                    let index = this.state.products.findIndex((p) => p.productNumber === updatedP.productNumber);
                    if (index != null) {
                        this.selectProduct(index, false);
                        let elm = document.getElementById(`product-${updatedP.productNumber}`);
                        if (elm != null)
                            elm.scrollIntoView();
                    }
                });
            });
        }
    }

    render() {
        return <div className="Products">
            <header>
                <div>
                    <div>
                        <button className="RibbonButton" onClick={() => { this.props.history.push("/") }}><img src={ico_Home} />HOME</button>
                        <button className="RibbonButton" onClick={() => this.doAddProduct()}><img src={ico_Plus} />ADD PRODUCT</button>
                        <button className="RibbonButton" onClick={() => this.doReactivateProduct()} disabled={this.state.fetchingProducts || this.state.selectedPIndex == null || this.state.products[this.state.selectedPIndex].status === ProductStatus.Active}><img src={ico_Sunrise} />REACTIVATE PRODUCT</button>
                        <button className="RibbonButton" onClick={() => this.doRetireProduct()} disabled={this.state.fetchingProducts || this.state.selectedPIndex == null || this.state.products[this.state.selectedPIndex].status === ProductStatus.Retired}><img src={ico_Sunset} />RETIRE PRODUCT</button>
                        <button className="RibbonButton" onClick={() => this.doDeleteProduct()} disabled={this.state.fetchingProducts || this.state.selectedPIndex == null}><img src={ico_Trash} />DELETE PRODUCT</button>
                        <button className="RibbonButton" onClick={() => this.doSynchBackOffice()}><img src={ico_Refresh} />SYNC BACK-OFFICE</button>
                    </div>
                </div>
                <div>
                    <ConnectionInfo />
                </div>
            </header>
            <div className={this.state.fetchingProducts || this.state.fetchingUnitTypes || this.state.fetchingFamilies || this.state.fetchingCommand ? "Spinner" : ""}></div>
            <div>
                <div className="PageTitle">
                    <img src={ico_Box} />
                    <span>Products</span>
                </div>
                <div className="Search">
                    <input placeholder="Search for records" value={this.state.search ?? ""} onChange={(e) => this.setState({ search: e.target.value })} onBlur={() => { if (this._lastSearch !== this.state.search) this.doFetchProducts() }} onKeyPress={(e) => { if (e.key == "Enter") this.doFetchProducts() }} />
                    {!this.state.search && <img src={ico_Magnifier} onMouseDown={() => this.doFetchProducts()} />}
                    {this.state.search && <img id="imgCancelProductSearch" src={ico_Close} onMouseDown={() => this.clearSearch()} />}
                </div>
            </div>
            <div className="Columns">
                <div className="ProductList">
                    <div className="ProductHeader ProductItem">
                        <span>Product Number<img src={this.getProductSortIcon("ProductNumber")} onClick={() => this.toggleProductSort("ProductNumber")} /></span>
                        <span>Title<img src={this.getProductSortIcon("Title")} onClick={() => this.toggleProductSort("Title")} /></span>
                        <span>Culture<img src={this.getProductSortIcon("Culture")} onClick={() => this.toggleProductSort("Culture")} /></span>
                        <span>Product Type<img src={this.getProductSortIcon("ProductType")} onClick={() => this.toggleProductSort("ProductType")} /></span>
                        <span>Unit Type<img src={this.getProductSortIcon("UnitType")} onClick={() => this.toggleProductSort("UnitType")} /></span>
                        <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchProducts()} /></button></div>
                    </div>
                    <div className="ProductScroller">
                        {this.state.products.map((p, index) => {
                            return <div className={`ProductItem ${this.state.selectedPIndex === index ? "Selected" : ""} ${this.state.products[index].status === ProductStatus.Retired ? " Retired" : ""}`} key={index} onClick={() => this.selectProduct(index)}>
                                <span>
                                    {p.status === ProductStatus.Active && <a id={`product-${p.productNumber}`} onClick={() => this.selectProduct(index, true)}>{p.productNumber}</a>}
                                    {p.status === ProductStatus.Retired && p.productNumber}
                                </span>
                                <span>{p.title}</span>
                                <span>{p.culture}</span>
                                <span>{ProductType[p.productType]}</span>
                                <span>{p.unitType}</span>
                            </div>
                        })}
                    </div>
                    <div className="ProductFooter">
                        <span>{`${this.state.products.length} record${this.state.products.length === 1 ? "" : "s"}`}</span>
                    </div>
                </div>
                <div className="RightColumn">
                    <div>
                        <h1>UNIT TYPES</h1>
                        <span>
                            <button onClick={() => this.doAddUnitType()} className="ChromelessButton"><img src={ico_Plus} /></button>
                            <button disabled={this.selectedUnitType == null} onClick={() => this.doDeleteUnitType()} className="ChromelessButton"><img src={ico_Trash} /></button>
                        </span>
                    </div>
                    <div>
                        {this.state.unitTypeEditError && <div className="Error">{this.state.unitTypeEditError}</div>}
                        <div className="UnitTypesHeader UnitTypeItem">
                            <div>
                                <span>Unit Type<img src={this.getUnitTypeSortIcon("UnitType")} onClick={() => this.toggleUnitTypeSort("UnitType")} /></span>
                                <span>Base Unit<img src={this.getUnitTypeSortIcon("BaseUnit")} onClick={() => this.toggleUnitTypeSort("BaseUnit")} /></span>
                                <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchUnitTypes()} /></button></div>
                            </div>
                        </div>
                        <div className={`UnitTypesScroller${this.state.unitTypeEditError ? " HasError" : ""}`}>
                            {this.state.unitTypes.map((ut, index) => {
                                return <div className={`UnitTypeItem ${this.state.selectedUTIndex === index ? ("Selected" + (this.state.editUnitTypeMode ? " Editing" : "")) : ""}`} key={index} onClick={() => this.selectUnitType(index)}>
                                    <div>
                                        <span>
                                            {(!this.state.editUnitTypeMode || this.state.selectedUTIndex !== index || (this.selectedUnitType?.unitType ?? "").length > 0) && <a onClick={() => this.selectUnitType(index, true)}>{ut.unitType}</a>}
                                            {this.state.editUnitTypeMode && this.state.selectedUTIndex === index && (this.selectedUnitType?.unitType ?? "").length === 0 && <input id="unitTypeEdit" value={this.state.editUnitType} onChange={(e) => this.setState({ editUnitType: e.target.value })} />}
                                        </span>
                                        <span>
                                            {(!this.state.editUnitTypeMode || index !== this.state.selectedUTIndex) && ut.baseUnitCode}
                                            {this.state.editUnitTypeMode && this.state.selectedUTIndex === index && <input value={this.state.editBaseUnit} onChange={(e) => this.setState({ editBaseUnit: e.target.value })} />}
                                        </span>
                                        {this.state.editUnitTypeMode && index === this.state.selectedUTIndex && <span><img src={ico_Save} onMouseDown={() => { this.saveUnitType() }} /><img src={ico_Close} onMouseDown={() => this.doFetchUnitTypes()} /></span>}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <span>{`${this.state.unitTypes.length} record${this.state.unitTypes.length === 1 ? "" : "s"}`}</span>
                    <div>
                        <h1>{`${this.selectedUnitType != null ? (this.selectedUnitType.unitType + " ") : ""}UNITS`}</h1>
                        <span>
                            <button disabled={this.selectedUnitType == null} onClick={() => this.doAddUnit()} className="ChromelessButton"><img src={ico_Plus} /></button>
                            <button disabled={this.selectedUnit == null} onClick={() => this.doDeleteUnit()} className="ChromelessButton"><img src={ico_Trash} /></button>
                        </span>
                    </div>
                    <div>
                        {this.state.unitEditError && <div className="Error">{this.state.unitEditError}</div>}
                        <div className="UnitsHeader UnitItem">
                            <div>
                                <span>Unit</span>
                                <span>Quanity</span>
                                <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doRefreshUnitType()} /></button></div>
                            </div>
                        </div>
                        <div className={`UnitsScroller${this.state.unitEditError ? " HasError" : ""}`}>
                            {this.editUnitsArray.map((u, index) => {
                                return <div className={`UnitItem ${this.state.selectedUIndex === index ? ("Selected" + (this.state.editUnitMode ? " Editing" : "")) : ""}`} key={index} onClick={() => this.selectUnit(index)}>
                                    <div>
                                        <span>
                                            {(!this.state.editUnitMode || this.state.selectedUIndex !== index) && <a onClick={() => this.selectUnit(index, true)}>{u.unitCode}</a>}
                                            {this.state.editUnitMode && this.state.selectedUIndex === index && <>
                                                {this.selectedUnit?.unitCode == null && <input id="unitEdit" value={this.state.editUnitCode} onChange={(e) => this.setState({ editUnitCode: e.target.value })} />}
                                                {this.selectedUnit?.unitCode != null && <span>{u.unitCode}</span>}
                                            </>}
                                        </span>
                                        <span>
                                            {(!this.state.editUnitMode || index !== this.state.selectedUIndex) && u.quantity}
                                            {this.state.editUnitMode && index === this.state.selectedUIndex && <input value={this.state.editQuantity} onChange={(e) => { this.setState({ editQuantity: e.target.value }) }} />}
                                        </span>
                                        {this.state.editUnitMode && index === this.state.selectedUIndex && <span><img src={ico_Save} onMouseDown={() => { this.saveUnit() }} /><img src={ico_Close} onMouseDown={() => this.doRefreshUnitType()} /></span>}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <span>{`${this.selectedUnitType ? this.selectedUnitType.units.length : 0} record${this.selectedUnitType?.units.length === 1 ? "" : "s"}`}</span>
                    <div>
                        <h1>PRODUCT FAMILIES</h1>
                        <span>
                            <button onClick={() => this.doAddFamily()} className="ChromelessButton"><img src={ico_Plus} /></button>
                            <button disabled={this.selectedFamily == null} onClick={() => this.doDeleteFamily() } className="ChromelessButton"><img src={ico_Trash} / ></button>
                        </span>
                    </div>
                    <div>
                        {this.state.familyEditError && <div className="Error">{this.state.familyEditError}</div>}
                        <div className="FamiliesHeader FamilyItem">
                            <div>
                                <span>Family<img src={this.getFamilySortIcon("Family")} onClick={() => this.toggleFamilySort("Family")} /></span>
                                <span>Name<img src={this.getFamilySortIcon("Name")} onClick={() => this.toggleFamilySort("Name")} /></span>
                                <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchFamilies()} /></button></div>
                            </div>
                        </div>
                        <div className={`FamiliesScroller${this.state.familyEditError ? " HasError" : ""}`}>
                            {this.state.families.map((f, index) => {
                                return <div className={`FamilyItem ${this.state.selectedFIndex === index ? ("Selected" + (this.state.editFamilyMode ? " Editing" : "")) : ""}`} key={index} onClick={() => this.selectFamily(index)}>
                                    <div>
                                        <span>
                                            {(!this.state.editFamilyMode || this.state.selectedFIndex !== index || (this.selectedFamily?.familyCode ?? "").length > 0) && <a onClick={() => this.selectFamily(index, true)}>{f.familyCode}</a>}
                                            {this.state.editFamilyMode && this.state.selectedFIndex === index && (this.selectedFamily?.familyCode ?? "").length === 0 && <input id="familyEdit" value={this.state.editFamilyCode} onChange={(e) => this.setState({ editFamilyCode: e.target.value })} />}
                                        </span>
                                        <span>
                                            {(!this.state.editFamilyMode || index !== this.state.selectedFIndex) && f.name}
                                            {this.state.editFamilyMode && this.state.selectedFIndex === index && <input value={this.state.editFamilyName} onChange={(e) => this.setState({ editFamilyName: e.target.value })} />}
                                        </span>
                                        {this.state.editFamilyMode && index === this.state.selectedFIndex && <span><img src={ico_Save} onMouseDown={() => { this.saveFamily() }} /><img src={ico_Close} onMouseDown={() => this.doFetchFamilies()} /></span>}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <span>{`${this.state.families.length} record${this.state.families.length === 1 ? "" : "s"}`}</span>
                </div>
            </div>
            {this.state.editProduct && <EditProductDlg isNew={this.state.productIsNew ?? false} product={this.state.editProduct} onSave={(updatedP) => this.onProductDlgSave(updatedP)} onCancel={() => this.setState({ editProduct: undefined })} />}
        </div>;
    }
}

export default withRouter(Products);