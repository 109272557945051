import * as React from 'react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import moment from 'moment';
import ConnectionInfo from './ConnectionInfo';
import { Api, ICCMeta, IDocument, IThreadProgress } from './Api';
import Dialog from './Dialog';
import DatePicker from 'react-datepicker';
import DeactivateDlg from './DeactivateDlg';

import './BillingActivations.scss';
import ico_Home from './images/ico_home.png';
import ico_Refresh from './images/ico_refresh.png';
import ico_Calculator from './images/ico_calculator.png';
import ico_Register from './images/ico_register.png';
import ico_Download from './images/ico_download.png';
import { FormEvent, RefObject } from 'react';
import { LicenseActivationBill } from '@testout/testout-commerce/models/commerce/LicenseActivationBill';

interface IBillingActivationsProps extends RouteComponentProps {

}

interface IBillingActivationsState {
    fetchingList: boolean;
    fetchingCommand: boolean;
    selectedIndex?: number;
    endDate: Date;
    bills: LicenseActivationBill[];
    downloadedBill?: IDocument;

    showSubmitDialog: boolean;
    submitThread?: IThreadProgress;

    showDeactivateDlg: boolean;
}

class BillingActivations extends React.PureComponent<IBillingActivationsProps, IBillingActivationsState> {
    constructor(props: IBillingActivationsProps) {
        super(props);
        this.state = {
            fetchingList: false, fetchingCommand: false, bills: [], endDate: new Date(), showSubmitDialog: false,
            showDeactivateDlg: false
        };
    }

    componentDidMount() {
        this.doFetchList();
    }

    doFetchList() {
        if (!this.state.fetchingList) {
            this.setState({ fetchingList: true, selectedIndex: undefined }, async () => {
                let bills = await Api.getLicenseActivationBills(this.state.endDate);
                this.setState({ fetchingList: false, bills: bills });
            });
        }
    }

    selectItem(index: number) {
        if (!this.state.fetchingList) {
            this.setState({ selectedIndex: index });
        }
    }

    doDownloadBillFile() {
        if (!this.state.fetchingCommand && this.state.selectedIndex != null) {
            this.setState({ fetchingCommand: true }, async () => {
                let index = this.state.selectedIndex;
                if (index != null) {
                    let d = await Api.getLicenseBillDownload(this.state.bills[index].customerNumber, this.state.endDate)
                    if (d)
                        this.setState({downloadedBill: d});
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    sizeToString(size: number) {
        if (size >= 1024 * 1024)
            return (size / (1024.0 * 1024.0)).toFixed(1) + " MB";
        if (size >= 1024)
            return (size / 1024.0).toFixed(1) + " KB";
        return size.toFixed(1) + " Bytes";
    }

    showCreateSalesOrder() {
        if (!this.state.fetchingCommand && this.state.selectedIndex != null) {
            this.setState({ showSubmitDialog: true });
        }
    }

    doCreateSalesOrder() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let index = this.state.selectedIndex;
                if (index != null) {
                    let bill = this.state.bills[index ?? -1];
                    let threadId = await Api.submitLicenseActivation(bill.customerNumber, this.state.endDate);
                    if (threadId != null) {
                        this.setState({ submitThread: { id: threadId, progress: 0, status: "Starting Submission..." } as IThreadProgress }, () => {
                            this.doCheckStatus();
                        });
                    }
                }
            })
        }
    }

    async doCheckStatus() {
        if (this.state.submitThread != null) {
            let thread = await Api.getThreadProgress(this.state.submitThread.id);
            this.setState({ submitThread: thread }, () => {
                if (this.isThreadComplete)
                    this.setState({ fetchingCommand: false });
                else setTimeout(() => this.doCheckStatus(), 5000);
            });
        }
    }

    get isThreadComplete(): boolean {
        return this.state.submitThread != null && this.state.submitThread.progress >= 100 && this.state.submitThread.status == "Complete";
    }

    endSubmitDialog() {
        this.setState({ showSubmitDialog: false, submitThread: undefined, fetchingCommand: false }, () => {
            this.doFetchList();
        });
    }

    showDeactivationDialog(index: number) {
        this.selectItem(index);
        this.setState({ showDeactivateDlg: true });
    }

    closeDeactivateDlg() {
        this.setState({ showDeactivateDlg: false }, () => this.doFetchList());
    }

    _timeout: any = undefined;
    scheduleRefresh() {
        if (this._timeout != null)
            clearTimeout(this._timeout);
        this._timeout = setTimeout(() => this.doFetchList(), 1000);
    }

    render() {
        return <div className="BillingActivations">
            <header>
                <div>
                    <div>
                        <button className="RibbonButton" onClick={() => this.props.history.push("/")}><img src={ico_Home} />HOME</button>
                        <button className="RibbonButton" onClick={() => this.doDownloadBillFile()} disabled={this.state.fetchingList || this.state.selectedIndex == null}><img src={ico_Download} />DOWNLOAD BILLING REPORT</button>
                        <button className="RibbonButton" onClick={() => this.showCreateSalesOrder()} disabled={this.state.fetchingList || this.state.selectedIndex == null}><img src={ico_Register} />CREATE SALES ORDER</button>
                    </div>
                </div>
                <div>
                    <ConnectionInfo />
                </div>
            </header>
            <div className={this.state.fetchingList || this.state.fetchingList || this.state.fetchingCommand ? "Spinner" : ""}></div>
            <div>
                <div className="PageTitle">
                    <img src={ico_Calculator} />
                    <span>Billing (License Activations)</span>
                </div>
                <div className="DatePicker">
                    <span>Activations Before</span>
                    <DatePicker selected={this.state.endDate} onChange={(d) => { this.setState({ endDate: d ?? new Date() }); this.scheduleRefresh() }} />
                </div>
            </div>
            <div className="BillingActivationList">
                <div className="BillingActivationHeader BillingActivationItem">
                    <span>Customer</span>
                    <span>Quantity</span>
                    <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchList()} /></button></div>
                </div>
                <div className="BillingActivationScroller">
                    {this.state.bills.map((b, index) => {
                        return <div className={`BillingActivationItem ${this.state.selectedIndex === index ? "Selected" : ""}`} key={index} onClick={() => this.selectItem(index)}>
                            <span className="Customer">
                                <span><a onClick={() => this.showDeactivationDialog(index)}>{b.customerNumber}</a>&nbsp;-&nbsp;
                                    <span>
                                        <span>{b.customerName}</span>
                                        <span>{b.campusName}</span>
                                    </span>
                                </span>
                            </span>
                            <span>{b.activationCount}</span>
                        </div>
                    })}
                </div>
                <div className="BillingActivationFooter">
                    <span>{`${this.state.bills.length}${this.state.bills.length === 100 ? "+" : ""} record${this.state.bills.length === 1 ? "" : "s"}`}</span>
                </div>
            </div>
            {this.state.downloadedBill != null && <Dialog>
                <div className="DownloadFileDlg">
                    <h1>Billing Report</h1>
                    <div>
                        <span>Your billing report is now prepared for download.</span>
                        <span><span>Filename:</span>&nbsp;{this.state.downloadedBill.name}</span>
                        <span><span>Size:</span>&nbsp;{this.sizeToString(this.state.downloadedBill.base64Content.length)}</span>
                    </div>
                    <div><a download={this.state.downloadedBill.name} href={`data:${this.state.downloadedBill.contentType};base64,${this.state.downloadedBill.base64Content}`}>Download</a></div>
                    <div><button onClick={() => { this.setState({ downloadedBill: undefined }) }}>Close</button></div>
                </div>
            </Dialog>}
            {this.state.showSubmitDialog && <Dialog>
                <div className="CreateOrderDlg">
                    <h1>CREATE SALES ORDER</h1>
                    <div><span>A sales order for the customer's license activations will be created.</span></div>
                    {this.state.submitThread != null && <>
                        {!this.isThreadComplete && <div className="Status">
                            <div className="Progress"><div style={{ width: `${this.state.submitThread.progress}%` }}></div></div>
                            <div>{this.state.submitThread.status}</div>
                        </div>}
                        {this.isThreadComplete && <div className="Results">
                            <span>Results</span>
                            <div dangerouslySetInnerHTML={{ __html: this.state.submitThread.resultData }}></div>
                        </div>}
                    </>}
                    <div>
                        <button onClick={() => this.endSubmitDialog()}>{this.isThreadComplete ? "Close" : "Cancel"}</button>
                        {!this.state.fetchingCommand && !this.isThreadComplete && <button onClick={() => this.doCreateSalesOrder()}>Create Order</button>}
                    </div>
                </div>
            </Dialog>}
            {this.state.showDeactivateDlg && this.state.selectedIndex != null && <DeactivateDlg customerNumber={this.state.bills[this.state.selectedIndex].customerNumber} endDate={this.state.endDate} onClose={() => this.closeDeactivateDlg()} />}
        </div>;
    }
}

export default withRouter(BillingActivations);