import * as React from 'react';
import './Home.scss';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import ConnectionInfo from './ConnectionInfo';

import icoGear from './images/ico_gear.png';
import icoCommerceTask from './images/ico_commerceTask.gif';
import icoPriceGroup from './images/ico_priceGroup.gif';
import icoPriceList from './images/ico_priceList.gif';
import icoShoppingCart from './images/ico_shoppingCart.png';
import icoUnitGroup from './images/ico_unitGroup.gif';

interface IHomeProps extends RouteComponentProps  {

}

interface IHomeState {
}

class Home extends React.PureComponent<IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className="Home">
            <header>
                <div className="PageTitle">
                    <img src={icoGear} />
                    <span>Commerce Administration</span>
                </div>
                <ConnectionInfo />
            </header>
            <div className="Columns">
                <div>
                    <div className="PageButton" onClick={() => { this.props.history.push("/PriceGroups") }}><img src={icoPriceGroup} />
                        <div>
                            <span>Price Groups and Price Codes</span>
                            <p>A price group is a set of customers that share a common attribute. The grouping may be general: all potential customers, or existing institutional customers with a specific segment group. The grouping may also be more specific: existing institutional customers explicitly assigned to the price group.</p>
                            <p>A price code is an identifier for a price group and price list combination. The customer uses a price code to identify themselves and their pricing.</p>
                            <p>In this section, you manage price groups and their properties. You can retire and create new versions of price codes. You can also choose which price list products to show and their display order for a price code.</p>
                        </div>
                    </div>
                    <div className="PageButton" onClick={() => { this.props.history.push("/PriceLists") }}><img src={icoPriceList} />
                        <div>
                            <span>Prices and Discounts</span>
                            <p>A price list specifies what prices can be charged for each unit in the unit type of a product.</p>
                            <p>A discount contains the specific discount levels that can be applied to a product, based on volume purchased.</p>
                            <p>In this section, you create, manage, and delete price list line items and price lists in the product catalog. You associate and disassociate products with price lists. You also specify various pricing options in the price list line items, such as the the pricing method, and the rounding options. You can also create, manage, and delete discounts in the product catalog.</p>
                        </div>
                    </div>
                    <div className="PageButton" onClick={() => { this.props.history.push("/Products") }}><img src={icoUnitGroup} />
                        <div>
                            <span>Products and Units</span>
                            <p>A product is an item in the product catalog that you want to sell to your customers.</p>
                            <p>A unit type contains the primary unit a product is available in, such as a liter, and then lists all the different increments that this primar unit is packaged for sale. For example, if the primary unit is a two-liter bottle, then that product could be sold individually as a two-liter bottle or in a case containing 6 two-liter bottles.</p>
                            <p>In this section, you manage products, create product bundles, and manage properties of product records. You can retire product records. You can also create, manage, and delete units and unit types in the product catalog.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="PageButton" onClick={() => { this.props.history.push("/BillingCodes") }}><img src={icoPriceList} />
                        <div>
                            <span>Billing (Activation Codes)</span>
                            <p>A license bill for activation codes shows the product activation codes that have been fulfilled through the shopping cart licensing API for a customer.</p>
                            <p>In this section, you view and choose which license bills to create as sales orders. You can also import a list of customer ID's and associated customer numbers for a billing customer to use with the shopping cart license API.</p>
                        </div>
                    </div>
                    <div className="PageButton" onClick={() => { this.props.history.push("/BillingActivations") }}><img src={icoPriceList} />
                        <div>
                            <span>Billing (License Activations)</span>
                            <p>A license bill for license activations shows the product license activations that have occurred on unlimited licenses associated with a customer campus.</p>
                            <p>In this section, you view and send license bills to customers, deactivate license activations for dropped students, and create sales orders from license bills.</p>
                        </div>
                    </div>
                    <div className="PageButton" onClick={() => { this.props.history.push("/Carts") }}><img src={icoShoppingCart} />
                        <div>
                            <span>Shopping Carts</span>
                            <p>A shopping cart is a grouping of price list items with associated quantities and properties selected by a customer for purchase.</p>
                            <p>In this section, you manage shopping carts and their submission. You can submit a shopping cart as a sales order supplying associated payment information.</p>
                        </div>
                    </div>
                    <div className="PageButton" onClick={() => { this.props.history.push("/Tasks") }}><img src={icoCommerceTask} />
                        <div>
                            <span>Commerce Tasks</span>
                            <p>A commerce task is a work item executed to coordinate commerce activities between commerce applications and back-office systems.</p>
                            <p>In this section, you manage commerce tasks and their execution. You can edit and retry failed tasks or delete completed tasks.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(Home);