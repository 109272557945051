import * as React from 'react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ConnectionInfo from './ConnectionInfo';
import { Product, ProductStatus, ProductType } from "@testout/testout-commerce/models/commerce/Product";
import { Api, ICCMeta } from './Api';
import Dialog from './Dialog';

import './EditProductDlg.scss';
import ico_Refresh from './images/ico_refresh.png';
import ico_Magnifier from './images/ico_magnifier.png';
import ico_Close from './images/ico_close.png';
import ico_Sort from './images/ico_sort.png';
import ico_SortUp from './images/ico_sortup.png';
import ico_SortDown from './images/ico_sortdown.png';
import ico_Trash from './images/ico_trash.png';
import ico_Calc from './images/ico_calculator.png';
import ico_Plus from './images/ico_plus.png';
import ico_Save from './images/ico_save.png';
import { ComponentType, ProductComponent } from '@testout/testout-commerce/models/commerce/ProductComponent';
import { ProductUnitType } from '@testout/testout-commerce/models/commerce/ProductUnitType';
import { ProductUnit } from '@testout/testout-commerce/models/commerce/ProductUnit';
import { PriceList } from '@testout/testout-commerce/models/commerce/PriceList';
import { ProductFamily } from '@testout/testout-commerce/models/commerce/ProductFamily';
import { StringDictionary } from '@testout/testout-commerce/core/StringDictionary';
import { ProductProperty } from '@testout/testout-commerce/models/commerce/ProductProperty';

interface IEditProductDlgProps extends RouteComponentProps {
    isNew: boolean;
    product: Product;
    onSave: (product: Product) => void;
    onCancel: () => void;
}

interface IEditProductDlgState {
    fetchingDependentObjects: boolean;
    fetchingCommand: boolean;
    sortColumn: string;
    sortDirection: string;
    editMode: boolean;

    saveError?: string;
    productNumber: string;
    title: string;
    culture: string;
    productType: ProductType;
    unitType: string;
    defaultUnitCode: string;
    defaultPriceId: number;
    listPrice: string;
    currencyCode: string;
    thumbnailUrl: string;
    informationUrl: string;
    fulfillmentProvider: string;
    familyCode: string;
    isbn: string;
    weight: string;
    courseId: number;
    meritItemId: string;

    unitTypes: ProductUnitType[];
    priceLists: PriceList[];
    productFamilies: ProductFamily[];

    components: ProductComponent[];
    selectedIndex?: number;

    editError?: string;
    editProductNumber?: string;
    editUnitCode?: string;
    editQuantity?: string;
    editComponentType?: ComponentType;
    componentIsNew?: boolean;

    showPriceListSearch: boolean;
    priceListSearch?: string;
    filteredPriceLists: PriceList[];

    showCourseLookup: boolean;
    courseDict: StringDictionary;
    showMeritLookup: boolean;
    meritDict: StringDictionary;

    showProductSearch: boolean;
    productSearch?: string;
    products?: Product[];

    properties: ProductProperty[];
    selectedPropIndex?: number;
    editPropMode: boolean;
    editPropError?: string;
    editPropKey?: string;
    editPropOptions?: string;
    propIsNew?: boolean;
}

class Products extends React.PureComponent<IEditProductDlgProps, IEditProductDlgState> {
    constructor(props: IEditProductDlgProps) {
        super(props);
        this.state = this.applyProps(props);
    }

    componentWillReceiveProps(props: IEditProductDlgProps) {
        this.setState(this.applyProps(props));
    }

    applyProps(props: IEditProductDlgProps) {
        return {
            productNumber: props.product.productNumber,
            title: props.product.title,
            culture: props.product.culture,
            productType: props.product.productType,
            unitType: props.product.unitType,
            defaultUnitCode: props.product.defaultUnitCode,
            defaultPriceId: props.product.defaultPriceId,
            listPrice: props.product.listPrice != null && props.product.listPrice > 0 ? props.product.listPrice.toFixed(2) : "",
            currencyCode: props.product.currencyCode,
            thumbnailUrl: props.product.thumbnailUrl,
            informationUrl: props.product.informationUrl,
            fulfillmentProvider: props.product.fulfillmentProvider,
            familyCode: props.product.familyCode,
            isbn: props.product.isbn,
            weight: props.product.weight != null && props.product.weight > 0 ? props.product.weight.toString() : "",
            courseId: props.product.courseId,
            meritItemId: props.product.meritItemId,
            components: [...props.product.components],
            fetchingDependentObjects: false, fetchingCommand: false, sortColumn: "Product", sortDirection: "Up", editMode: false,
            unitTypes: [],
            priceLists: [],
            productFamilies: [],
            showPriceListSearch: false,
            filteredPriceLists: [],
            showCourseLookup: false,
            courseDict: new StringDictionary(),
            showMeritLookup: false,
            meritDict: new StringDictionary(),
            showProductSearch: false,
            products: [],
            editPropMode: false,
            properties: [...props.product.properties]
        } as IEditProductDlgState;
    }

    selectComponent(index: number, doEdit: boolean | undefined = undefined, componentIsNew: boolean = false) {
        if (!this.state.fetchingCommand) {
            if (doEdit != null)
                setTimeout(() => {
                    let c = this.state.components[index];
                    this.setState({
                        selectedIndex: index, editMode: doEdit, editError: undefined, componentIsNew: componentIsNew,
                        editProductNumber: c.productNumber, editUnitCode: c.unitCode,
                        editQuantity: c.quantity.toString(), editComponentType: c.componentType
                    });
                }, 1);
            else this.setState({ selectedIndex: index, editMode: this.state.editMode && this.state.selectedIndex === index });
        }
    }

    selectProperty(index: number, doEdit: boolean | undefined = undefined, propIsNew: boolean = false) {
        if (!this.state.fetchingCommand) {
            if (doEdit != null)
                setTimeout(() => {
                    let p = this.state.properties[index];
                    this.setState({
                        selectedPropIndex: index, editPropMode: doEdit, editPropError: undefined, propIsNew: propIsNew,
                        editPropKey: p.key, editPropOptions: (p.options ?? []).join('|')
                    })
                }, 1);
            else this.setState({ selectedPropIndex: index, editMode: this.state.editPropMode && this.state.selectedPropIndex === index });
        }
    }

    doSort(cb?: () => void) {
        let arr = [...this.state.components];
        arr.sort((a, b) => {
            let result = 0;
            switch (this.state.sortColumn) {
                case "Product": result = a.productNumber.localeCompare(b.productNumber); break;
                case "Unit": result = a.unitCode.localeCompare(b.unitCode); break;
                case "Quantity": result = a.quantity - b.quantity; break;
                case "MemberType": result = a.componentType - b.componentType; break;
            }
            if (this.state.sortDirection === "Down")
                result = result * -1;
            return result;
        });
        this.setState({ components: arr, selectedIndex: undefined }, () => {
            if (cb)
                cb();
        });
    }

    toggleSort(column: string) {
        if (this.state.sortColumn === column) {
            this.setState({ sortDirection: this.state.sortDirection === "Up" ? "Down" : "Up" }, () => this.doSort());
        }
        else this.setState({ sortColumn: column, sortDirection: "Down" }, () => this.doSort());
    }

    getSortIcon(column: string) {
        if (this.state.sortColumn === column) {
            switch (this.state.sortDirection) {
                case "Up": return ico_SortUp;
                case "Down": return ico_SortDown;
            }
        }
        return ico_Sort;
    }

    get selectedComponent(): ProductComponent | undefined {
        if (this.state.components != null && this.state.selectedIndex != null)
            return this.state.components[this.state.selectedIndex];
        return undefined;
    }

    get selectedProperty(): ProductProperty | undefined {
        if (this.state.properties != null && this.state.selectedPropIndex != null)
            return this.state.properties[this.state.selectedPropIndex];
        return undefined;
    }

    componentDidMount() {
        this.doFetchDependantObjects();
    }

    componentDidUpdate() {
        requestAnimationFrame(() => {
            let e = document.getElementById("ComponentEditQuantity");
            if (e == null)
                e = document.getElementById("PropertyEditKey");
            if (e) {
                if (this.state.selectedIndex === this.state.components.length - 1) {
                    e.id = "";
                    e.scrollIntoView();
                    e.focus();
                }
            }
        });
    }

    doDeleteComponent() {
        let index = this.state.selectedIndex;
        if (index != null) {
            let arr = [...this.state.components];
            arr.splice(index, 1);
            this.setState({ editMode: false, editError: undefined, selectedIndex: undefined, components: arr });
        }
    }

    doDeleteProperty() {
        let index = this.state.selectedPropIndex;
        if (index != null) {
            let arr = [...this.state.properties];
            arr.splice(index, 1);
            this.setState({ editPropMode: false, editPropError: undefined, selectedIndex: undefined, properties: arr });
        }
    }

    applyComponent() {
        let index = this.state.selectedIndex;
        if (index != null) {
            let quantity = parseInt(this.state.editQuantity ?? "");
            if (isNaN(quantity))
                this.setState({ editError: "Please enter a quantity for the component." });
            else {
                let c = this.state.components[index];
                c.productNumber = this.state.editProductNumber ?? "";
                c.unitCode = this.state.editUnitCode ?? "";
                c.quantity = quantity;
                c.componentType = this.state.editComponentType != null ? this.state.editComponentType : ComponentType.Required;
                this.swapComponentInState(index, c);
                this.setState({ editMode: false, editError: undefined });
            }
        }
    }

    swapComponentInState(index: number, updatedC?: ProductComponent) {
        if (index === this.state.selectedIndex && updatedC) {
            let arr = [...this.state.components];
            arr[index] = updatedC;
            this.setState({ components: arr });
        }
    }

    applyProperty() {
        let index = this.state.selectedPropIndex;
        if (index != null) {
            if ((this.state.editPropKey ?? "").length === 0 || (this.state.editPropOptions ?? "").length === 0)
                this.setState({ editError: "Please enter a Key and Options for the property." });
            else {
                let p = this.state.properties[index];
                p.key = this.state.editPropKey ?? "";
                p.options = (this.state.editPropOptions ?? "").split('|');
                this.swapPropertyInState(index, p);
                this.setState({ editPropMode: false, editPropError: undefined });
            }
        }
    }

    swapPropertyInState(index: number, updatedP?: ProductProperty) {
        if (index === this.state.selectedPropIndex && updatedP) {
            let arr = [...this.state.properties];
            arr[index] = updatedP;
            this.setState({ properties: arr });
        }
    }

    doRefreshComponents() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true, editMode: false, editError: undefined, selectedIndex: undefined },
                async () => {
                    let p = await Api.getProduct(this.state.productNumber);
                    if (p)
                        this.setState({ components: [...p.components] });
                    this.setState({ fetchingCommand: false });
                });
        }
    }

    doRefreshProperties() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true, editMode: false, editError: undefined, selectedIndex: undefined },
                async () => {
                    let p = await Api.getProduct(this.state.productNumber);
                    if (p)
                        this.setState({ properties: [...p.properties] });
                    this.setState({ fetchingCommand: false });
                });
        }
    }

    doSaveProduct() {
        if (!this.state.fetchingCommand) {
            let errors = [];
            if (this.state.productNumber.length === 0)
                errors.push("Please enter product number to identify the product.");
            if (this.state.title.length == 0)
                errors.push("Please enter a title for the product.");
            if ((this.state.listPrice ?? "").length === 0)
                errors.push("Please select a list price for the product");
            if (this.isProductFamilyAllowed && (this.state.familyCode ?? "").length == 0)
                errors.push("Please select a product family for the product");

            let listPrice = parseFloat(this.state.listPrice ?? "");
            if (errors.length === 0 && (isNaN(listPrice) || listPrice < 0 || listPrice > 10000))
                errors.push("The list price must be a decimal currency between 0 and 10000");
            
            let weight = parseFloat(this.state.weight);
            if (isNaN(weight))
                weight = 0;

            if (errors.length > 0)
                this.setState({ saveError: errors.join(" ") });
            else {
                this.setState({ saveError: undefined, fetchingCommand: true }, async () => {
                    let p = new Product(this.props.product);
                    p.productNumber = this.state.productNumber;
                    p.title = this.state.title;
                    p.culture = this.state.culture;
                    p.productType = this.state.productType;
                    p.unitType = this.state.unitType;
                    p.defaultUnitCode = this.state.defaultUnitCode;
                    p.defaultPriceId = this.state.defaultPriceId;
                    p.listPrice = listPrice;
                    p.currencyCode = this.state.currencyCode;
                    p.thumbnailUrl = this.state.thumbnailUrl;
                    p.informationUrl = this.state.informationUrl;
                    p.fulfillmentProvider = this.isFulfillmentAllowed ? this.state.fulfillmentProvider : undefined;
                    p.familyCode = this.isProductFamilyAllowed ? this.state.familyCode : undefined;
                    p.isbn = this.isIsbnAllowed ? this.state.isbn : undefined;
                    p.weight = this.isWeightAllowed ? weight : undefined;
                    p.courseId = this.isLabSimCourseAllowed ? this.state.courseId : undefined;
                    p.meritItemId = this.isMeritItemAllowed ? this.state.meritItemId : undefined;
                    p.components = [...this.state.components];
                    p.properties = [...this.state.properties];

                    let updatedP = await Api.updateProduct(p);

                    if (this.props.onSave && updatedP)
                        this.props.onSave(updatedP);
                });
            }
        }
    }

    doCancelProduct() {
        if (this.props.onCancel)
            this.props.onCancel();
    }

    doFetchDependantObjects(cb?: () => void) {
        if (!this.state.fetchingDependentObjects) {
            this.setState({ fetchingDependentObjects: true }, async () => {
                let uts = await Api.getUnitTypes();
                let pls = await Api.getPriceLists();
                let pfs = await Api.getProductFamilies();
                let courseDict = (await Api.getCoursesDictionary()) ?? new StringDictionary();
                let meritDict = (await Api.getMeritItemsDictionary()) ?? new StringDictionary();
                this.setState({
                    fetchingDependentObjects: false,
                    unitTypes: uts,
                    priceLists: pls,
                    productFamilies: pfs,
                    courseDict: courseDict,
                    meritDict: meritDict
                }, () => {
                    if (cb)
                        cb();
                });
                this.doFilterPriceLists();
            });
        }
    }

    doFetchProducts(cb?: () => void) {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let products = await Api.getProducts(this.state.productSearch);
                this.setState({ fetchingCommand: false, products: products }, () => {
                    if (cb)
                        cb();
                });
            });
        }
    }

    clearProductSearch() {
        this.setState({ productSearch: undefined }, () => {
            this.doFetchProducts();
        });
    }

    doAddComponent() {
        if (!this.state.fetchingCommand) {
            this.setState({ productSearch: undefined }, () => {
                this.doFetchProducts(() => {
                    this.setState({ showProductSearch: true, productSearch: undefined });
                });
            });
        }
    }

    addProductAsComponent(product: Product) {
        let comps = [...this.state.components];
        let pc = new ProductComponent();
        pc.productNumber = product.productNumber;
        pc.unitCode = product.defaultUnitCode ?? "1-Year";
        pc.quantity = 1;
        pc.componentType = ComponentType.Required;
        comps.push(pc);
        this.setState({ showProductSearch: false, components: comps }, () => {
            this.selectComponent(comps.length - 1, true, true);
        });
    }

    doAddProperty() {
        if (!this.state.fetchingCommand && !this.state.editPropMode) {
            let props = [...this.state.properties];
            let p = new ProductProperty();
            p.key = "";
            p.options = [];
            props.push(p);
            this.setState({ properties: props }, () => {
                this.selectProperty(props.length - 1, true, true);
            });
        }
    }

    get availUnits(): ProductUnit[] {
        if (this.state.unitTypes) {
            let ut = this.state.unitTypes.find((ut) => ut.unitType === this.state.unitType);
            if (ut)
                return ut.units;
        }
        return [];
    }

    get areComponentsAllowed() {
        return this.state.productType === ProductType.Bundle;
    }

    get isFulfillmentAllowed() {
        switch (this.state.productType) {
            case ProductType.Fee:
                return false;
        }
        return true;
    }

    get isProductFamilyAllowed() {
        switch (this.state.productType) {
            case ProductType.Bundle:
            case ProductType.Fee:
                return false;
        }
        return true;
    }

    get isIsbnAllowed() {
        switch (this.state.productType) {
            case ProductType.Service:
            case ProductType.Fee:
                return false;
        }
        return true;
    }

    get isWeightAllowed() {
        switch (this.state.productType) {
            case ProductType.DigitalGoods:
            case ProductType.Service:
            case ProductType.Bundle:
            case ProductType.Fee:
                return false;
        }
        return true;
    }

    get isLabSimCourseAllowed() {
        switch (this.state.productType) {
            case ProductType.Inventory:
            case ProductType.Service:
            case ProductType.Bundle:
            case ProductType.Fee:
                return false;
        }
        return this.state.fulfillmentProvider === "LabSimServer";
    }

    get isMeritItemAllowed() {
        switch (this.state.productType) {
            case ProductType.Service:
            case ProductType.Bundle:
            case ProductType.Fee:
                return false;
        }
        return this.state.fulfillmentProvider === "CertificatePrinter";
    }

    get selectedPriceListTitle() {
        if (this.state.defaultPriceId > 0) {
            let pl = this.state.priceLists.find(pl => pl.priceId === this.state.defaultPriceId);
            if (pl)
                return `${pl.priceId} - ${pl.name}`;
            return this.state.defaultPriceId;
        }
        return "";
    }

    get selectedCourseTitle() {
        if (this.state.courseId != null) {
            let ckv = this.state.courseDict.find(ckv => ckv.Key.toString() === this.state.courseId.toString());
            if (ckv)
                return `${ckv.Key} - ${ckv.Value}`;
            return this.state.courseId;
        }
        return "";
    }

    get selectedMeritTitle() {
        let mkv = this.state.meritDict.find(mkv => mkv.Key.toString() === (this.state.meritItemId ?? "").toString());
        if (mkv)
            return `${mkv.Key} - ${mkv.Value}`;
        return this.state.meritItemId;
    }

    productNumberToTitle(productNumber: string) {
        if (this.state.products) {
            let p = this.state.products.find((p) => p.productNumber === productNumber);
            if (p)
                return p.title;
        }
        return "";
    }

    doShowPriceListSearch() {
        this.setState({ showPriceListSearch: true, priceListSearch: undefined });
    }

    doFilterPriceLists() {
        if (this.state.priceListSearch == null || this.state.priceListSearch.length === 0)
            this.setState({ filteredPriceLists: [...this.state.priceLists] });
        else {
            let search = (this.state.priceListSearch ?? "").toLowerCase();
            let filtered = this.state.priceLists.filter(pl => search.indexOf(pl.priceId.toString()) > -1 || (pl.name ?? "").toLocaleLowerCase().indexOf(search));
            this.setState({ filteredPriceLists: filtered });
        }
    }

    clearPriceListSearch() {
        this.setState({ filteredPriceLists: [...this.state.priceLists], priceListSearch: undefined });
    }

    cancelComponentEdit() {
        if (this.state.componentIsNew)
            this.doDeleteComponent();
        else this.setState({ editMode: false, editError: undefined, selectedIndex: undefined });
    }

    cancelPropertyEdit() {
        if (this.state.propIsNew)
            this.doDeleteProperty();
        else this.setState({ editPropMode: false, editPropError: undefined, selectedPropIndex: undefined });
    }

    onUnitTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let unitType = e.target.value;
        let units = this.state.unitTypes.find(ut => ut.unitType === unitType)?.units;
        this.setState({ unitType: unitType, defaultUnitCode: units && units.length ? units[0].unitCode : "" });
    }

    render() {
        return <div className="ProductEditDlg">
            <header>
                <div>
                    <div>
                        <button className="RibbonButton" onClick={() => this.doSaveProduct()}><img src={ico_Save} />SAVE AND RETURN</button>
                        <button className="RibbonButton" onClick={() => this.doCancelProduct()}><img src={ico_Close} />CANCEL CHANGES</button>
                    </div>
                </div>
                <div>
                    <ConnectionInfo />
                </div>
            </header>
            <div className={this.state.fetchingCommand || this.state.fetchingDependentObjects ? "Spinner" : ""}></div>
            <div className="PageTitle">
                <div>
                    <img src={ico_Calc} />
                    <div>
                        {this.props.isNew && <span>NEW PRODUCT</span>}
                        {!this.props.isNew && <span>PRODUCT: {this.props.product.productNumber}</span>}
                        <span>{this.props.isNew ? "New" : this.props.product.title}</span>
                    </div>
                </div>
                <div>
                    <span>Staus</span>
                    <span>{this.props.isNew ? "New" : (ProductStatus[this.props.product.status])}</span>
                </div>
            </div>
            <div className="Columns">
                <div className="ProductFields">
                    <div>
                        <h1>DETAILS</h1>
                    </div>
                    {this.state.saveError && <div className="Error">{this.state.saveError}</div>}
                    <div>
                        <div><span className="Bold">Product Number</span><span><input disabled={!this.props.isNew} value={this.state.productNumber} onChange={(e) => { this.setState({ productNumber: e.target.value }) }} /></span></div>
                        <div><span className="Bold">Title</span><span><input value={this.state.title} onChange={(e) => {this.setState({title: e.target.value})}} /></span></div>
                        <div><span>Culture</span><span>
                            <select value={this.state.culture} onChange={(e) => { this.setState({ culture: e.target.value }) }}>
                                <option value="en-US">en-US - English (United States)</option>
                                <option value="en">en - English</option>
                                <option value="es">es - Spanish</option>
                                <option value="pt-BR">pt-BR - Portuguese (Brazil)</option>
                            </select>
                        </span></div>
                        <div><span className="Bold">Product Type</span><span>
                            <select value={this.state.productType.toString()} onChange={(e) => { this.setState({ productType: parseInt(e.target.value) as ProductType }) }}>
                                <option value="1">Inventory</option>
                                <option value="0">DigitalGoods</option>
                                <option value="5">Service</option>
                                <option value="3">Bundle</option>
                                <option value="6">Fee</option>
                            </select>
                        </span></div>
                        <div><span className="Bold">Unit Type</span><span>
                            <select value={this.state.unitType.toString()} onChange={this.onUnitTypeChange}>
                                {(this.state.unitTypes ?? [] as ProductUnitType[]).map(ut => <option key={ut.unitType} value={ut.unitType}>{ut.unitType}</option>)}
                            </select>
                        </span></div>
                        <div><span className="Bold">Default Unit</span><span>
                            <select value={this.state.defaultUnitCode.toString()} onChange={(e) => { this.setState({ defaultUnitCode: e.target.value }) }}>
                                {this.availUnits.map(u => <option key={u.unitCode} value={u.unitCode}>{u.unitCode}</option>)}
                            </select>
                        </span></div>
                        <div className="ButtonSelect"><span className="Bold">Default Price List</span><span>
                            <button onClick={() => this.doShowPriceListSearch()}>Select</button><span>{this.selectedPriceListTitle}</span>
                        </span></div>
                        <div><span className="Bold">List Price</span><span><input value={this.state.listPrice} onChange={(e) => { this.setState({ listPrice: e.target.value }) }} /></span></div>
                        <div><span className="Bold">Default Currency</span><span>
                            <select value={this.state.currencyCode} onChange={(e) => {this.setState({currencyCode: e.target.value})}}>
                                <option value="AUD">AUD - Australian Dollar</option>
                                <option value="CAD">CAD - Canadian Dollar</option>
                                <option value="EUR">EUR - Euro</option>
                                <option value="GBP">GBP - GBP - British Pound</option>
                                <option value="NZD">NZD - New Zealand Dollar</option>
                                <option value="ZAR">ZAR - South African Rand</option>
                                <option value="USD">USD - US Dollar</option>
                            </select>
                        </span></div>
                        <div><span>Thumnail URL</span><span><input value={this.state.thumbnailUrl} onChange={(e) => { this.setState({ thumbnailUrl: e.target.value }) }} /></span></div>
                        <div><span>Information URL</span><span><input value={this.state.informationUrl} onChange={(e) => { this.setState({ informationUrl: e.target.value }) }} /></span></div>
                        <div><span>Fulfillment</span><span>
                            {!this.isFulfillmentAllowed && <input value="" disabled={true} />}
                            {this.isFulfillmentAllowed &&
                                <select value={this.state.fulfillmentProvider} onChange={(e) => { this.setState({ fulfillmentProvider: e.target.value }) }}>
                                    <option value=""></option>
                                    <option value="LabSimServer">LabSim Server</option>
                                    <option value="CertificatePrinter">Certificate Printer</option>
                                    <option value="VoucherService">Voucher Service</option>
                                    <option value="FulfillmentAdmin">Fulfillment Administrator</option>
                                    <option value="ParcelShipper">Parcel Shipper</option>
                                </select>
                            }
                        </span></div>
                        <div><span className={this.isProductFamilyAllowed ? "Bold" : ""}>Product Family</span><span>
                            {!this.isProductFamilyAllowed && <input value={""} disabled={true} />}
                            {this.isProductFamilyAllowed &&
                                <select value={this.state.familyCode} onChange={(e) => { this.setState({ familyCode: e.target.value }) }}>
                                    <option value=""></option>
                                    {this.state.productFamilies.map(pf => <option value={pf.familyCode}>{pf.name}</option>)}
                                </select>
                            }
                        </span></div>
                        <div><span>ISBN</span><span><input disabled={!this.isIsbnAllowed} value={this.isIsbnAllowed ? this.state.isbn : ""} onChange={(e) => { this.setState({ isbn: e.target.value }) }} /></span></div>
                        <div><span>Weight</span><span><input disabled={!this.isWeightAllowed} value={this.isWeightAllowed ? this.state.weight : ""} onChange={(e) => { this.setState({ weight: e.target.value }) }} /></span></div>
                        <div className="ButtonSelect"><span>LabSim Course</span><span>
                            <button disabled={!this.isLabSimCourseAllowed} onClick={() => this.setState({ showCourseLookup: true })}>Select</button><span>{this.isLabSimCourseAllowed ? this.selectedCourseTitle : ""}</span>
                        </span></div>
                        <div className="ButtonSelect"><span>LabSim Merit Item</span><span>
                            <button disabled={!this.isMeritItemAllowed} onClick={() => this.setState({ showMeritLookup: true })}>Select</button><span>{this.isMeritItemAllowed ? this.selectedMeritTitle : ""}</span>
                        </span></div>
                    </div>
                </div>
                <div className="RightColumn">
                    <div className={this.areComponentsAllowed ? "Components" : "Components Disabled"}>
                        <div>
                            <h1>COMPONENTS</h1>
                            <span>
                                <button disabled={!this.areComponentsAllowed} onClick={() => this.doAddComponent()} className="ChromelessButton"><img src={ico_Plus} /></button>
                                <button disabled={!this.areComponentsAllowed || this.selectedComponent == null} onClick={() => this.doDeleteComponent()} className="ChromelessButton"><img src={ico_Trash} /></button>
                            </span>
                        </div>
                        {this.state.editError && <div className="Error">{this.state.editError}</div>}
                        <div className="ComponentsHeader ComponentItem">
                            <div>
                                <span>Product{this.areComponentsAllowed && <img src={this.getSortIcon("Product")} onClick={() => this.toggleSort("Product")} />}</span>
                                <span>Unit{this.areComponentsAllowed && <img src={this.getSortIcon("Unit")} onClick={() => this.toggleSort("Unit")} />}</span>
                                <span>Quantity{this.areComponentsAllowed && <img src={this.getSortIcon("Quantity")} onClick={() => this.toggleSort("Quantity")} />}</span>
                                <span>Member Type{this.areComponentsAllowed && <img src={this.getSortIcon("MemberType")} onClick={() => this.toggleSort("MemberType")} />}</span>
                                <div><button disabled={!this.areComponentsAllowed} className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doRefreshComponents()} /></button></div>
                            </div>
                        </div>
                        <div className={`ComponentsScroller${this.state.editError ? " HasError" : ""}`}>
                            {(this.areComponentsAllowed ? this.state.components : []).map((c, index) => {
                                return <div className={`ComponentItem ${this.state.selectedIndex === index ? "Selected" : ""}`} key={index} onMouseDown={() => this.selectComponent(index)}>
                                    <div>
                                        <span>
                                            {(!this.state.editMode || index !== this.state.selectedIndex) && <a onClick={() => this.selectComponent(index, true)}>{c.productNumber}</a>}
                                            {(this.state.editMode && index === this.state.selectedIndex) && c.productNumber}
                                        </span>
                                        <span>
                                            {(!this.state.editMode || index !== this.state.selectedIndex) && c.unitCode}
                                            {this.state.editMode && index === this.state.selectedIndex && <select value={this.state.editUnitCode} onChange={(e) => { this.setState({ editUnitCode: e.target.value }) }}>
                                                {this.availUnits.map((ut) => <option key={ut.unitCode} value={ut.unitCode}>{ut.unitCode}</option>)}
                                            </select>}
                                        </span>
                                        <span>
                                            {(!this.state.editMode || index !== this.state.selectedIndex) && c.quantity}
                                            {this.state.editMode && index === this.state.selectedIndex && <input id="ComponentEditQuantity" value={this.state.editQuantity} onChange={(e) => { this.setState({ editQuantity: e.target.value }) }} />}
                                        </span>
                                        <span>
                                            {(!this.state.editMode || index !== this.state.selectedIndex) && ComponentType[c.componentType]}
                                            {this.state.editMode && index === this.state.selectedIndex && <select value={this.state.editComponentType} onChange={(e) => { this.setState({ editComponentType: parseInt(e.target.value) as ComponentType }) }}>
                                                <option value="0">Required</option>
                                                <option value="1">Optional</option>
                                                <option value="2">Quantity</option>
                                            </select>}
                                        </span>
                                        {this.state.editMode && index === this.state.selectedIndex && <span><img src={ico_Save} onClick={() => { this.applyComponent() }} /><img src={ico_Close} onClick={() => this.cancelComponentEdit()} /></span>}
                                    </div>
                                    <div>{this.productNumberToTitle(c.productNumber)}</div>
                                </div>
                            })}
                        </div>
                        <div className="ComponentFooter">
                            <span>{`${this.state.components.length} record${this.state.components.length === 1 ? "" : "s"}`}</span>
                        </div>
                    </div>
                    <div className="Properties">
                        <div>
                            <h1>PROPERTIES</h1>
                            <span>
                                <button onClick={() => this.doAddProperty()} className="ChromelessButton"><img src={ico_Plus} /></button>
                                <button disabled={this.selectedProperty == null} onClick={() => this.doDeleteProperty()} className="ChromelessButton"><img src={ico_Trash} /></button>
                            </span>
                        </div>
                        {this.state.editPropError && <div className="Error">{this.state.editPropError}</div>}
                        <div className="PropertiesHeader PropertyItem">
                            <div>
                                <span>Key</span>
                                <span>Options</span>
                                <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doRefreshProperties()} /></button></div>
                            </div>
                        </div>
                        <div className={`PropertiesScroller${this.state.editError ? " HasError" : ""}`}>
                            {this.state.properties.map((p, index) => {
                                return <div className={`PropertyItem ${this.state.selectedPropIndex === index ? "Selected" : ""}`} key={index} onMouseDown={() => this.selectProperty(index)}>
                                    <div>
                                        <span>
                                            {(!this.state.editPropMode || index !== this.state.selectedPropIndex) && <a onClick={() => this.selectProperty(index, true)}>{p.key}</a>}
                                            {(this.state.editPropMode && index === this.state.selectedPropIndex) && <input id="PropertyEditKey" value={this.state.editPropKey} onChange={(e) => this.setState({ editPropKey: e.target.value })} />}
                                        </span>
                                        <span>
                                            {(!this.state.editPropMode || index !== this.state.selectedPropIndex) && p.options}
                                            {(this.state.editPropMode && index === this.state.selectedPropIndex) && <input value={this.state.editPropOptions} onChange={(e) => this.setState({ editPropOptions: e.target.value })} />}
                                        </span>
                                        {this.state.editPropMode && index === this.state.selectedPropIndex && <span><img src={ico_Save} onClick={() => { this.applyProperty() }} /><img src={ico_Close} onClick={() => this.cancelPropertyEdit()} /></span>}
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="PropertiesFooter">
                            <span>{`${this.state.properties.length} record${this.state.properties.length === 1 ? "" : "s"}`}</span>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.showPriceListSearch && <Dialog>
                <div className="SelectPriceList">
                    <div><h1>Select a Price List</h1>
                        <div className="Search">
                            <input placeholder="Search for records" value={this.state.priceListSearch ?? ""} onChange={(e) => this.setState({ priceListSearch: e.target.value })} onBlur={() => this.doFilterPriceLists()} onKeyPress={(e) => { if (e.key == "Enter") this.doFilterPriceLists() }} />
                            {!this.state.priceListSearch && <img src={ico_Magnifier} onMouseDown={() => this.doFilterPriceLists()} />}
                            {this.state.priceListSearch && <img src={ico_Close} onMouseDown={() => this.clearPriceListSearch()} />}
                        </div>
                    </div>
                    <div>{(this.state.filteredPriceLists).map((pl) => <div onMouseDown={() => this.setState({ showPriceListSearch: false, defaultPriceId: pl.priceId })}><span>{pl.priceId}</span><span></span>{pl.name}</div>)}</div>
                    <div><button onClick={() => this.setState({ showPriceListSearch: false })}>Cancel</button></div>
                </div>
            </Dialog>}
            {this.state.showCourseLookup && <Dialog>
                <div className="SelectCourse">
                    <div><h1>Select a Course</h1></div>
                    <div>{(this.state.courseDict).map((ckv) => <div onMouseDown={() => this.setState({ showCourseLookup: false, courseId: parseInt(ckv.Key.toString()) })}><span>{ckv.Key}</span><span></span>{ckv.Value}</div>)}</div>
                    <div><button onClick={() => this.setState({ showCourseLookup: false })}>Cancel</button></div>
                </div>
            </Dialog>}
            {this.state.showMeritLookup && <Dialog>
                <div className="SelectMerit">
                    <div><h1>Select a Merit Item</h1></div>
                    <div>{(this.state.meritDict).map((ckv) => <div onMouseDown={() => this.setState({ showMeritLookup: false, meritItemId: ckv.Key.toString() })}><span>{ckv.Key}</span><span></span>{ckv.Value}</div>)}</div>
                    <div><button onClick={() => this.setState({ showMeritLookup: false })}>Cancel</button></div>
                </div>
            </Dialog>}
            {this.state.showProductSearch && <Dialog>
                <div className="SelectProduct">
                    <div><h1>Select a Product</h1>
                        <div className="Search">
                            <input placeholder="Search for records" value={this.state.productSearch ?? ""} onChange={(e) => this.setState({ productSearch: e.target.value })} onBlur={() => this.doFetchProducts()} onKeyPress={(e) => { if (e.key == "Enter") this.doFetchProducts() }} />
                            {!this.state.productSearch && <img src={ico_Magnifier} onMouseDown={() => this.doFetchProducts()} />}
                            {this.state.productSearch && <img src={ico_Close} onMouseDown={() => this.clearProductSearch()} />}
                        </div>
                    </div>
                    <div>{(this.state.products ?? [] as Product[]).map((p) => <div onMouseDown={() => this.addProductAsComponent(p)}><span>{p.productNumber}</span><span></span>{p.title}</div>)}</div>
                    <div><button onClick={() => this.setState({ showProductSearch: false })}>Cancel</button></div>
                </div>
            </Dialog>}
        </div>;
    }
}

export default withRouter(Products);