import * as React from 'react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import moment from 'moment';
import ConnectionInfo from './ConnectionInfo';
import { PriceGroup } from "@testout/testout-commerce/models/commerce/PriceGroup";
import { Api, ICCMeta } from './Api';
import Dialog from './Dialog';
import { PriceGroupCode } from '@testout/testout-commerce/models/commerce/PriceGroupCode';
import { IPriceGroup } from '@testout/testout-commerce/models/commerce/PriceGroup';
import { PriceList } from '@testout/testout-commerce/models/commerce/PriceList';
import { ProductStatus } from '@testout/testout-commerce/models/commerce/Product';

import './PriceGroups.scss';
import ico_Home from './images/ico_home.png';
import ico_Refresh from './images/ico_refresh.png';
import ico_Magnifier from './images/ico_magnifier.png';
import ico_Close from './images/ico_close.png';
import ico_Sort from './images/ico_sort.png';
import ico_SortUp from './images/ico_sortup.png';
import ico_SortDown from './images/ico_sortdown.png';
import ico_Trash from './images/ico_trash.png';
import ico_CalcPeople from './images/ico_calcpeople.png';
import ico_Plus from './images/ico_plus.png';
import ico_ToggleOn from './images/ico_toggleon.png';
import ico_ToggleOff from './images/ico_toggleoff.png';
import ico_Increase from './images/ico_increase.png';
import ico_CheckedBox from './images/ico_checkedbox.png';
import ico_UncheckedBox from './images/ico_uncheckedbox.png';
import ico_UpArrow from './images/ico_uparrow.png';
import ico_DownArrow from './images/ico_downarrow.png';
import ico_Save from './images/ico_save.png';
import { Price } from '@testout/testout-commerce/models/commerce/Price';

interface IPriceGroupsProps extends RouteComponentProps {

}

interface IPriceGroupsState {
    fetchingPriceGroups: boolean;
    fetchingPriceGroupCode: boolean;
    fetchingCommand: boolean;
    priceGroups: PriceGroup[];
    selectedGroupIndex?: number;
    search?: string;
    sortColumn: string;
    sortDirection: string;
    editMode: boolean;

    selectedPriceCode?: string;
    priceGroupCode?: PriceGroupCode;
    selectedProductIndex?: number;

    editError?: string;
    editType?: string;
    editName?: string;
    editPriceList?: number;
    showPriceListSearch?: boolean;
    priceListSearch?: string;
    priceLists?: PriceList[];
}

class PriceGroups extends React.PureComponent<IPriceGroupsProps, IPriceGroupsState> {
    constructor(props: IPriceGroupsProps) {
        super(props);
        this.state = { fetchingPriceGroups: false, fetchingPriceGroupCode: false, fetchingCommand: false, priceGroups: [], sortColumn:"Grp", sortDirection: "Up", editMode: false };
    }

    componentDidMount() {
        this.doFetchPriceGroups();
    }

    _lastSearch: string | undefined = undefined;
    doFetchPriceGroups() {
        if (!this.state.fetchingPriceGroups) {
            this.setState({ fetchingPriceGroups: true, selectedGroupIndex: undefined, selectedPriceCode: undefined, priceGroupCode: undefined, selectedProductIndex: undefined, editMode: false, editError: undefined }, async () => {
                this._lastSearch = this.state.search;
                let priceGroups = await Api.getPriceGroups(this.state.search);
                this.setState({ fetchingPriceGroups: false, priceGroups: priceGroups });
                this.doSort();
            });
        }
    }

    clearSearch() {
        this.setState({ search: undefined }, () => {
            this.doFetchPriceGroups();
        });
    }

    selectPriceGroup(index: number, doEdit: boolean | undefined = undefined) {
        if (!this.state.fetchingPriceGroups) {
            if (doEdit != null)
                setTimeout(() => {
                    let grp = this.state.priceGroups[index];
                    this.setState({ selectedGroupIndex: index, editMode: doEdit, editError: undefined, editType: grp.priceGroupTypeCode, editName: grp.name, editPriceList: undefined })
                }, 1);
            else this.setState({ selectedGroupIndex: index, editMode: this.state.editMode && this.state.selectedGroupIndex === index });
        }
    }

    selectPriceGroupCode(priceGroupCode: string) {
        if (!this.state.fetchingPriceGroupCode) {
            this.setState({ selectedPriceCode: priceGroupCode, priceGroupCode: undefined, selectedProductIndex: undefined }, () => {
                this.setState({ fetchingPriceGroupCode: true }, async () => {
                    let pgc = await Api.getPriceGroupCode(priceGroupCode);
                    if (this.state.selectedPriceCode === priceGroupCode)
                        this.setState({ priceGroupCode: pgc });
                    this.setState({ fetchingPriceGroupCode: false });
                });
            });
        }
    }

    refreshPriceGroupCode() {
        if (this.state.selectedPriceCode)
            this.selectPriceGroupCode(this.state.selectedPriceCode);
    }

    selectProduct(index: number) {
        this.setState({ selectedProductIndex: index });
    }


    doSort(cb?: () => void) {
        let arr = [...this.state.priceGroups];
        arr.sort((a, b) => {
            let result = 0;
            switch (this.state.sortColumn) {
                case "Grp": result = a.priceGroupId - b.priceGroupId; break;
                case "Type": result = a.priceGroupTypeCode.localeCompare(b.priceGroupTypeCode); break;
                case "Name": result = a.name.localeCompare(b.name); break;
            }
            if (this.state.sortDirection === "Down")
                result = result * -1;
            return result;
        });
        this.setState({ priceGroups: arr, selectedGroupIndex: undefined }, () => {
            if (cb)
                cb();
        });
    }

    toggleSort(column: string) {
        if (this.state.sortColumn === column) {
            this.setState({ sortDirection: this.state.sortDirection === "Up" ? "Down" : "Up" }, () => this.doSort());
        }
        else this.setState({ sortColumn: column, sortDirection: "Down" }, () => this.doSort());
    }

    getSortIcon(column: string) {
        if (this.state.sortColumn === column) {
            switch (this.state.sortDirection) {
                case "Up": return ico_SortUp;
                case "Down": return ico_SortDown;
            }
        }
        return ico_Sort;
    }

    get selectedPriceGroup(): PriceGroup | undefined {
        if (this.state.priceGroups != null && this.state.selectedGroupIndex != null)
            return this.state.priceGroups[this.state.selectedGroupIndex];
        return undefined;
    }

    doAddPriceGroup() {
        if (!this.state.fetchingCommand && !this.state.fetchingPriceGroups) {
            this.setState({ fetchingCommand: true }, async () => {
                let newId = await Api.getNewPriceGroupId();
                if (newId != null) {
                    let groups = [...this.state.priceGroups];
                    groups.push(new PriceGroup({
                        priceGroupId: newId,
                        priceGroupTypeCode: "Customer",
                        name: "",
                        priceCodes: []
                    }));
                    this.setState({ priceGroups: groups }, () => {
                        this.doSort(() => {
                            let sortedIndex = this.state.priceGroups.findIndex((g) => g.priceGroupId == newId);
                            this.selectPriceGroup(sortedIndex, true);
                        });
                    });
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    componentDidUpdate() {
        requestAnimationFrame(() => {
            let e = document.getElementById("priceGroupNameEdit");
            if (e) {
                if ((e as HTMLInputElement).value === "") {
                    e.scrollIntoView();
                    e.focus();
                }
            }
        });
    }

    doDeletePriceGroup() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let pg = this.selectedPriceGroup
                if (pg)
                    await Api.deletePriceGroup(pg.priceGroupId);
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchPriceGroups();
                });
            });
        }
    }

    doSynchBackOffice() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                await Api.syncPriceGroups();
                this.setState({ fetchingCommand: false }, () => {
                    this.doFetchPriceGroups();
                });
            });
        }
    }

    swapPriceGroupInState(index: number, updatedPg?: PriceGroup) {
        if (index === this.state.selectedGroupIndex && updatedPg) {
            let pgs = [...this.state.priceGroups];
            pgs[index] = updatedPg;
            this.setState({ priceGroups: pgs });
        }
    }

    togglePriceCodeEnabled(pgc: PriceGroupCode) {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let index = this.state.selectedGroupIndex;
                if (index != null) {
                    let pg = this.state.priceGroups[index];
                    let updatedPg = await Api.togglePriceCodeEnabled(pg.priceGroupId, pgc.priceCode, !pgc.enabled);
                    this.swapPriceGroupInState(index, updatedPg);
                    this.setState({ selectedPriceCode: pgc.priceCode });
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    updatePriceCodeVersion(pgc: PriceGroupCode) {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let index = this.state.selectedGroupIndex;
                if (index != null) {
                    let pg = this.state.priceGroups[index];
                    let updatedPg = await Api.updatePriceCodeVersion(pg.priceGroupId, pgc.priceCode);
                    this.swapPriceGroupInState(index, updatedPg);
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    deletePriceCode(pgc: PriceGroupCode) {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let index = this.state.selectedGroupIndex;
                if (index != null) {
                    let pg = this.state.priceGroups[index];
                    let updatedPg = await Api.deletePriceCode(pg.priceGroupId, pgc.priceCode);
                    this.swapPriceGroupInState(index, updatedPg);
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    updateSelectedProductIndex(productNumber: string, unitCode: string) {
        let options = this.state.priceGroupCode?.priceGroupOptions;
        if (options) {
            let newIndex = options.findIndex((opt) => opt.productNumber === productNumber && opt.unitCode === unitCode);
            this.setState({ selectedProductIndex: newIndex > -1 ? newIndex : undefined });
        }
    }

    moveProduct(delta: number) {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                if (this.state.priceGroupCode && this.state.selectedProductIndex != null) {
                    if (this.state.priceGroupCode != null && this.state.priceGroupCode?.priceGroupOptions && this.state.selectedProductIndex != null) {
                        let pc = this.state.selectedPriceCode;
                        let p = this.selectedPriceOption;
                        if (pc && p) {
                            let updatedPGC = await Api.moveProductInPriceList(pc, p.productNumber, p.unitCode, delta);
                            if (this.state.selectedPriceCode === pc) {
                                this.setState({ priceGroupCode: updatedPGC }, () => {
                                    if (p)
                                        this.updateSelectedProductIndex(p.productNumber, p.unitCode);
                                });
                            }
                        }
                    }
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    toggleProductVisibility() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                if (this.state.priceGroupCode && this.state.selectedProductIndex != null) {
                    if (this.state.priceGroupCode != null && this.state.priceGroupCode?.priceGroupOptions && this.state.selectedProductIndex != null) {
                        let pc = this.state.selectedPriceCode;
                        let p = this.selectedPriceOption;
                        if (pc && p) {
                            let updatedPGC = await Api.toggleOptionVisibilty(pc, p.productNumber, p.unitCode);
                            if (this.state.selectedPriceCode === pc) {
                                this.updateSelectedProductIndex(p.productNumber, p.unitCode);
                                this.setState({ priceGroupCode: updatedPGC });
                            }
                        }
                    }
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    toggleAllProductVisibility() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                if (this.state.priceGroupCode != null && this.state.priceGroupCode?.priceGroupOptions) {
                    let pc = this.state.selectedPriceCode;
                    if (pc) {
                        let updatedPGC = await Api.toggleOptionsVisibility(pc, !this.allProductsVisible);
                        if (this.state.selectedPriceCode === pc)
                            this.setState({ priceGroupCode: updatedPGC, selectedProductIndex: undefined });
                    }
                }
                this.setState({ fetchingCommand: false });
            });
        }
    }

    savePriceGroup() {
        if (!this.state.fetchingCommand) {
            if ((this.state.editName ?? "") === "") {
                this.setState({ editError: "Please enter a name for the customer list." });
            }
            else {
                this.setState({ fetchingCommand: true }, async () => {
                    let index = this.state.selectedGroupIndex;
                    if (index != null) {
                        let pg = (Object.assign({}, this.state.priceGroups[index]) as any) as IPriceGroup;
                        pg.priceGroupTypeCode = this.state.editType as string;
                        pg.name = this.state.editName as string;

                        let updatedPg = await Api.updatePriceGroup(pg, this.state.editPriceList);
                        this.swapPriceGroupInState(index, updatedPg);
                        this.selectPriceGroup(index, false);
                        this.setState({ editError: undefined });
                    }
                    this.setState({ fetchingCommand: false });
                });
            }
        }
    }

    get allProductsVisible() {
        if (this.state.priceGroupCode && this.state.priceGroupCode.priceGroupOptions) {
            let allVisible = true;
            this.state.priceGroupCode.priceGroupOptions.forEach((o) => { if (!o.visible) allVisible = false });
            return allVisible;
        }
        return false;
    }

    get selectedPriceOption() {
        if (this.state.priceGroupCode && this.state.selectedProductIndex != null) {
            let pgc = this.state.priceGroupCode;
            if (pgc && pgc.priceGroupOptions)
                return pgc.priceGroupOptions[this.state.selectedProductIndex];
        }
    }

    showPriceListSearch() {
        this.setState({ showPriceListSearch: true, priceLists: [], priceListSearch: undefined }, () => this.doFetchPriceLists());
    }

    doFetchPriceLists() {
        if (this.state.showPriceListSearch && !this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let priceLists = await Api.getPriceLists(this.state.priceListSearch);
                if (priceLists)
                    this.setState({ priceLists: priceLists });
                this.setState({ fetchingCommand: false });
            });
        }
    }

    clearPriceListSearch() {
        this.setState({ priceListSearch: undefined }, () => {
            this.doFetchPriceLists();
        });
    }

    render() {
        return <div className="PriceGroups">
            <header>
                <div>
                    <div>
                        <button className="RibbonButton" onClick={() => { this.props.history.push("/") }}><img src={ico_Home} />HOME</button>
                        <button className="RibbonButton" onClick={() => this.doAddPriceGroup()}><img src={ico_Plus} />ADD PRICE GROUP</button>
                        <button className="RibbonButton" onClick={() => this.doDeletePriceGroup()} disabled={this.state.fetchingPriceGroupCode || this.state.selectedGroupIndex == null}><img src={ico_Trash} />DELETE PRICE GROUP</button>
                        <button className="RibbonButton" onClick={() => this.doSynchBackOffice()}><img src={ico_Refresh} />SYNC BACK-OFFICE</button>
                    </div>
                </div>
                <div>
                    <ConnectionInfo />
                </div>
            </header>
            <div className={this.state.fetchingPriceGroups || this.state.fetchingPriceGroupCode || this.state.fetchingCommand ? "Spinner" : ""}></div>
            <div>
                <div className="PageTitle">
                    <img src={ico_CalcPeople} />
                    <span>Price Groups</span>
                </div>
                <div className="Search">
                    <input placeholder="Search for records" value={this.state.search ?? ""} onChange={(e) => this.setState({ search: e.target.value })} onBlur={() => {if (this._lastSearch !== this.state.search) this.doFetchPriceGroups() }} onKeyPress={(e) => { if (e.key == "Enter") this.doFetchPriceGroups() }} />
                    {!this.state.search && <img src={ico_Magnifier} onMouseDown={() => this.doFetchPriceGroups()} />}
                    {this.state.search && <img id="imgCancelPriceGroupSearch" src={ico_Close} onMouseDown={() => this.clearSearch()} />}
                </div>
            </div>
            <div className="Columns">
                <div className="PriceGroupList">
                    {this.state.editError && <div className="Error">{this.state.editError}</div>}
                    <div className="PriceGroupHeader PriceGroupItem">
                        <span>Grp<img src={this.getSortIcon("Grp")} onClick={() => this.toggleSort("Grp")} /></span>
                        <span>Type<img src={this.getSortIcon("Type")} onClick={() => this.toggleSort("Type")} /></span>
                        <span>Name<img src={this.getSortIcon("Name")} onClick={() => this.toggleSort("Name")} /></span>
                        <span>Price Codes</span>
                        <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchPriceGroups()} /></button></div>
                    </div>
                    <div className={`PriceGroupScroller${this.state.editError ? " HasError" : ""}`}>
                        {this.state.priceGroups.map((priceGroup, index) => {
                            return <div className={`PriceGroupItem ${this.state.selectedGroupIndex === index ? "Selected" : ""}`} key={index} onClick={() => this.selectPriceGroup(index)}>
                                <span><a onClick={() => this.selectPriceGroup(index, true)}>{priceGroup.priceGroupId}</a></span>
                                <span>
                                    {(!this.state.editMode || index !== this.state.selectedGroupIndex) && priceGroup.priceGroupTypeCode}
                                    {this.state.editMode && index === this.state.selectedGroupIndex && <select value={this.state.editType} onChange={(e) => { this.setState({ editType: e.target.value }) }}>
                                        <option value="General">General</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Academic">Academic</option>
                                        <option value="HigherEd">HigherEd</option>
                                        <option value="SecondaryEd">SecondaryEd</option>
                                        <option value="Professional">Professional</option>
                                        <option value="Reseller">Reseller</option>
                                    </select>}
                                </span>
                                <span>
                                    {(!this.state.editMode || index !== this.state.selectedGroupIndex) && priceGroup.name}
                                    {this.state.editMode && index === this.state.selectedGroupIndex && <input id="priceGroupNameEdit"  value={this.state.editName} onChange={(e) => { this.setState({editName: e.target.value}) }} />}
                                </span>
                                <span>{priceGroup.priceCodes.map((pgc) => <span className={`PriceCode${this.state.selectedPriceCode === pgc.priceCode ? " Selected": ""}`} key={pgc.priceCode}>
                                        <button className="ChromelessButton"><img src={pgc.enabled ? ico_ToggleOn : ico_ToggleOff} onClick={() => this.togglePriceCodeEnabled(pgc)} /></button>
                                        <a className={pgc.enabled ? "" : "Lighter"} onClick={() => this.selectPriceGroupCode(pgc.priceCode)}>{pgc.priceCode}</a>
                                        {pgc.enabled && <button className="ChromelessButton"><img src={ico_Increase} onClick={() => this.updatePriceCodeVersion(pgc)} /></button>}
                                        {!pgc.enabled && <img src={ico_Trash} onClick={() => this.deletePriceCode(pgc)} />}
                                </span>)}
                                    {this.state.editMode && index === this.state.selectedGroupIndex && <span className="PriceCode AddPriceCode"><img src={ico_Plus} /><span>{this.state.editPriceList}</span><button onClick={() => this.showPriceListSearch()}>Select</button></span>}
                                </span>
                                {this.state.editMode && index === this.state.selectedGroupIndex && <span><img src={ico_Save} onClick={() => { this.savePriceGroup() }} /><img src={ico_Close} onClick={() => this.doFetchPriceGroups()} /></span>}
                            </div>
                        })}
                    </div>
                    <div className="PriceGroupFooter">
                        <span>{`${this.state.priceGroups.length} record${this.state.priceGroups.length === 1 ? "" : "s"}`}</span>
                    </div>
                </div>
                <div className="PriceGroupDetail">
                    {this.state.priceGroupCode && <h1>
                        <span>{`${this.state.selectedPriceCode ?? ""} ${(this.state.priceGroupCode?.priceList?.name ?? "") === "SRP" ? "(SRP)" : ""}`} DISPLAY</span>
                        <div>
                            <button disabled={(this.state.selectedProductIndex == null || this.state.selectedProductIndex < 1) || !this.selectedPriceOption?.visible} className="ChromelessButton" onClick={() => this.moveProduct(-1)}><img src={ico_UpArrow} /></button>
                            <button disabled={(this.state.selectedProductIndex == null || this.state.selectedProductIndex > (this.state.priceGroupCode?.priceList?.prices.length ?? 0) - 2) || !this.selectedPriceOption?.visible} className="ChromelessButton" onClick={() => this.moveProduct(1)}><img src={ico_DownArrow} /></button>
                        </div>
                    </h1>}
                    {!this.state.priceGroupCode?.priceList && <h1>DISPLAY</h1>}
                    <div className="ProductHeader ProductItem">
                        <div>
                            <span><img src={this.allProductsVisible ? ico_CheckedBox : ico_UncheckedBox} onClick={() => this.toggleAllProductVisibility()} />Product</span>
                            <span>Unit</span>
                            <span>Price</span>
                            <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.refreshPriceGroupCode()} /></button></div>
                        </div>
                    </div>
                    <div className="ProductScroller">
                        {this.state.priceGroupCode != null && this.state.priceGroupCode.priceGroupOptions != null && this.state.priceGroupCode?.priceGroupOptions.map((pgo, index) => {
                            return <div className={`ProductItem ${this.state.selectedProductIndex === index ? "Selected" : ""}`} key={index} onClick={() => this.selectProduct(index)}>
                                <div>
                                    <span><img src={pgo.visible ? ico_CheckedBox : ico_UncheckedBox} onClick={() => this.toggleProductVisibility()} />{pgo.productNumber}</span>
                                    <span>{pgo.unitType}</span>
                                    <span>${pgo.baseUnitPrice.toFixed(2)}</span>
                                </div>
                                <div>{pgo.productNumberLabel}</div>
                            </div>
                        })}
                    </div>
                    {this.state.priceGroupCode?.priceList && <div className="PriceGroupFooter">
                        <span>{`${this.state.priceGroupCode.priceList.prices.length} record${this.state.priceGroupCode.priceList.prices.length === 1 ? "" : "s"}`}</span>
                    </div>}
                </div>
            </div>
            {this.state.showPriceListSearch && <Dialog>
                <div className="SelectPriceList">
                    <div><h1>Select a PriceList</h1>
                        <div className="Search">
                            <input placeholder="Search for records" value={this.state.priceListSearch ?? ""} onChange={(e) => this.setState({ priceListSearch: e.target.value })} onBlur={() => this.doFetchPriceLists()} onKeyPress={(e) => { if (e.key == "Enter") this.doFetchPriceLists() }} />
                            {!this.state.priceListSearch && <img src={ico_Magnifier} onMouseDown={() => this.doFetchPriceLists()} />}
                            {this.state.priceListSearch && <img id="imgClosePriceListSearch" src={ico_Close} onMouseDown={() => this.clearPriceListSearch()} />}
                        </div>
                    </div>
                    <div>{(this.state.priceLists ?? [] as PriceList[]).map((pl) => <div onMouseDown={() => this.setState({ showPriceListSearch: false, editPriceList: pl.priceId })}><span>{pl.priceId}</span><span></span>{pl.name}</div>)}</div>
                    <div><button onClick={() => this.setState({ showPriceListSearch: false })}>Cancel</button></div>
                </div>
            </Dialog>}
        </div>;
    }
}

export default withRouter(PriceGroups);