import * as React from 'react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ConnectionInfo from './ConnectionInfo';
import { LicenseActivation, ILicenseActivation, LicenseActivationStatus } from "@testout/testout-commerce/models/commerce/LicenseActivation";
import { Api, ICCMeta, IThreadProgress } from './Api';
import Dialog from './Dialog';
import readXlsxFile from 'read-excel-file';

import './DeactivateDlg.scss';
import ico_Deactivate from './images/ico_deactivate.png';
import ico_Import from './images/ico_import.png';
import ico_Return from './images/ico_return.png';
import ico_Refresh from './images/ico_refresh.png';
import ico_Calc from './images/ico_calculator.png';
import { Price, PriceType } from '@testout/testout-commerce/models/commerce/Price';
import { RoundingPolicy } from '@testout/testout-commerce/models/Currency';
import { PriceDiscount } from '@testout/testout-commerce/models/commerce/PriceDiscount';
import { FormEvent, RefObject } from 'react';

interface IDeactivateDlgProps extends RouteComponentProps {
    customerNumber: string;
    endDate: Date;
    onClose: () => void;
}

interface IDeactivateDlgState {
    fetchingCommand: boolean;
    activations: LicenseActivation[];
    selections: { [index: number]: boolean };
    endDate: Date;

    showImportDialog: boolean;
    showDeactivateDialog: boolean;
    deactivateThread?: IThreadProgress;
}

class DeactivateDlg extends React.PureComponent<IDeactivateDlgProps, IDeactivateDlgState> {
    _fileInput: RefObject<HTMLInputElement>;
    constructor(props: IDeactivateDlgProps) {
        super(props);
        this.state = this.applyProps(props);
        this._fileInput = React.createRef<HTMLInputElement>();
    }

    componentWillReceiveProps(props: IDeactivateDlgProps) {
        this.setState(this.applyProps(props))
    }

    applyProps(props: IDeactivateDlgProps) {
        return {
            customerNumber: props.customerNumber,
            endDate: props.endDate,
            activations: [],
            selections: {},
            fetchingCommand: false,
            showImportDialog: false,
            showDeactivateDialog: false
        } as IDeactivateDlgState;
    }

    toggleItemSelection(index: number) {
        if (!this.state.fetchingCommand) {
            let selections = Object.assign({}, this.state.selections) as { [index: number]: boolean };
            selections[index] = !selections[index];
            this.setState({ selections: selections });
        }
    }

    componentDidMount() {
        this.doFetchActivations();
    }

    doFetchActivations(cb?: () => void) {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let activations = await Api.getActivationsFromBill(this.props.customerNumber, this.state.endDate);
                this.setState({ fetchingCommand: false, activations: activations, selections: {} }, () => {
                    if (cb)
                        cb();
                });
            });
        }
    }

    timeSpanString(secsIn: number) {
        function pad(num: number) {
            if (isNaN(num))
                return "00";
            let numStr = Math.trunc(num).toString();
            while (numStr.length < 2) numStr = "0" + numStr;
            return numStr;

        }
        var hours = secsIn / 3600,
            remainder = secsIn % 3600,
            minutes = remainder / 60,
            seconds = remainder % 60;
        if (hours >= 24) {
            let days = Math.trunc(hours / 24);
            hours -= days * 24;
            return `${days}.${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
        }
        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    }

    doCloseDialog() {
        if (this.props.onClose)
            this.props.onClose();
    }

    doImportDeactivation() {
        if (!this.state.fetchingCommand)
            this.setState({ showImportDialog: true });
    }

    async handleImportFile(e: FormEvent) {
        e.preventDefault();
        let files = this._fileInput.current?.files;
        if (files && files.length > 0) {
            readXlsxFile(files[0]).then((rows) => {
                this.setState({ fetchingCommand: true }, () => {
                    // `rows` is an array of rows    // each row being an array of cells.
                    let activations = this.state.activations;
                    let selections = {} as { [index: number]: boolean };
                    rows.forEach((r, index) => {
                        //index 0 is the header row
                        if (index > 0 && r.length > 0) {
                            let include = r[0] != null ? r[0].toString().match(/y/i) != null : false;
                            let licenseId = r[1] != null ? parseInt(r[1].toString()) : null;
                            let activationIndex = r[2] != null ? parseInt(r[2].toString()) : null;
                            if (include) {
                                let index = activations.findIndex(act => act.licenseId === licenseId && act.activationIndex === activationIndex);
                                if (index != null)
                                    selections[index] = true;
                            }
                        }
                    });
                    this.setState({ fetchingCommand: false, showImportDialog: false, selections: selections });
                });
            });
        }
    }

    _timeout: any = undefined;
    scheduleRefresh() {
        if (this._timeout != null)
            clearTimeout(this._timeout);
        this._timeout = setTimeout(() => this.doFetchActivations(), 1000);
    }

    get selectedActivations(): number[] {
        let result = [];
        for (let key in this.state.selections) {
            if (this.state.selections[key])
                result.push(parseInt(key));
        }
        return result;
    }

    isSelected(index: number) {
        return this.state.selections[index];
    }

    get isThreadComplete(): boolean {
        return this.state.deactivateThread != null && this.state.deactivateThread.progress >= 100 && this.state.deactivateThread.status == "Complete";
    }

    async doCheckStatus() {
        if (this.state.deactivateThread != null) {
            let thread = await Api.getThreadProgress(this.state.deactivateThread.id);
            this.setState({ deactivateThread: thread }, () => {
                if (this.isThreadComplete)
                    this.endDeactivation();
                else setTimeout(() => this.doCheckStatus(), 5000);
            });
        }
    }

    doDeactivate() {
        if (!this.state.fetchingCommand) {
            this.setState({ fetchingCommand: true }, async () => {
                let activations = this.state.activations;
                let selections = this.selectedActivations;
                if (selections.length > 0) {
                    let selectedActivations = selections.map(index => activations[index]);
                    let threadId = await Api.doDeactivations(selectedActivations);
                    if (threadId != null) {
                        this.setState({ deactivateThread: { id: threadId, progress: 0, status: "Starting Transfer..." } as IThreadProgress }, () => {
                            this.doCheckStatus();
                        });
                    }
                }
            })
        }
    }

    endDeactivation() {
        this.setState({ showDeactivateDialog: false, deactivateThread: undefined, fetchingCommand: false }, () => {
            this.doFetchActivations();
        });
    }

    render() {
        return <div className="DeactivateEditDlg">
            <header>
                <div>
                    <div>
                        <button className="RibbonButton" onClick={() => this.doCloseDialog()}><img src={ico_Return} />RETURN TO BILLING</button>
                        <button className="RibbonButton" onClick={() => this.doImportDeactivation()}><img src={ico_Import} />IMPORT DEACTIVATION FILE</button>
                        <button className="RibbonButton" disabled={this.selectedActivations.length === 0} onClick={() => this.setState({ showDeactivateDialog: true })}><img src={ico_Deactivate} />DEACTIVATE LICENSE ACTIVATIONS</button>
                    </div>
                </div>
                <div>
                    <ConnectionInfo />
                </div>
            </header>
            <div className={this.state.fetchingCommand ? "Spinner" : ""}></div>
            <div className="PageTitle">
                <div>
                    <img src={ico_Calc} />
                    <span>License Activation Bill</span>
                </div>
                <div className="DatePicker">
                    <span>Activations Before</span>
                    <DatePicker selected={this.state.endDate} onChange={(d) => { this.setState({ endDate: d ?? new Date() }); this.scheduleRefresh() }} />
                </div>
            </div>
            <div className="Columns">
                <div className="Activations">
                    <div className="ActivationsHeader ActivationItem">
                        <div>
                            <span>Activation</span>
                            <span>Status</span>
                            <span>Status Date</span>
                            <span>Student</span>
                            <span>Student Class(es)</span>
                            <span>Product(s)</span>
                            <span>Usage</span>
                            <div><button className="ChromelessButton"><img src={ico_Refresh} onClick={() => this.doFetchActivations()} /></button></div>
                        </div>
                    </div>
                    <div className="ActivationsScroller">
                        {this.state.activations && this.state.activations.map((act, index) => {
                            return <div className={`ActivationItem ${this.isSelected(index) ? "Selected" : ""}`} key={index} onMouseDown={() => this.toggleItemSelection(index)}>
                                <div>
                                    <span>{act.licenseId},{act.activationIndex}</span>
                                    <span>{LicenseActivationStatus[act.status]}</span>
                                    <span>{moment(act.statusDate).format("MM/D/YYYY")}</span>
                                    <span><span>{act.userFirstName} {act.userLastName}</span><span>{act.userEmail}</span></span>
                                    <span>{act.classes.map((c, index) => <span key={index}>{c}</span>)}</span>1
                                    <span>{act.courses.map((c, index) => <span key={index}>{c.Value}</span>)}</span>
                                    <span>{this.timeSpanString(act.secondsInUse)}</span>
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="ActivationsFooter">
                        <span>{`${this.state.activations.length} record${this.state.activations.length === 1 ? "" : "s"}`}</span>
                    </div>
                </div>
            </div>
            {this.state.showImportDialog && <Dialog>
                <div className="ImportDeactivation">
                    <form onSubmit={(e) => this.handleImportFile(e)}>
                        <h1>IMPORT DEACTIVATION FILE</h1>
                        <div><span>Choose the License Activation Billing Report file (*.xlsx) to import. The rows in the file containing a "Y" in the "Cancel?" column will be selected in the list so they can be deactivated.</span></div>
                        {!this.state.fetchingCommand && <>
                            <div>
                                <span>Import File</span>
                                <input type="file" ref={this._fileInput} />
                            </div>
                            <div>
                                <button onClick={() => this.setState({ showImportDialog: false })}>Cancel</button>
                                <button type="submit">Import</button>
                            </div>
                        </>}
                    </form>
                </div>
            </Dialog>}
            {this.state.showDeactivateDialog && <Dialog>
                <div className="Deactivations">
                    <h1>DEACTIVATE LICENSE ACTIVATIONS</h1>
                    <div>
                        <span>The {this.selectedActivations.length} selected license activation(s) will be canceled. The associated users will no longer have access to the licensed products. Please confirm you wish to cancel these license activations.</span>
                    </div>
                    {this.state.deactivateThread != null && <>
                        {!this.isThreadComplete && <div className="Status">
                            <div className="Progress"><div style={{ width: `${this.state.deactivateThread.progress}%` }}></div></div>
                            <div>{this.state.deactivateThread.status}</div>
                        </div>}
                    </>}
                    <div>
                        <button onClick={() => this.endDeactivation()}>{this.isThreadComplete ? "Close" : "Cancel"}</button>
                        {!this.state.fetchingCommand && !this.isThreadComplete && <button onClick={() => this.doDeactivate()}>Deactivate</button>}
                    </div>
                </div>
            </Dialog>}
        </div>;
    }
}

export default withRouter(DeactivateDlg);